import React, { useEffect, useState } from "react"
import { cn } from "core/lib/utils"
import pn from "core/utils/pn"
import useLocalStorage from "core/hooks/useLocalStorage"
import Dashboard from "assets/svgs/Dashboard"
import Button from "./new/Button"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
  AddIcon,
  BarChartIcon,
  BillIcon,
  BreafCaseIcon,
  CalendarIcon,
  CalendarPlusIcon,
  CartIcon,
  ChevronDownIcon,
  ExcelIcon,
  FeedbackIcon,
  FileIcon,
  GroupIcon,
  HomeIcon,
  LineChartIcon,
  MainLogo,
  MentorDashboardIcon,
  PastClockIcon,
  PeopleIcon,
  PlacementIcon,
  PlayCircleIcon,
  QuestionListIcon,
  ReferIcon,
  RupeesIcon,
  SignUpIcon,
  StoreIcon,
  TestIcon,
  UpdateIcon,
  WalletIcon,
  TransactionIcon,
  WhatsAppIcon,
  RewardIcon,
  CoinsIcon,
  EyeIcon,
} from "core/constants/svgs"
import { useAuth } from "core/context/auth"
import { isEmpty } from "core/utils/misc"
import { useApp } from "core/context/app"
import { STR_DSML, STR_EV_BATCH, STR_PAID } from "core/constants/strings"
import ProgressManager from "assets/svgs/SSMProgress/ProgressManagerIcon"
import Ellipse123 from "assets/svgs/Ellipse123"
import { NewUpdatesClass } from "domain/useCase/Student/NewUpdates/NewUpdates"
import { NewUpdatesRepositoryImpl } from "data/repository/Student/NewUpdatesRepositoryImpl"
import { NewUpdatesAPIDataSourceImpl } from "data/API/Student/NewUpdatesAPIDataSourceImpl"

export default function Sidebar() {
  const [currItem, setCurrItem] = useState({
    index: -1,
    subIndex: -1,
  })
  const [sidebarList, setSidebarList] = useState<any[]>([])
  const [doubtSupportAvailable, setDoubtSupportAvailable] = useState(false)
  const [isPaymentDone, setIsPaymentDone] = useState(false)
  const [isDSML, setIsDSML] = useState(false)
  const [isInstructor, setIsInstructor] = useState(false)
  const [isGoalSettingMentor, setIsGoalSettingMentor] = useState(false)
  const { student, homeDetails, mentor, setStudent } = useApp()
  const { auth, logout } = useAuth()

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const handleLogout = async () => {
    await logout()
    navigate("/login", { replace: true })
  }

  const isAccelerator = student?.batch?.includes("Accelerator")
  const newUpdatesUseCase = new NewUpdatesClass(new NewUpdatesRepositoryImpl(new NewUpdatesAPIDataSourceImpl()))
  const increase_feature_count = async (feature: string) => {
    if (student?.new_updates?.[feature] >= 3) return
    if (student?.new_updates?.[feature] !== undefined) {
      try {
        // const response = await axios.post(
        //   `http://127.0.0.1:8080/v4_student/new_feature`,
        //   {
        //     id_token: auth?.id_token,
        //     count: student?.new_updates?.[feature] + 1,
        //     feature: feature,
        //   }
        // );
        const response = await newUpdatesUseCase.invoke({
          id_token: auth?.id_token,
          count: student?.new_updates?.[feature] + 1,
          feature: feature,
        })
        if (response?.data) {
          setStudent((prevStudent: any) => ({
            ...prevStudent,
            new_updates: {
              [feature]: student?.new_updates?.[feature] + 1,
            },
          }))
        }
      } catch (error) {
        console.error("Error occurred:", error)
      }
    }
  }
  const ALL_ROUTES: any = {
    user: [
      {
        icon: ({ ...props }: any) => <HomeIcon {...props} />,
        title: "Home",
        link: "/home",
      },
      {
        icon: ({ ...props }: any) => <BillIcon {...props} />,
        title: "Course",
        list: student?.batch?.includes("KCE")
          ? [
              {
                icon: ({ ...props }: any) => <FileIcon {...props} />,
                title: "Curriculum",
                link: "/course",
              },
              {
                icon: ({ ...props }: any) => <CalendarIcon {...props} />,
                title: "Schedule",
                link: "/schedule",
              },
              // {
              //   icon: ({ ...props }: any) => <LightBulbIcon {...props} />,
              //   title: 'Problems',
              //   link: '/problems',
              // },
              ...(!isDSML
                ? [
                    {
                      icon: ({ ...props }: any) => <TestIcon {...props} />,
                      title: "Exams",
                      link: "/weekly-test-series",
                    },
                  ]
                : []),
            ]
          : [
              {
                icon: ({ ...props }: any) => <FileIcon {...props} />,
                title: "Curriculum",
                link: "/course",
              },
              {
                icon: ({ ...props }: any) => <GroupIcon {...props} />,
                title: "My mentor",
                link: "/mentor",
                onclick: () => {
                  increase_feature_count("my_mentor")
                },
              },
              ...(!isAccelerator
                ? [
                    {
                      icon: ({ ...props }: any) => <ProgressManager {...props} />,
                      title: "Progress Meeting",
                      link: "/progress-meeting",
                    },
                    {
                      icon: ({ ...props }: any) => <CalendarIcon {...props} />,
                      title: "Schedule",
                      link: "/schedule",
                    },
                    // {
                    //   icon: ({ ...props }: any) => <LightBulbIcon {...props} />,
                    //   title: 'Problems',
                    //   link: '/problems',
                    // },
                    // ...(!isDSML ? [
                    {
                      icon: ({ ...props }: any) => <TestIcon {...props} />,
                      title: "Exams",
                      link: "/weekly-test-series",
                    },
                  ]
                : []),
            ],
      },
      ...(isAccelerator
        ? []
        : [
            {
              icon: ({ ...props }: any) => <BarChartIcon {...props} />,
              title: "Progress",
              list: [
                // {
                //   icon: ({ ...props }: any) => <UserIcon {...props} />,
                //   title: 'Profile',
                //   link: '/profile',
                // },
                ...(student?.batch?.includes("KCE") || student?.batch?.includes("AC2")
                  ? []
                  : [
                      {
                        icon: ({ ...props }: any) => <PlacementIcon {...props} />,
                        title: "Placement",
                        link: "/placement",
                      },
                    ]),
                ...(isDSML
                  ? []
                  : [
                      {
                        icon: ({ ...props }: any) => <LineChartIcon {...props} />,
                        title: "Leaderboard",
                        link: "/leaderboard",
                      },
                    ]),
                {
                  icon: ({ ...props }: any) => <StoreIcon {...props} />,
                  title: student?.redeem_coins === true ? "Redeem Coins" : "Store",
                  link: "/store",
                },
              ],
            },
          ]),
      ...(student?.batch?.includes("KCE")
        ? []
        : [
            {
              icon: ({ ...props }: any) => <WalletIcon {...props} />,
              title: "Payments",
              list: [
                ...(isPaymentDone
                  ? []
                  : student && student?.PAYMENT_OPTION === true
                    ? [
                        {
                          icon: ({ ...props }: any) => <RupeesIcon {...props} />,
                          title: "Methods",
                          link: "/payment",
                        },

                        {
                          icon: ({ ...props }: any) => <TransactionIcon {...props} />,
                          title: "Transaction",
                          link: student && student?.PAYMENT_OPTION ? "/transaction" : "",
                        },
                      ]
                    : [
                        {
                          icon: ({ ...props }: any) => <RupeesIcon {...props} />,
                          title: "Methods",
                          link: "/payment",
                        },
                      ]),
                {
                  icon: ({ ...props }: any) => <ReferIcon {...props} />,
                  title: "Refer Earn",
                  link: "/refer",
                },
              ],
            },
          ]),
      {
        icon: ({ ...props }: any) => <WhatsAppIcon {...props} />,
        title: student["batch"] === "KCE" ? "Mentor Support" : "Doubt Support",
        // link: 'https://api.whatsapp.com/send?phone=919870258716&text=Hi',
        link: "/doubt-support",
        message: "Doubt Support will be available after the batch starts.",
      },
    ],
    admin: [
      {
        icon: ({ ...props }: any) => <HomeIcon {...props} />,
        title: "Home",
        link: "/admin/home",
      },
      {
        icon: ({ ...props }: any) => <SignUpIcon {...props} />,
        title: "User Signup",
        link: "/admin/user-signup",
      },
      {
        icon: ({ ...props }: any) => <AddIcon {...props} />,
        title: "Add Onboarding Manager",
        link: "/admin/create-onboarding-manager",
      },

      {
        icon: ({ ...props }: any) => <BillIcon {...props} />,
        title: "Students",
        list: [
          {
            icon: ({ ...props }: any) => <Dashboard {...props} />,
            title: "Student Dashboard",
            link: "/admin/students",
          },
          {
            icon: ({ ...props }: any) => <UpdateIcon {...props} />,
            title: "Update Batch",
            link: "/admin/update-batch",
          },
          {
            icon: ({ ...props }: any) => <UpdateIcon {...props} />,
            title: "Update WalkThrough",
            link: "/admin/update-walk-through",
          },
        ],
      },
      {
        icon: ({ ...props }: any) => <GroupIcon {...props} />,
        title: "Mentors",
        list: [
          {
            icon: ({ ...props }: any) => <MentorDashboardIcon {...props} />,
            title: "Mentor Dashboard",
            link: "/admin/mentor-dashboard",
          },
          {
            icon: ({ ...props }: any) => <CalendarIcon {...props} />,
            title: "Mentor View",
            link: "/admin/mentor-sessions",
          },
          {
            icon: ({ ...props }: any) => <FeedbackIcon {...props} />,
            title: "Invoice",
            link: "/admin/invoicing",
          },
        ],
      },
      {
        icon: ({ ...props }: any) => <PlacementIcon {...props} />,
        title: "Placement",
        list: [
          {
            icon: ({ ...props }: any) => <UpdateIcon {...props} />,
            title: "Update Placement",
            link: "/admin/placement",
          },
        ],
      },
      {
        icon: ({ ...props }: any) => <BillIcon {...props} />,
        title: "Batches",
        list: [
          {
            icon: ({ ...props }: any) => <AddIcon {...props} />,
            title: "Create Batch",
            link: "/admin/create-batch",
          },
          {
            icon: ({ ...props }: any) => <AddIcon {...props} />,
            title: "Edit Batch",
            link: "/admin/edit-batch",
          },
          {
            icon: ({ ...props }: any) => <AddIcon {...props} />,
            title: "End Batch",
            link: "/admin/end-batch",
          },
        ],
      },

      {
        icon: ({ ...props }: any) => <BillIcon {...props} />,
        title: "Classes",
        list: [
          {
            icon: ({ ...props }: any) => <AddIcon {...props} />,
            title: "Create Class",
            link: "/admin/create-class",
          },
          {
            icon: ({ ...props }: any) => <CalendarIcon {...props} />,
            title: "All Classes",
            link: "/admin/all-classes",
          },
          {
            icon: ({ ...props }: any) => <CalendarIcon {...props} />,
            title: "Scheduled Classes",
            link: "/admin/scheduled-classes",
          },
          {
            icon: ({ ...props }: any) => <AddIcon {...props} />,
            title: "Project Section",
            link: "/admin/project-section",
          },
        ],
      },
      {
        icon: ({ ...props }: any) => <TestIcon {...props} />,
        title: "Exams",
        // link: "/admin/contests",
        list: [
          {
            icon: ({ ...props }: any) => <TestIcon {...props} />,
            title: "Create Exams",
            link: "/admin/contests",
          },
          {
            icon: ({ ...props }: any) => <BillIcon {...props} />,
            title: "Admin Approval",
            link: "/admin/approval-requests",
          },
          {
            icon: ({ ...props }: any) => <QuestionListIcon {...props} />,
            title: "Proctoring Support",
            link: "/admin/proctoring",
          },
        ],
      },
      {
        icon: ({ ...props }: any) => <BillIcon {...props} />,
        title: "Questions",
        list: [
          {
            icon: ({ ...props }: any) => <QuestionListIcon {...props} />,
            title: "Coding Question",
            link: "/admin/questions",
          },
          {
            icon: ({ ...props }: any) => <TestIcon {...props} />,
            title: "MCQs",
            link: "/admin/mcqs",
          },
          {
            icon: ({ ...props }: any) => <QuestionListIcon {...props} />,
            title: "Subjective/Case Studies",
            link: "/admin/question-chamber",
          },
          {
            icon: ({ ...props }: any) => <QuestionListIcon {...props} />,
            title: "Company Tags",
            link: "/admin/company-tags",
          },
        ],
      },
      {
        icon: ({ ...props }: any) => <RewardIcon {...props} />,
        title: "Rewards and Coins",
        list: [
          {
            icon: ({ ...props }: any) => <CoinsIcon {...props} />,
            title: "Coins Tracker",
            link: "/admin/coins-tracker",
          },
          {
            icon: ({ ...props }: any) => <CartIcon {...props} />,
            title: "Coupons",
            link: "/admin/redeem-coins",
          },
        ],
      },
      {
        icon: ({ ...props }: any) => <BillIcon {...props} />,
        title: "Invoice",
        link: "/admin/invoice",
      },
      {
        icon: ({ ...props }: any) => <BillIcon {...props} />,
        title: "Others",
        list: [
          {
            icon: ({ ...props }: any) => <StoreIcon {...props} />,
            title: "Store",
            link: "/admin/store",
          },
          {
            icon: ({ ...props }: any) => <FeedbackIcon {...props} />,
            title: "Monthly Feedback",
            link: "/admin/all-feedback",
          },
          {
            icon: ({ ...props }: any) => <TestIcon {...props} />,
            title: "Test Dashboard",
            link: "/admin/test-dashboard",
          },
          {
            icon: ({ ...props }: any) => <BreafCaseIcon {...props} />,
            title: "Job Dashboard",
            link: "/admin/job-dashboard",
          },
        ],
      },
      {
        icon: ({ ...props }: any) => <TestIcon {...props} />,
        title: "Mentorship Flow",
        link: "/admin/mentorship",
      },
    ],
    mentor: [
      {
        icon: ({ ...props }: any) => <HomeIcon {...props} />,
        title: "Home",
        link: "/mentor/home",
      },
      {
        icon: ({ ...props }: any) => <PastClockIcon {...props} />,
        title: "All Sessions",
        link: "/mentor/sessions",
      },
      ...(isInstructor
        ? [
            {
              icon: ({ ...props }: any) => <PlayCircleIcon {...props} />,
              title: "Live Class",
              link: "/mentor/live-class",
            },
          ]
        : []),
      {
        icon: ({ ...props }: any) => <PeopleIcon {...props} />,
        title: "Mentees",
        link: "/mentor/mentees",
      },
      // {
      //   icon: ({ ...props }: any) => <CalendarIcon {...props} />,
      //   title: "Monthly Sessions",
      //   link: "/mentor/monthly-sessions",
      // },
      {
        icon: ({ ...props }: any) => <CalendarPlusIcon {...props} />,
        title: "Availability",
        link: "/mentor/availability",
      },
      ...(isGoalSettingMentor
        ? [
            {
              icon: ({ ...props }: any) => <CalendarPlusIcon {...props} />,
              title: "Goal Setting Availability",
              link: "/mentor/goal-setting-availability",
            },
          ]
        : []),
    ],
    free: [
      {
        icon: ({ ...props }: any) => <TestIcon {...props} />,
        title: "Contest",
        link: "/contest",
      },
    ],
  }
  useEffect(() => {
    if (!isEmpty(mentor)) {
      setIsGoalSettingMentor(mentor?.mentor?.goal_setting_mentor)
    }
  }, [mentor])
  useEffect(() => {
    if (!isEmpty(auth)) {
      setSidebarList(ALL_ROUTES[auth?.role])
      setIsPaymentDone(auth?.payment_status === STR_PAID)
    }
  }, [auth, isInstructor, isDSML, isGoalSettingMentor])

  useEffect(() => {
    if (!isEmpty(student) && !isEmpty(homeDetails) && !isEmpty(auth) && auth?.role === "user") {
      setIsDSML(student?.batch?.includes(STR_DSML))
      setDoubtSupportAvailable(
        student?.batch !== "ALL" && parseInt(homeDetails?.batch_start_timestamp) * 1000 <= Date.now()
      )
    }
  }, [auth, student, homeDetails])

  useEffect(() => {
    const currPath = pn(pathname.split("/")[1], pathname.split("/")[2])

    let index = -1,
      subIndex = -1

    sidebarList.forEach((item, i) => {
      if (item.list) {
        item.list.forEach((subItem: any, j: number) => {
          if (subItem.link === currPath || subItem.link === pn(pathname.split("/")[1])) {
            index = i
            subIndex = j
          }
        })
      } else if (item.link === currPath) {
        index = i
      }
    })

    setCurrItem({
      index,
      subIndex,
    })
  }, [sidebarList, pathname])

  useEffect(() => {
    if (!isEmpty(mentor)) {
      setIsInstructor(mentor?.mentor?.is_instructor)
    }
  }, [mentor])

  const DoubtSupport = ({ item }: any) => {
    return (
      <div
      // title={!doubtSupportAvailable && item.message}
      // placement="bottom"
      >
        <span>
          <button
            className={cn(
              "flex items-center gap-2 px-6 py-3 hover:font-semibold hover:text-new-solid-white disabled:opacity-75",
              currItem.index >= 0 && sidebarList[currItem.index].title === "Doubt Support"
                ? "font-semibold text-new-solid-white"
                : student?.is_payment_done
                  ? "font-normal text-[#C1C3C7]"
                  : "font-normal text-new-misc-blue"
            )}
            onClick={() => navigate(item.link)}
            // onClick={() => window.open(item.link)} // for direct whatsapp link in new tab
            // disabled={!doubtSupportAvailable}
          >
            <span>
              <item.icon className="h-4 w-4" />
            </span>
            <span className="text-left">{item.title}</span>
          </button>
        </span>
      </div>
    )
  }

  return (
    <aside
      className={`flex h-full w-full flex-col ${
        student?.is_payment_done && auth?.role === "user"
          ? "bg-gradient-to-r from-[#0B111C] to-[#343641]"
          : "bg-new-gradient-dark"
      }`}
    >
      <Link to="/home" className=" p-6">
        <MainLogo className="h-auto w-[120px]" />
      </Link>
      <nav className="no-scrollbar h-full overflow-y-auto">
        <div className="flex h-full flex-col  ">
          {sidebarList.map((item, i) =>
            item.title === "Doubt Support" ? (
              <DoubtSupport key={i} item={item} />
            ) : item.list ? (
              <SidebarDisclosure key={i} item={item} current={currItem} index={i} />
            ) : (
              <SidebarItem key={i} setCurrent={setCurrItem} current={currItem} item={item} index={i} />
            )
          )}
        </div>
      </nav>
      <div className="mt-auto  p-6">
        <Button outlined onClick={handleLogout}>
          Logout
        </Button>
      </div>
    </aside>
  )
}

const SidebarDisclosure = ({ current, item, index }: any) => {
  const [openList, setOpenList] = useLocalStorage("open-list", new Array(100).fill(false))
  const [open, setOpen] = useState(() => openList[index] || current.index === index)

  useEffect(() => {
    if (current.index === index) {
      setOpen(true)
      setOpenList((prev: any) => {
        const newArr = [...prev]
        for (let i = 0; i < newArr.length; i++) {
          if (i === index) {
            newArr[i] = true
            continue
          }
          newArr[i] = false
        }
        return newArr
      })
    }
  }, [current.index])
  const { student } = useApp()
  return (
    <div>
      <div
        className={cn(
          "flex cursor-pointer items-center justify-between px-6 py-3 hover:font-semibold hover:text-new-solid-white",
          current.index === index
            ? "font-semibold text-new-solid-white"
            : student?.is_payment_done
              ? "font-normal text-[#C1C3C7]"
              : "font-normal text-new-misc-blue"
        )}
        onClick={() => {
          setOpen(!open)
          setOpenList((prev: any) => {
            const newArr = [...prev]
            for (let i = 0; i < newArr.length; i++) {
              if (i === index) {
                newArr[i] = true
                continue
              }
              newArr[i] = false
            }
            return newArr
          })
        }}
      >
        <div className="flex items-center gap-2">
          <span>
            <item.icon className="h-4 w-4"></item.icon>
          </span>
          <span>{item.title}</span>
        </div>
        <span className="ml-auto">
          <ChevronDownIcon className={cn("h-4 w-4", open && "rotate-180")} />
        </span>
      </div>
      <div className={cn("w-full", !open && "hidden")}>
        {item.list.map((subItem: any, i: number) => (
          <SidebarItem list key={i} current={current} item={subItem} index={i} parentIndex={index} />
        ))}
      </div>
    </div>
  )
}

const SidebarItem = ({ current, item, parentIndex, index, list }: any) => {
  const { student } = useApp()
  const [, setOpenList] = useLocalStorage("open-list", new Array(100).fill(false))

  useEffect(() => {
    setOpenList(new Array(100).fill(false))
  }, [])

  return (
    <div onClick={item.onclick ? item.onclick : undefined}>
      <Link
        to={item.link}
        className={cn(
          "flex items-center px-6 py-3 hover:font-semibold hover:text-new-solid-white",
          (list && current.index === parentIndex && current.subIndex === index) || (!list && current.index === index)
            ? "font-semibold text-new-solid-white"
            : student?.is_payment_done
              ? "font-normal text-[#C1C3C7]"
              : "font-normal text-new-misc-blue",
          list && "pl-10"
        )}
      >
        <div
          className={cn(
            "flex items-center gap-2",
            item.title === "My mentor" &&
              student?.new_updates?.my_mentor < 3 &&
              "rounded-[2px] border-[0.5px] border-white border-opacity-50 px-[6px] py-[2px]"
          )}
        >
          <span>
            <item.icon className="h-4 w-4" />
          </span>
          <span>{item.title}</span>
          {item.title === "My mentor" && student?.new_updates?.my_mentor < 3 && (
            <span>
              <Ellipse123 />
            </span>
          )}
        </div>
      </Link>
    </div>
  )
}
