import ProcessCard from "./ProcessCard"
export default function Placementstage({ isEvolve }: { isEvolve: boolean }) {
  const bgcolors = ["bg-[#FFDA8C]", "bg-[#F0F477]", "bg-[#EDC8FB]", "bg-[#97FDB3]", "bg-[#FFBEBE]", "bg-[#99D9FA]"]
  const fontcolors = [
    "text-[#A67E2A]",
    "text-[#A7AC1D]",
    "text-[#7A15A0]",
    "text-[#1B9D3F]",
    "text-[#BA2626]",
    "text-[#217DAC]",
  ]
  const bordercolors = [
    "border-[#A67E2A]",
    "border-[#A7AC1D]",
    "border-[#7A15A0]",
    "border-[#1B9D3F]",
    "border-[#BA2626]",
    "border-[#217DAC]",
  ]

  const stages: any = [
    {
      title: "Your Batch Eligibility",
      description: `After ${isEvolve ? "3" : "4"} months of your course begins, your batch will be eligible for process`,
      number: 1,
    },
    {
      title: "Profile screening",
      description: "Our team will check your initial readiness for	placements.",
      number: 2,
    },
    {
      title: "Admission into placement cohort",
      description: "Selected students will be added to the cohort for further processes.",
      number: 3,
    },
    {
      title: "Recruitment Focussed Preparation",
      description:
        "Students in the placement cohort will be mentored to fulfil the industry requirements and become job ready.",
      number: 4,
    },
    {
      title: "Profile building",
      description: "We will assist our students with services like Resume Building, LinkedIn Optimisation etc.",
      number: 5,
    },
    {
      title: "Final Placements",
      description: "Methods -",
      number: 6,
      boldText: ["a) Referrals into top companies", "b) Direct tie ups"],
    },
  ]

  return (
    <div className="bg-white w-full px-[32px] py-[24px] flex-col gap-8 text-4xl leading-normal shadow rounded">
      <div className="flex justify-center mb-8">
        <div>
          Our <span className="font-bold text-[#312E81]">Placement Process</span>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-8">
        {stages.map((stage: { number: number; title: string; description: string; boldText: string[] }) => (
          <ProcessCard
            index={stage.number}
            bg={bgcolors[stage.number - 1]}
            fontcolor={fontcolors[stage.number - 1]}
            bordercolor={bordercolors[stage.number - 1]}
            title={stage.title}
            description={stage.description}
            boldText={stage.boldText ? stage.boldText : []}
          />
        ))}
      </div>
    </div>
  )
}
