import { CrossIcon, StarIcon } from "core/constants/svgs"
import { differenceInMinutes, format, set } from "date-fns"
import getOrdinal from "core/utils/ordinal"
import Button from "core/components/new/Button"
import { isEmpty } from "core/utils/misc"
import { Close } from "@mui/icons-material"
import React from "react"

export default function ConfirmPopUp({
  session,
  mentor,
  onBooking,
  mentorSessionDetails,
  setConfirmSessionIndex,
  setBookingIndex,
  isRescheduleEnabled,
  setIsRescheduleEnabled,
  onCancel,
}: any) {
  const [isBookingLoading, setIsBookingLoading] = React.useState(false)

  async function handleBookSession() {
    setIsBookingLoading(true)
    if (isRescheduleEnabled) {
      await onCancel(mentorSessionDetails?.id, "reschedule")
      setIsRescheduleEnabled(false)
      await onBooking(session?.date, session?.start, session?.end, mentorSessionDetails?.id)
    } else await onBooking(session?.date, session?.start, session?.end, mentorSessionDetails?.id)
    // await onBooking(
    //   session?.date,
    //   session?.start,
    //   session?.end,
    //   mentorSessionDetails?.session_id
    // );
    setIsBookingLoading(false)
    setConfirmSessionIndex(-1)
    setBookingIndex(-1)
  }
  const startTimestamp = new Date(session?.start_timestamp)
  const endTimestamp = new Date(session?.end_timestamp)
  const durationInMinutes = differenceInMinutes(endTimestamp, startTimestamp)

  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={() => setConfirmSessionIndex(-1)} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-5">
          <div className="font-montserrat m-auto flex w-[304px] transform flex-col gap-3 rounded border bg-new-solid-white p-4">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-new-accent">Book Session</h3>
              </div>
              <div>
                <button onClick={() => setConfirmSessionIndex(-1)}>
                  <CrossIcon className="h-6 w-6" />
                </button>
              </div>
            </div>
            <p className="text-wrap"> You are about to schedule your session on </p>

            <h4 className="font-montserrat w-[218px] font-semibold">
              {session?.date} at {format(new Date(session?.start_timestamp), "h:mm a")}
            </h4>
            <h5 className="whitespace-wrap text-new-failure">
              Note ** Session rescheduling is not allowed within 12Hr of mentor session.
            </h5>
            <div className="flex gap-4">
              <Button
                outlined
                className="h-9 w-[134px] border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                onClick={() => setConfirmSessionIndex(-1)}
              >
                No
              </Button>
              <Button
                outlined
                className="h-9 w-[134px] whitespace-nowrap rounded-sm bg-transparent px-4 py-2"
                onClick={() => {
                  handleBookSession()
                }}
                disabled={isBookingLoading || mentorSessionDetails?.status === "booked"}
                loading={isBookingLoading}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
