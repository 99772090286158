import MentorCard from "./MentorCard"
import Button from "core/components/new/Button"
import { cn } from "core/lib/utils"
import Avatar from "react-avatar"
import {
  STR_COMPLETE_MENTOR_SELECTION_PROCESS,
  STR_COMPLETE_MENTOR_SELECTION_PROCESS_DESCRIPTION,
  STR_MENTOR_LIST,
  STR_NEXT_STEP_BUTTON,
  STR_SELECT_ATMAX_THREE_MENTORS,
} from "core/constants/strings"
import { Mentor } from "domain/model/Mentor"
import Toast from "core/components/Toast"
import MentorViewModel from "../MentorViewModel"

export default function MentorList({
  isAlloting,
  mentorsList,
  selectedMentors,
  handleMentorAllotment,
  alloted,
  handleMentorSelection,
  toast,
  changeToastVisibility,
}: any) {
  return (
    <div className="flex flex-col gap-6 p-[22px]">
      <div className="space-y-1 rounded-sm px-6 py-4 shadow-[0px_3px_7px_rgba(0,0,0,0.1)]">
        <h2 className="text-new-accent">{STR_COMPLETE_MENTOR_SELECTION_PROCESS}</h2>
        <p className="text-new-neutral">{STR_COMPLETE_MENTOR_SELECTION_PROCESS_DESCRIPTION}</p>
      </div>
      <div className="space-y-6 rounded-sm pt-6 shadow-[0px_3px_7px_rgba(0,0,0,0.1)]">
        <div className="space-y-1 px-6">
          <h2 className="text-new-accent">{STR_MENTOR_LIST}</h2>
          <p className="text-new-neutral">Select any three mentors to proceed further.</p>
        </div>
        <div className="relative space-y-6">
          <div className="flex flex-col gap-4 px-6">
            {mentorsList &&
              mentorsList?.map((mentor: Mentor, i: number) => (
                <MentorCard
                  key={i}
                  index={i}
                  mentor={mentor}
                  selectedMentors={selectedMentors}
                  alloted={alloted}
                  handleMentorSelection={handleMentorSelection}
                />
              ))}
          </div>
          <div className="sticky bottom-0 flex w-full justify-between border border-new-neutral-light bg-new-solid-white p-6">
            <div className="flex items-end gap-6">
              <div className="flex gap-4">
                {Array(3)
                  .fill(0)
                  .map((_: any, i: number) => (
                    <span key={i} className={cn("h-8 w-8 rounded-full bg-primary-300")}>
                      {selectedMentors.length > i && (
                        <Avatar
                          className="object-cover"
                          src={mentorsList[selectedMentors[i]]?.photo}
                          name={mentorsList[selectedMentors[i]]?.name}
                          size="32"
                          round
                        />
                      )}
                    </span>
                  ))}
              </div>
              <p className="font-medium text-gray-400">{STR_SELECT_ATMAX_THREE_MENTORS}</p>
            </div>
            <Button small className="w-fit" onClick={handleMentorAllotment} loading={isAlloting}>
              {STR_NEXT_STEP_BUTTON}
            </Button>
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
