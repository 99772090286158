import { Auth } from "domain/model/Auth"
import { ContestRepository } from "domain/repository/User/ContestRepository"
import ContestDataSource from "data/dataSource/User/ContestDataSource"

export class ContestRepositoryImpl implements ContestRepository {
  private dataSource: ContestDataSource

  constructor(dataSource: ContestDataSource) {
    this.dataSource = dataSource
  }

  async getLiveContests(auth: Auth) {
    return this.dataSource.getLiveContests(auth)
  }

  async getInstantContests(auth: Auth) {
    return this.dataSource.getInstantContests(auth)
  }

  async getContestDetails(auth: Auth, contest_id: string) {
    return this.dataSource.getContestDetails(auth, contest_id)
  }

  async getStudentPastContests(auth: Auth) {
    return this.dataSource.getStudentPastContests(auth)
  }

  async getStudentContestDetails(auth: Auth, contest_id: string) {
    return this.dataSource.getStudentContestDetails(auth, contest_id)
  }

  async registerContest(auth: Auth, contest_id: string) {
    return this.dataSource.registerContest(auth, contest_id)
  }

  async unregisterContest(auth: Auth, contest_id: string) {
    return this.dataSource.unregisterContest(auth, contest_id)
  }

  async getContestLeaderboard(auth: Auth, contest_id: string) {
    return this.dataSource.getContestLeaderboard(auth, contest_id)
  }

  async isStudentRegistered(auth: Auth, contest_id: string) {
    return this.dataSource.isStudentRegistered(auth, contest_id)
  }

  async hasStudentStartedContest(auth: Auth, contest_id: string) {
    return this.dataSource.hasStudentStartedContest(auth, contest_id)
  }

  async getMcqQuestionDetails(auth: Auth, question_id: string) {
    return this.dataSource.getMcqQuestionDetails(auth, question_id)
  }

  async getSubCaseQuestionDetails(auth: Auth, question_id: string) {
    return this.dataSource.getSubCaseQuestionDetails(auth, question_id)
  }

  async getCodingQuestionDetails(auth: Auth, question_id: string) {
    return this.dataSource.getCodingQuestionDetails(auth, question_id)
  }

  async submitCodingQuestion(auth: Auth, contest_id: string, coding_question_key: string, status: string) {
    return this.dataSource.submitCodingQuestion(auth, contest_id, coding_question_key, status)
  }

  async submitMcqQuestion(auth: Auth, contest_id: string, mcq_question_key: string, solution: string) {
    return this.dataSource.submitMcqQuestion(auth, contest_id, mcq_question_key, solution)
  }

  async submitSubjectiveQuestions(auth: Auth, contest_id: string, solutions: any) {
    return this.dataSource.submitSubjectiveQuestions(auth, contest_id, solutions)
  }

  async submitCasestudyQuestions(auth: Auth, contest_id: string, solutions: any) {
    return this.dataSource.submitCasestudyQuestions(auth, contest_id, solutions)
  }

  async submitBatchMcqQuestion(auth: Auth, contest_id: string, batch_mcq_obj: any) {
    return this.dataSource.submitBatchMcqQuestion(auth, contest_id, batch_mcq_obj)
  }

  async submitContest(auth: Auth, contest_id: string) {
    return this.dataSource.submitContest(auth, contest_id)
  }

  async submitContestApproval(auth: Auth, data: any) {
    return this.dataSource.submitContestApproval(auth, data)
  }

  async markSubmitProctored(auth: Auth, id: any) {
    return this.dataSource.markSubmitProctored(auth, id)
  }
}
