import { useEffect, useState } from "react"
import CourseCard from "./components/CourseCard"
import useCourseViewModel from "./CourseViewModel"
import { Button } from "core/components/v2/Button"
import ButtonNew from "core/components/new/Button"
import ButtonGroup from "core/components/v2/ButtonGroup"
import CourseLeetCodeModal from "./components/CourseLeetCodeModal"
import Toast from "core/components/Toast"
import colors from "core/constants/colors"
import Loader from "core/components/Loader"
import { Table, TableBody, TableCell, TableHead, TableRow, TableHeader } from "core/components/v2/Table"
import ConditionalLoader from "core/components/ConditionalLoader"
import { isEmpty } from "core/utils/misc"
import { capitalize } from "core/utils/string"
import {
  STR_DATA_SCIENTIST,
  STR_MASTER_CLASS,
  STR_PAST_SESSIONS_AND_ASSIGNMENT,
  STR_SOFTWARE_ENGINEERING,
  STR_UPCOMING_LECTURES,
  STR_DSML_BEGINNER,
} from "core/constants/strings"
import Modal from "core/components/Modal"
import { toDateTime } from "core/utils/date"
import CourseSessionFeedback from "./components/CourseSessionFeedback"
import ProjectModuleComponent from "./components/ProjectModuleComponent"
import { useLocation } from "react-router-dom"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"

export default function CourseView() {
  const {
    toast,
    courseList,
    currentCourse,
    activeButtonType,
    tableData,
    isFullCourseContentActive,
    fullCourseTableData,
    hasLeetCodeId,
    courseSelectedIndex,
    loadingTable,
    student,
    isCourseFetched,
    isAssignmentsFetched,
    isLeetcodeChecked,
    isLeetcodeFetched,
    refreshed,
    isAttendanceModalOpen,
    attendanceModalData,
    feedbackOpen,
    attendanceSessionLoading,
    showProjectModule,
    checkLeetCodeProfile,
    fetchMasterClass,
    fetchAttendanceAndAssignments,
    fetchDsmlBeginnerModuleSessions,
    fetchCourse,
    changeCourseSelected,
    changeActiveButtonType,
    showFullCourseContent,
    handleLeetCodeId,
    changeToastVisibility,
    handleRecordedSessionTable,
    handleUpcomingSessionTable,
    handleMasterClassTable,
    handleCloseAttendanceModal,
    handleAttendanceChange,
    submitFeedback,
    setIsFullCourseContentActive,
  } = useCourseViewModel()

  const [trackName, setTrackName] = useState("")
  const isCourseEmpty = isEmpty(currentCourse?.recorded_lectures) && isEmpty(currentCourse?.upcoming_lectures)

  useEffect(() => {
    if (!isEmpty(student?.tracks)) {
      setTrackName(capitalize(student?.tracks?.[0]?.split("_")?.join(" ")))
    }
  }, [student])

  useEffect(() => {
    if (refreshed) {
      checkLeetCodeProfile()
    }
  }, [refreshed, isLeetcodeChecked])

  useEffect(() => {
    if (refreshed && isLeetcodeFetched) {
      //condition for leetcode verifiction removed
      fetchCourse()
    }
  }, [refreshed, isLeetcodeFetched]) //// leetcode dependency removed

  useEffect(() => {
    if (refreshed && isCourseFetched) {
      fetchAttendanceAndAssignments()
    }
  }, [refreshed, isCourseFetched])

  useEffect(() => {
    if (refreshed && isAssignmentsFetched) {
      fetchMasterClass()
      if (student?.batch?.includes("DSML") && student?.tracks?.includes("dsml_beginner")) {
        fetchDsmlBeginnerModuleSessions()
      }
    }
  }, [refreshed, isAssignmentsFetched])

  useEffect(() => {
    changeCourseSelected(courseList[courseSelectedIndex], courseSelectedIndex)
  }, [isCourseFetched, isAssignmentsFetched])

  useEffect(() => {
    if (!isEmpty(currentCourse)) {
      if (isMasterClass || isDsmlBeginner) {
        handleMasterClassTable()
      } else if (activeButtonType === 0) {
        handleUpcomingSessionTable()
      } else {
        handleRecordedSessionTable()
      }
    }
  }, [loadingTable, activeButtonType, currentCourse, isAssignmentsFetched])

  useEffect(() => {
    if (isFullCourseContentActive) {
      showFullCourseContent()
    }
  }, [isFullCourseContentActive])

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get("tab")

  useEffect(() => {
    if (tab === "Upcoming Classes") {
      changeActiveButtonType(0)
    }

    if (tab === "Past Classes") {
      changeActiveButtonType(1)
    }
  }, [tab])

  const isMasterClass = currentCourse?.name === STR_MASTER_CLASS
  const isDsmlBeginner = currentCourse?.name === STR_DSML_BEGINNER
  const isKCE = student?.batch?.includes("KCE")
  const isEvolve = student?.batch?.includes("EV")
  const isAccelerator = student?.batch?.includes("Accelerator")

  return (
    <DashboardLayoutv2>
      <CourseLeetCodeModal show={!hasLeetCodeId} loading={!isLeetcodeChecked} onSubmit={handleLeetCodeId} />
      <ConditionalLoader isLoading={isEmpty(currentCourse)} loader={<Loader />}>
        <div className="space-y-6 p-6">
          <div className="space-y-6">
            <div className="space-y-1">
              {student?.batch != "EV2" ? (
                <h2 className="text-base font-semibold text-[#333]">{trackName}</h2>
              ) : (
                <h2 className="text-base font-semibold text-[#333]">Evolve</h2>
              )}
              <p className="text-sm font-normal text-[#646464]">
                You have access to <b>{courseList?.length} modules</b> in{" "}
                {student?.batch != "EV2" ? <b>{trackName}</b> : <b>Evolve</b>} course.
              </p>
            </div>
            <div className="rounded-lg border-[0.5px] border-[#d3d3d3] p-4">
              <div className="gray-scrollbar flex w-full gap-5 overflow-x-auto pb-6">
                {!isEmpty(courseList) &&
                  courseList?.map((course: any, i: number) =>
                    student?.batch === "KCE"
                      ? course?.id !== "master_class" &&
                        (course?.recorded_lectures?.length !== 0 || course?.upcoming_lectures?.length !== 0) && (
                          <CourseCard
                            key={i}
                            index={i}
                            active={courseSelectedIndex === i}
                            course={course}
                            onClick={changeCourseSelected}
                            color={colors[Object.keys(colors)[i % 6]]}
                            masterClass={course?.name === "Master Class"}
                          />
                        )
                      : course?.id !== "master_class" &&
                        (course?.recorded_lectures?.length !== 0 || course?.upcoming_lectures?.length !== 0) && (
                          <CourseCard
                            key={i}
                            index={i}
                            active={courseSelectedIndex === i}
                            course={course}
                            onClick={changeCourseSelected}
                            color={colors[Object.keys(colors)[i % 6]]}
                            masterClass={course?.name === "Master Class"}
                          />
                        )
                  )}

                {!isEmpty(courseList) &&
                  courseList?.map((course: any, i: number) =>
                    student?.batch === "KCE"
                      ? course?.id !== "master_class" &&
                        course?.recorded_lectures?.length === 0 &&
                        course?.upcoming_lectures?.length === 0 && (
                          <CourseCard
                            key={i}
                            index={i}
                            active={courseSelectedIndex === i}
                            course={course}
                            onClick={changeCourseSelected}
                            color={colors["grey"]}
                            masterClass={course?.name === "Master Class"}
                          />
                        )
                      : course?.id !== "master_class" &&
                        course?.recorded_lectures?.length === 0 &&
                        course?.upcoming_lectures?.length === 0 && (
                          <CourseCard
                            key={i}
                            index={i}
                            active={courseSelectedIndex === i}
                            course={course}
                            onClick={changeCourseSelected}
                            color={colors["grey"]}
                            masterClass={course?.name === "Master Class"}
                          />
                        )
                  )}
                {student?.batch?.includes("DSML") && (
                  <CourseCard
                    projectModule
                    index={courseList.length}
                    active={courseSelectedIndex === courseList.length}
                    course={{ name: "Projects", id: "project_module" }}
                    onClick={changeCourseSelected}
                    color={colors["white"]}
                  />
                )}
                {!isEmpty(courseList) &&
                  courseList?.map(
                    (course: any, i: number) =>
                      student?.batch !== "KCE" &&
                      course?.id === "master_class" && (
                        <>
                          <CourseCard
                            key={i}
                            index={i}
                            active={courseSelectedIndex === i}
                            course={course}
                            onClick={changeCourseSelected}
                            color={colors["grey"]}
                            masterClass={course?.name === "Master Class"}
                          />
                        </>
                      )
                  )}

                {(isEmpty(courseList) || courseList?.at(-1)?.id !== "master_class") && (
                  <CourseCard course={{ name: "Master Class" }} loading masterClass />
                )}
              </div>
            </div>
          </div>
          {!showProjectModule && !isEmpty(currentCourse) && (
            <div className="flex flex-col gap-4" id="course-session">
              <div className="space-y-1">
                <h2 className="text-base font-semibold text-[#333]">{currentCourse?.name}</h2>
                {isAccelerator ? (
                  student?.is_payment_done == false ? (
                    currentCourse?.name == "Beginner Course" ? (
                      <p>You have access to 4 chapters in {currentCourse?.name} module.</p>
                    ) : (
                      <p>You have access to 0 chapters in {currentCourse?.name} module.</p>
                    )
                  ) : (
                    <p className="text-sm text-[#646464]">
                      You have access to {currentCourse?.recorded_lectures?.length} chapters in {currentCourse?.name}{" "}
                      module.
                    </p>
                  )
                ) : (
                  <p>
                    {currentCourse?.recorded_lectures?.length} chapters in {currentCourse?.name} module.
                  </p>
                )}
              </div>
              <div className="space-y-4">
                <div
                  className={`flex ${isFullCourseContentActive ? "justify-end" : "gray-scrollbar items-start justify-between"}`}
                >
                  {!isFullCourseContentActive && (
                    <div>
                      {!isMasterClass && !isAccelerator && !isDsmlBeginner && (
                        <ButtonGroup
                          className="w-fit"
                          buttons={[STR_UPCOMING_LECTURES, STR_PAST_SESSIONS_AND_ASSIGNMENT]}
                          active={activeButtonType}
                          onChange={changeActiveButtonType}
                        />
                      )}

                      {isMasterClass && !isAccelerator && (
                        <ButtonGroup
                          buttons={[STR_DATA_SCIENTIST, STR_SOFTWARE_ENGINEERING]}
                          active={activeButtonType}
                          onChange={changeActiveButtonType}
                        />
                      )}
                    </div>
                  )}
                  <div className="self-end">
                    {!(
                      isMasterClass ||
                      isDsmlBeginner ||
                      isKCE ||
                      isAccelerator ||
                      (isEvolve && currentCourse?.name !== "DS & Algo")
                    ) && (
                      <Button
                        variant={"cancel"}
                        className="border border-[#DC2626]"
                        onClick={() => setIsFullCourseContentActive((a: any) => !a)}
                      >
                        {isFullCourseContentActive ? "Back" : "View Full Course Content"}
                      </Button>
                    )}
                  </div>
                </div>
                {isFullCourseContentActive && (
                  <Table className="overflow-hidden rounded">
                    <TableHeader className="rounded">
                      <TableRow className="rounded bg-[#333] px-4">
                        {fullCourseTableData?.header?.map((title: any, i: any) => (
                          <TableHead className="text-xs text-white">{title}</TableHead>
                        ))}
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {!isEmpty(fullCourseTableData?.rows) ? (
                        fullCourseTableData?.rows?.map((row: any, i: any) => (
                          <TableRow key={i} className="px-4 hover:bg-[#ebebeb]">
                            {row.map((cell: any, j: any) => (
                              <TableCell
                                key={j}
                                className={`text-xs ${j === 0 || j === row.length - 1 ? "px-4 py-2" : "p-2"}`}
                              >
                                {cell}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      ) : (
                        <div></div>
                      )}
                    </TableBody>
                  </Table>
                )}
              </div>

              {!isFullCourseContentActive && (
                <Table className="overflow-hidden rounded">
                  <TableHeader className="rounded">
                    <TableRow className="rounded bg-[#333]">
                      {tableData?.header?.map((title: any, i: any) => (
                        <TableHead key={i} className="text-xs text-white">
                          {title}
                        </TableHead>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody className="overflow-hidden rounded">
                    {!isEmpty(tableData?.rows) ? (
                      tableData?.rows?.map((row: any, i: any) => (
                        <TableRow key={i} className="hover:bg-[#ebebeb]">
                          {row.map((cell: any, j: any) => (
                            <TableCell
                              key={j}
                              className={`text-xs ${j === 0 || j === row.length - 1 ? "px-4 py-2" : "p-2"}`}
                            >
                              {cell}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </TableBody>
                </Table>
              )}
            </div>
          )}
        </div>
      </ConditionalLoader>
      {showProjectModule && <ProjectModuleComponent />}
      <Modal open={isAttendanceModalOpen} onClose={handleCloseAttendanceModal}>
        <ConditionalLoader isLoading={feedbackOpen} loader={<CourseSessionFeedback onSubmit={submitFeedback} />}>
          <div className="flex flex-col gap-2">
            <div className="mb-2 flex flex-col gap-2">
              <h3 className="text-new-accent">{attendanceModalData?.chapter_name}</h3>
              <div className="flex items-center gap-4">
                {!isEmpty(attendanceModalData) && <p>{toDateTime(parseInt(attendanceModalData?.timestamp) * 1000)}</p>}
                {attendanceModalData?.is_attended ? (
                  <h4 className="text-success">Present</h4>
                ) : (
                  <h4 className="text-failure">Absent</h4>
                )}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <h3>Did you attend this class?</h3>
              <div className="flex gap-2">
                <ButtonNew
                  outlined
                  className="w-28"
                  onClick={() => handleAttendanceChange(true)}
                  loading={attendanceSessionLoading === 1}
                  disabled={attendanceSessionLoading !== -1}
                >
                  Yes
                </ButtonNew>
                <ButtonNew
                  outlined
                  failure
                  className="w-28"
                  onClick={() => handleAttendanceChange(false)}
                  loading={attendanceSessionLoading === 0}
                  disabled={attendanceSessionLoading !== -1}
                >
                  No
                </ButtonNew>
              </div>
            </div>
          </div>
        </ConditionalLoader>
      </Modal>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayoutv2>
  )
}
