import React from "react"

export default function Feedback7(props: any) {
  const { selectedColor } = props
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="32" fill={selectedColor} />
      <path d="M22.1289 42.7188H42.214" stroke="white" strokeWidth="3" strokeLinecap="round" />
      <circle cx="23.1503" cy="25.8652" r="3.40426" fill="white" />
      <circle cx="41.1934" cy="25.8652" r="3.40426" fill="white" />
    </svg>
  )
}
