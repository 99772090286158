import pn from "core/utils/pn"
import { Auth } from "domain/model/Auth"
import { postAPI } from "core/utils/axios"
import { SessionDataSource } from "data/dataSource/Mentor/SessionDataSource"
import {
  API_CANCEL_SESSION,
  API_FETCH_MONTHLY_MENTOR_SESSION,
  API_GET_MENTOR_SESSIONS,
  API_MENTORS,
  API_WRITE_STUDENT_FEEDBACK,
} from "core/constants/strings"

export default class SessionAPIDataSourceImpl implements SessionDataSource {
  async getMentorSessions(auth: Auth) {
    try {
      const response = await postAPI(pn(API_MENTORS, API_GET_MENTOR_SESSIONS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async cancelSession(auth: Auth, date: string, start: string, end: string) {
    try {
      const response = await postAPI(pn(API_MENTORS, API_CANCEL_SESSION), {
        id_token: auth.id_token,
        date,
        start,
        end,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async writeStudentFeedback(room_id: string, feedback: string, action_items: string) {
    try {
      const response = await postAPI(pn(API_MENTORS, API_WRITE_STUDENT_FEEDBACK), {
        room_id,
        feedback,
        action_items,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async fetchMonthlyMentorSession(auth: Auth, start: string, end: string) {
    try {
      const response = await postAPI(pn(API_MENTORS, API_FETCH_MONTHLY_MENTOR_SESSION), {
        id_token: auth.id_token,
        start,
        end,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
