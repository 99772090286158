export default function ChevronRight({ className, pathClassName }: any) {
  return (
    <svg className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5626 16L10.9626 9.40002L12.8479 7.51468L21.3333 16L12.8479 24.4854L10.9626 22.6L17.5626 16Z"
        fill="currentColor"
      />
    </svg>
  )
}
