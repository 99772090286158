import { StudentDashboardDataSource } from "data/dataSource/Admin/StudentDashboardDataSource"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"
import {
  TDisableStudentReasonUpdate,
  getStudentDetails,
  loadNextData,
  mentorList,
  saveResponse,
  sendStates,
  TPaymentStatusReq,
  deleteStudent,
} from "domain/model/StudentDashboard"
import { Auth } from "domain/model/Auth"

export class StudentDashboardRepositoryImpl implements StudentDashboardRepository {
  private dataSource: StudentDashboardDataSource

  constructor(dataSource: StudentDashboardDataSource) {
    this.dataSource = dataSource
  }

  async getStudentByEmail(data: any): Promise<any> {
    return await this.dataSource.getStudentByEmail(data)
  }

  async getStudentsByName(data: any): Promise<any> {
    return await this.dataSource.getStudentsByName(data)
  }

  async getStudentsByBatch(data: any): Promise<any> {
    return await this.dataSource.getStudentsByBatch(data)
  }

  getTop100Students(details: getStudentDetails) {
    return this.dataSource.getTop100Students(details)
  }

  updateDisableState(updateState: sendStates): Promise<any> {
    return this.dataSource.updateDisableState(updateState)
  }

  updatePaidToUnpaid(updateState: TPaymentStatusReq): Promise<any> {
    return this.dataSource.updatePaidToUnpaid(updateState)
  }

  loadNextData(nextData: loadNextData): Promise<any> {
    return this.dataSource.loadNextData(nextData)
  }

  sendEmail(data: loadNextData): Promise<any> {
    return this.dataSource.sendEmail(data)
  }

  getUserData(userData: loadNextData): Promise<any> {
    return this.dataSource.getUserData(userData)
  }

  getMentorList(mentorList: mentorList): Promise<any> {
    return this.dataSource.getMentorList(mentorList)
  }

  getOnboardingManagerList(auth: Auth): Promise<any> {
    return this.dataSource.getOnboardingManagerList(auth)
  }

  updateStudentProfile(data: saveResponse): Promise<any> {
    return this.dataSource.updateStudentProfile(data)
  }

  getBatchHistoryData(auth: Auth, email: string): Promise<any> {
    return this.dataSource.getBatchHistoryData(auth, email)
  }

  async removeMentor(auth: Auth, email: string): Promise<any> {
    return this.dataSource.removeMentor(auth, email)
  }

  async disableStudentReasonUpdate(auth: Auth, data: TDisableStudentReasonUpdate): Promise<any> {
    return this.dataSource.disableStudentReasonUpdate(auth, data)
  }
  async deleteStudent(student: deleteStudent): Promise<any> {
    return await this.dataSource.deleteStudent(student)
  }
  async refundStudent(Auth: Auth, data: any): Promise<any> {
    return this.dataSource.refundStudent(Auth, data)
  }
  async updateMilestoneSessionMentor(auth: Auth, student_id: any): Promise<any> {
    return this.dataSource.updateMilestoneSessionMentor(auth, student_id)
  }

  async addPlacementCompany(auth: Auth, company_details: any): Promise<any> {
    return this.dataSource.addPlacementCompany(auth, company_details)
  }
  async editPlacementCompany(auth: Auth, company_details: any): Promise<any> {
    return this.dataSource.editPlacementCompany(auth, company_details)
  }
  async deletePlacementCompany(auth: Auth, company_details: any): Promise<any> {
    return this.dataSource.deletePlacementCompany(auth, company_details)
  }
  async getPlacementCompany(auth: Auth, email: string): Promise<any> {
    return this.dataSource.getPlacementCompany(auth, email)
  }

  async addStudentComment(auth: Auth, comment_details: any): Promise<any> {
    return this.dataSource.addStudentComment(auth, comment_details)
  }
  async getStudentComment(auth: Auth, email: string): Promise<any> {
    return this.dataSource.getStudentComment(auth, email)
  }
  async editStudentComment(auth: Auth, comment_details: any): Promise<any> {
    return this.dataSource.editStudentComment(auth, comment_details)
  }
  async deleteStudentComment(auth: Auth, comment_details: any): Promise<any> {
    return this.dataSource.deleteStudentComment(auth, comment_details)
  }

  async getPlacementReady(auth: Auth, email: string): Promise<any> {
    return this.dataSource.getPlacementReady(auth, email)
  }
  async setPlacementReady(auth: Auth, placement_details: any): Promise<any> {
    return this.dataSource.setPlacementReady(auth, placement_details)
  }
}
