import React from "react"
import DashboardLayout from "core/layouts/DashboardLayout"
import MentorSchedule from "./components/MentorSchedule"
import MentorSessionCard from "./components/MentorSessionCard"
import useMentorViewModel from "./MentorViewModel"
import MentorCard from "./components/MentorCard"
import MentorList from "./components/MentorList"
import MentorBeginSelectionCard from "presentation/Student/Mentor/components/MentorBeginSelectionCard"
import Toast from "core/components/Toast"
import PremiumPopup from "core/components/PremiumPopup"

import Loader from "core/components/Loader"
import Table from "core/components/new/Table"
import MentorModal from "./components/MentorModal"
import { isEmpty } from "core/utils/misc"
import NewMentorSchedule from "./components/NewMentorSchedule"
import MentorSessionTable from "./components/MentorSessionTable"
import PopUp from "./components/PopUp"
import { set } from "date-fns"
import { is } from "date-fns/locale"
import ConfirmPopUp from "./components/ConfirmPopUp"
import MentorReport from "./components/MentorReport"
import { GetMentorSessionLinkStudent } from "domain/useCase/Student/Mentor/GetMentorSessionLinkStudent"
import ConfirmationPopUpMentorRemoval from "./components/MentorAbsentPopup"
import ConditionalLoader from "core/components/ConditionalLoader"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"

export default function MentorView() {
  const {
    isAvailabilityLoading,
    setIsAvailabilityLoading,
    handleMarkMentorAbsent,
    isOpen,
    setIsOpen,
    isAlloting,
    getMentorNameFromId,
    toast,
    isMentorAlloted,
    isSelectionStarted,
    allMentors,
    allSelectedMentors,
    isPremiumPopupVisible,
    mentor,
    milestoneSessionMentor,
    mentorAvailability,
    mentorSessions,
    tableData,
    tableDataNew,
    isAllotmentStatusLoading,
    isMentorSessionBooked,
    reviewIndex,
    feedbackIndex,
    bookingIndex,
    cancelSessionIndex,
    rescheduleSessionIndex,
    meetLink,
    mentorId,
    setMentorId,
    index,
    mentorDetails,
    handleGetMentorSessionDetails,
    setMeetLink,
    getSessionJoiningLink,
    setRescheduleSessionIndex,
    confirmSessionIndex,
    setConfirmSessionIndex,
    rating,
    comment,
    refreshed,
    student,
    limitReached,
    newMentorshipFlow,
    setNewMentorshipFlow,
    isMenuOpen,
    menuActiveRow,
    mentorSessionData,
    bookingDetails,
    mentorsList,
    isRescheduleEnabled,
    isMilestoneSession,
    setIsRescheduleEnabled,
    formatDate,
    setBookingIndex,
    setBookingDetails,
    setIsMentorAlloted,
    handleMentorSelection,
    startSelectionProcess,
    handleMentorAllotment,
    setCancelSessionIndex,
    milestoneSessionMentorId,
    getMentorDetailsByStudent,
    setMilestoneSessionMentorId,
    indexToBeMarkedAbsent,
    handleClosePopup,
    realMentor,
    setMenuOpen,
    lastCompletedSessionId,
    setComment,
    setRating,
    closeScheduleDialog,
    closeSelectedFeedbackDialog,
    handleSubmit,
    bookMentorSession,
    cancelMentorSession,
    fetchBookedSessions,
    fetchAllMentorSessions,
    fetchAllMentors,
    fetchMentorAvailability,
    changeToastVisibility,
    handleNewMentorSessionsTable,
    isBookingInProgress,
    setMentor,
    sessionId,
    setSessionId,
    setMsid,
    msid,
    setShowMentorPage,
    disableMentorCard,
    setDisableMentorCard,
    showMentorPage,
    BATCHES_TO_IGNORE_MENTOR_ALLOTMENT,
    cancellationInProgress,
    BATCHES_TO_IGNORE_NMF,
    isBookingAvailable,
  } = useMentorViewModel()
  React.useEffect(() => {
    setMentor(student?.mentor_id)
  })
  React.useEffect(() => {
    if (lastCompletedSessionId !== "") {
      handleGetMentorSessionDetails(lastCompletedSessionId)
    }
  }, [lastCompletedSessionId])
  React.useEffect(() => {
    fetchAllMentorSessions()
    if (mentorSessions?.booked?.length > 0) {
      getMentorNameFromId(mentorSessions.booked[0].mentor_id)
    }
  }, [isBookingAvailable])
  React.useEffect(() => {
    fetchMentorAvailability(isMilestoneSession, sessionId)
  }, [isMilestoneSession, sessionId])
  React.useEffect(() => {
    if (refreshed) {
      setMsid("msid" + String(bookingIndex + 1))
      setMilestoneSessionMentorId(student?.milestone_session_mentor_id)
      // fetchAllMentors()
      // fetchBookedSessions()
      // fetchMentorAvailability(isMilestoneSession, sessionId)
      // fetchAllMentorSessions()
      // handleGetMentorSessionDetails(lastCompletedSessionId)
    }
  }, [
    refreshed,
    bookingIndex,
    isMilestoneSession,
    rescheduleSessionIndex,
    confirmSessionIndex,
    msid,
    meetLink,
    lastCompletedSessionId,
  ])
  React.useEffect(
    () => {
      fetchAllMentors()
    },
    [
      // isMentorSessionBooked,
      // bookingIndex,
      // rescheduleSessionIndex,
      // reviewIndex,
      // isSelectionStarted,
      // confirmSessionIndex,
    ]
  )
  React.useEffect(() => {
    setMentorId(student?.mentor_id)
    fetchBookedSessions()
  }, [])
  React.useEffect(() => {
    if (student?.batch_start_date >= 20240701 && !BATCHES_TO_IGNORE_NMF.includes(student?.batch)) {
      setNewMentorshipFlow(true)
    } else {
      setNewMentorshipFlow(false)
    }
  }, [student])
  React.useEffect(() => {
    handleNewMentorSessionsTable(mentorSessionData)
  }, [mentorSessionData, isMenuOpen, menuActiveRow])
  React.useEffect(() => {
    if (
      !isEmpty(student) &&
      (isMentorAlloted ||
        (!student?.is_batch_over == true && !BATCHES_TO_IGNORE_MENTOR_ALLOTMENT.includes(student?.batch))) &&
      (!student?.batch_end_timestamp || student?.batch_end_timestamp > Date.now())
    ) {
      setShowMentorPage(true)
    }
  }, [student, isMentorAlloted])

  React.useEffect(() => {
    if (student && student["batch"] === "KCE" && !disableMentorCard) {
      setDisableMentorCard(true)
    }
  }, [student])
  React.useEffect(() => {
    // fetchBookedSessions()

    getMentorDetailsByStudent(student?.mentor_id)
  }, [])
  React.useEffect(() => {
    if (refreshed && isMentorSessionBooked) {
      // fetchMentorAvailability(isMilestoneSession, sessionId)
      fetchAllMentorSessions()
    }
    if (reviewIndex !== -1 && mentorSessionData?.[reviewIndex]?.student_review !== undefined) {
      setRating(mentorSessionData?.[reviewIndex]?.student_review?.rating)
      setComment(mentorSessionData?.[reviewIndex]?.student_review?.comment)
    }
  }, [
    refreshed,
    isMentorSessionBooked,
    bookingIndex,
    rescheduleSessionIndex,
    reviewIndex,
    isSelectionStarted,
    confirmSessionIndex,
  ])

  React.useEffect(() => {
    if (student?.mentor_id === "" || student?.mentor_id === null) {
      setIsMentorAlloted(false)
    }
  }, [student])
  React.useEffect(() => {
    if (
      !isEmpty(student) &&
      (isMentorAlloted || (!student?.is_batch_over && !BATCHES_TO_IGNORE_MENTOR_ALLOTMENT.includes(student?.batch))) &&
      (!student?.batch_end_timestamp || student?.batch_end_timestamp > Date.now())
    ) {
      setShowMentorPage(true)
    }
  }, [student, isMentorAlloted])
  return !showMentorPage ? (
    <DashboardLayoutv2>
      <div className="flex h-[500px] w-full items-center justify-center text-center">
        <p className="max-w-md">
          You don't have access to mentorship program. For any assistance please mail us at{" "}
          <a className="font-medium text-new-accent" href="mailto:help@bosscoderacademy.com">
            help@bosscoderacademy.com
          </a>
        </p>
      </div>
    </DashboardLayoutv2>
  ) : disableMentorCard ? (
    <DashboardLayoutv2>
      <div className="flex h-[500px] w-full items-center justify-center text-center">
        <p className="max-w-md font-medium">
          For assistance with the mentorship program, please reach out to your
          <span className="font-bold"> Student Success Manager</span>.
        </p>
      </div>
    </DashboardLayoutv2>
  ) : isBookingInProgress ? (
    <DashboardLayoutv2>
      <div className="flex flex-col gap-4 justify-center items-center">
        <div>
          <Loader />
        </div>
        <div className="text accent-blue text-lg">Please wait! Your session is being booked</div>
      </div>
    </DashboardLayoutv2>
  ) : (
    <DashboardLayoutv2>
      {isSelectionStarted ? (
        <MentorList
          isAlloting={isAlloting}
          mentorsList={mentorsList}
          selectedMentors={allSelectedMentors}
          alloted={isMentorAlloted}
          handleMentorAllotment={handleMentorAllotment}
          handleMentorSelection={handleMentorSelection}
          toast={toast}
          changeToastVisibility={changeToastVisibility}
        />
      ) : (
        <div>
          {isOpen && (
            <ConfirmationPopUpMentorRemoval
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              updateStudentsMentor={() => handleMarkMentorAbsent(indexToBeMarkedAbsent)}
              heading="Are you sure you want to mark mentor absent for this session?"
            />
          )}
          {cancelSessionIndex !== -1 && (
            <PopUp
              index={cancelSessionIndex}
              session={mentorSessionData?.[cancelSessionIndex]}
              onClose={() => closeSelectedFeedbackDialog("cancel")}
              type="cancel"
              formatDate={formatDate}
              onCancel={cancelMentorSession}
              setCancelSessionIndex={setCancelSessionIndex}
              setIsRescheduleEnabled={setIsRescheduleEnabled}
              isCancellationInProgress={cancellationInProgress}
            />
          )}
          {rescheduleSessionIndex !== -1 && (
            <PopUp
              index={rescheduleSessionIndex}
              session={mentorSessionData?.[rescheduleSessionIndex]}
              onClose={() => closeSelectedFeedbackDialog("reschedule")}
              type="reschedule"
              formatDate={formatDate}
              onCancel={cancelMentorSession}
              setBookingIndex={setBookingIndex}
              setRescheduleSessionIndex={setRescheduleSessionIndex}
              setIsRescheduleEnabled={setIsRescheduleEnabled}
              isCancellationInProgress={cancellationInProgress}
            />
          )}
          {reviewIndex !== -1 && (
            <MentorModal
              index={reviewIndex}
              sessions={newMentorshipFlow ? mentorSessionData?.[reviewIndex] : mentorSessions?.all_sessions}
              onClose={() => closeSelectedFeedbackDialog("review")}
              rating={rating}
              setRating={setRating}
              comment={comment}
              setComment={setComment}
              onSubmit={handleSubmit}
              type="review"
              newMentorshipFlow={newMentorshipFlow}
              openedFrom="student"
            />
          )}
          {bookingIndex !== -1 && (
            <NewMentorSchedule
              loading={isAvailabilityLoading}
              availability={mentorAvailability}
              onClose={() => closeScheduleDialog()}
              mentor={realMentor}
              onBooking={bookMentorSession}
              index={bookingIndex}
              mentorSessionDetails={mentorSessionData?.[bookingIndex]}
              setBookingDetails={setBookingDetails}
              setConfirmSessionIndex={setConfirmSessionIndex}
            />
          )}
          {feedbackIndex !== -1 && (
            <MentorReport
              index={feedbackIndex}
              sessions={
                newMentorshipFlow ? mentorSessionData?.[feedbackIndex] : mentorSessions?.all_sessions?.[feedbackIndex]
              }
              onClose={() => closeSelectedFeedbackDialog("feedback")}
              rating={rating}
              setRating={setRating}
              comment={comment}
              setComment={setComment}
              onSubmit={handleSubmit}
              newMentorshipFlow={newMentorshipFlow}
              type="feedback"
            />
          )}
          {confirmSessionIndex !== -1 && (
            <ConfirmPopUp
              session={bookingDetails}
              index={confirmSessionIndex}
              mentorSessionDetails={mentorSessionData?.[confirmSessionIndex]}
              setBookingIndex={setBookingIndex}
              onBooking={bookMentorSession}
              type="confirm"
              setConfirmSessionIndex={setConfirmSessionIndex}
              isRescheduleEnabled={isRescheduleEnabled}
              setIsRescheduleEnabled={setIsRescheduleEnabled}
              onCancel={cancelMentorSession}
              // isCancellationInProgress={cancellationInProgress}
            />
          )}
          <div className="flex flex-col gap-8 p-6">
            {mentor && mentorSessions?.booked?.length > 0 && (
              <MentorSessionCard mentor={mentorId} session={mentorSessions?.booked[0]} />
            )}
            {!isMentorAlloted ? (
              <div>
                <MentorBeginSelectionCard onClick={startSelectionProcess} />
              </div>
            ) : (
              <MentorCard mentor={mentorDetails} alloted={isMentorAlloted} />
            )}

            {!newMentorshipFlow &&
              (limitReached ? (
                <div className="flex h-[100px] w-full items-center justify-center text-center">
                  <p className="max-w-md">
                    You have reached the maximum limit for mentor sessions. For any assistance please mail us at{" "}
                    <a className="font-medium text-new-accent" href="mailto:help@bosscoderacademy.com">
                      help@bosscoderacademy.com
                    </a>
                  </p>
                </div>
              ) : (
                <MentorSchedule availability={mentorAvailability} mentor={realMentor} onBooking={bookMentorSession} />
              ))}
            {newMentorshipFlow ? (
              <div>
                <h2 className="text-new-accent">Schedule Mentorship</h2>
                {/* <p>Book all your mentor sessions hassle free!</p> */}
                <p>
                  You can only book a session 15 days prior to the session start date until the session end date, after
                  which the session automatically expires.
                </p>

                <div className="my-4">
                  <MentorSessionTable
                    data={tableDataNew}
                    gradient={false}
                    isMenuOpen={isMenuOpen}
                    menuActiveRow={menuActiveRow}
                  />
                </div>
              </div>
            ) : (
              <Table data={tableData} />
            )}
          </div>
          <Toast data={toast} onClick={() => changeToastVisibility(false)} />
        </div>
      )}
    </DashboardLayoutv2>
  )
}
