import { useEffect } from "react"
import useCourseSessionViewModel from "./CourseSessionViewModel"
import RecordedSessionFeedback from "./components/RecordedSessionFeedback"
import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import CourseDisclosure from "./components/CourseDisclosure"
import CourseSidebar from "./components/CourseSidebar"
import { cn } from "core/lib/utils"
import Loader from "core/components/Loader"
import { STR_VIEW_NOTES, STR_VIEW_SUMMARY } from "core/constants/strings"
import { CheckSuccessIcon } from "core/constants/svgs"
import Modal from "core/components/Modal"
import ConditionalLoader from "core/components/ConditionalLoader"
import { isEmpty } from "core/utils/misc"
import SubmissionResponse from "./components/SubmissionResponse"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import Tags from "core/components/v2/Tags"
import { Table, TableBody, TableCell, TableHead, TableRow, TableHeader } from "core/components/v2/Table"
import { useSearchParams } from "react-router-dom"
import TableButton from "core/components/v2/TableButton"
import ArrowBack from "core/components/v2/ArrowBack"

export default function CourseSession() {
  const {
    toast,
    assignmentsTableContent,
    warmupTableContent,
    homeworkTableContent,
    practiceTableContent,
    sessionDetails,
    courseLectures,
    loading,
    activeIndex,
    sessionId,
    loadingTable,
    sessionList,
    courseName,
    refreshed,
    feedbackData,
    isRecordedFeedbackVisible,
    isViewSubmissionVisible,
    order,
    questionName,
    isLoading,
    questionType,
    feedbackResponseData,
    selectedQuestion,
    tab,
    TABS,
    onLectureClick,
    fetchSessionDetails,
    fetchCourseLectures,
    getFeedbackDetails,
    setIsRecordedFeedbackVisible,
    submitRecordingFeedback,
    changeToastVisibility,
    handleSidebarData,
    changeTableData,
    navigate,
    handleOnCloseBtnModalClick,
    fetchViewSubmission,
    setTab,
    handleTabChange,
    handleSelectedQuestionBtnClick,
  } = useCourseSessionViewModel()

  useEffect(() => {
    if (refreshed) {
      fetchSessionDetails()
    }
  }, [refreshed, sessionId])

  useEffect(() => {
    if (refreshed && sessionList[sessionId]) {
      fetchCourseLectures()
      handleSidebarData()
      getFeedbackDetails(sessionId)
    }
  }, [refreshed, sessionList])

  useEffect(() => {
    changeTableData(sessionDetails)
  }, [loadingTable, sessionDetails])

  useEffect(() => {
    if (refreshed && isViewSubmissionVisible) {
      fetchViewSubmission(questionName, questionType)
    }
  }, [refreshed, isViewSubmissionVisible, questionName, questionType])

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const currentTabVisible = searchParams.get("tab")
    if (currentTabVisible) {
      setTab(parseInt(currentTabVisible, 0))
    }
  }, [searchParams])

  const duration = sessionDetails?.duration

  return (
    <DashboardLayoutv2>
      <ConditionalLoader isLoading={loading} loader={<Loader />}>
        <div className="space-y-4 p-6">
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-1">
              <h3 className="text-new-accent">{sessionDetails?.session_name}</h3>
              <p>
                Duration: {Math.floor(duration / 3600)}h:{Math.floor((duration % 3600) / 60)}m:
                {duration % 60}s
              </p>
            </div>
            <TableButton
              className="flex h-fit w-fit items-center justify-center gap-1"
              outlined
              onClick={() => navigate("/course")}
            >
              <ArrowBack />
              <p className="text-sm font-medium">Go Back</p>
            </TableButton>
          </div>
          <div className="flex gap-6">
            <div className="w-[72%] space-y-6">
              <div className="flex gap-2">
                {TABS.map((item: string, idx: number) => (
                  <Tags selected={tab === idx} onClick={() => handleTabChange(idx)}>
                    {item}
                  </Tags>
                ))}
              </div>
              {tab === 0 && (
                <div className="h-calc((w-full)*0.5625) w-full">
                  {sessionDetails?.video_id ? (
                    <iframe
                      title="Video"
                      className="aspect-video h-full w-full rounded"
                      src={sessionDetails?.video_link}
                      allow="autoplay; fullscreen; picture-in-picture"
                    />
                  ) : (
                    <div className="flex aspect-video h-full w-full items-center justify-center font-medium">
                      <span>Video not available!</span>
                    </div>
                  )}
                </div>
              )}
              {tab === 1 && (
                <div>
                  {warmupTableContent.rows?.length > 0 && (
                    <div className="flex w-full items-start gap-4">
                      <button
                        className={cn(
                          "mt-4",
                          sessionDetails?.warmup?.every((item: any) => item?.status === true)
                            ? "text-new-success"
                            : "text-new-neutral"
                        )}
                      >
                        <CheckSuccessIcon className="h-4 w-4" />
                      </button>
                      <CourseDisclosure name="Warmup Questions">
                        <Table className="overflow-hidden rounded">
                          <TableHeader className="rounded">
                            <TableRow className="rounded bg-[#333]">
                              {warmupTableContent?.header?.map((title: any, i: number) => (
                                <TableHead key={i} className={cn("p-2 text-xs text-white", i === 0 && "px-4")}>
                                  {title}
                                </TableHead>
                              ))}
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {!isEmpty(warmupTableContent?.rows) ? (
                              warmupTableContent?.rows?.map((row: any, i: number) => (
                                <TableRow key={i} className="hover:bg-[#ebebeb]">
                                  {row.map((cell: any, j: number) => (
                                    <TableCell
                                      key={j}
                                      className={cn("text-xs p-2", (j === 0 || j === row.length - 1) && "px-4")}
                                    >
                                      {cell}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))
                            ) : (
                              <div></div>
                            )}
                          </TableBody>
                        </Table>
                      </CourseDisclosure>
                    </div>
                  )}

                  {assignmentsTableContent.rows?.length > 0 && (
                    <div className="flex w-full items-start gap-4">
                      <div
                        className={cn(
                          "mt-4",
                          sessionDetails?.assignments?.every(
                            (item: any) => item?.status === true || item?.solved_on_leetcode === true
                          )
                            ? "text-new-success"
                            : "text-new-neutral"
                        )}
                      >
                        <CheckSuccessIcon className="h-4 w-4" />
                      </div>
                      <CourseDisclosure name="Assignment Questions">
                        <Table className="overflow-hidden rounded">
                          <TableHeader className="rounded">
                            <TableRow className="rounded bg-[#333]">
                              {assignmentsTableContent?.header?.map((title: any, i: number) => (
                                <TableHead key={i} className={cn("p-2 text-xs text-white", i === 0 && "px-4")}>
                                  {title}
                                </TableHead>
                              ))}
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {!isEmpty(assignmentsTableContent?.rows) ? (
                              assignmentsTableContent?.rows?.map((row: any, i: any) => (
                                <TableRow className="hover:bg-[#ebebeb]">
                                  {row.map((cell: any, j: any) => (
                                    <TableCell
                                      className={`text-xs ${j === 0 || j === row.length - 1 ? "px-4 py-2" : "p-2"} `}
                                    >
                                      {cell}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))
                            ) : (
                              <div></div>
                            )}
                          </TableBody>
                        </Table>
                      </CourseDisclosure>
                    </div>
                  )}

                  {homeworkTableContent.rows?.length > 0 && (
                    <div className="flex w-full items-start gap-4">
                      <button
                        className={cn(
                          "mt-4",
                          sessionDetails?.homework?.every((item: any) => item?.status === true)
                            ? "text-new-success"
                            : "text-new-neutral"
                        )}
                      >
                        <CheckSuccessIcon className="h-4 w-4" />
                      </button>
                      <CourseDisclosure name="Homework Questions">
                        <Table className="overflow-hidden rounded">
                          <TableHeader className="rounded">
                            <TableRow className="rounded bg-[#333]">
                              {homeworkTableContent?.header?.map((title: any, i: any) => (
                                <TableHead className="p-2 text-xs text-white">{title}</TableHead>
                              ))}
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {!isEmpty(homeworkTableContent?.rows) ? (
                              homeworkTableContent?.rows?.map((row: any, i: any) => (
                                <TableRow className="hover:bg-[#ebebeb]">
                                  {row.map((cell: any, j: any) => (
                                    <TableCell
                                      className={`text-xs ${j === 0 || j === row.length - 1 ? "px-4 py-2" : "p-2"}`}
                                    >
                                      {cell}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))
                            ) : (
                              <div></div>
                            )}
                          </TableBody>
                        </Table>
                      </CourseDisclosure>
                    </div>
                  )}

                  {practiceTableContent.rows?.length > 0 && (
                    <div className="flex w-full items-start gap-4">
                      <button
                        className={cn(
                          "mt-4",
                          sessionDetails?.practice?.every((item: any) => item?.status === true)
                            ? "text-new-success"
                            : "text-new-neutral"
                        )}
                      >
                        <CheckSuccessIcon className="h-4 w-4" />
                      </button>
                      <CourseDisclosure name="Practice Questions">
                        <Table className="overflow-hidden rounded">
                          <TableHeader className="rounded">
                            <TableRow className="rounded bg-[#333]">
                              {practiceTableContent?.header?.map((title: any, i: any) => (
                                <TableHead className="p-2 text-xs text-white">{title}</TableHead>
                              ))}
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {!isEmpty(practiceTableContent?.rows) ? (
                              practiceTableContent?.rows?.map((row: any, i: any) => (
                                <TableRow className="hover:bg-[#ebebeb]">
                                  {row.map((cell: any, j: any) => (
                                    <TableCell
                                      className={`text-xs ${j === 0 || j === row.length - 1 ? "px-4 py-2" : "p-2"}`}
                                    >
                                      {cell}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))
                            ) : (
                              <div></div>
                            )}
                          </TableBody>
                        </Table>
                      </CourseDisclosure>
                    </div>
                  )}
                </div>
              )}
              {tab === 2 && (sessionDetails?.notes_link || sessionDetails?.notes_summary_link) && (
                <div className="flex items-start gap-4">
                  <button className="mt-4 text-new-success">
                    <CheckSuccessIcon className="h-4 w-4" />
                  </button>
                  <CourseDisclosure name="Notes">
                    <div className="flex gap-2">
                      {sessionDetails?.notes_link && sessionDetails?.notes_link.length > 0 && (
                        <a href={sessionDetails?.notes_link} target="_blank" rel="noreferrer">
                          <Button outlined>{STR_VIEW_NOTES}</Button>
                        </a>
                      )}
                      {sessionDetails?.notes_summary_link && sessionDetails?.notes_summary_link.length > 0 && (
                        <a href={sessionDetails?.notes_summary_link} target="_blank" rel="noreferrer">
                          <Button outlined>{STR_VIEW_SUMMARY}</Button>
                        </a>
                      )}
                    </div>
                  </CourseDisclosure>
                </div>
              )}
            </div>
            <div className="w-[28%]">
              <CourseSidebar {...{ lectures: courseLectures, courseName, activeIndex }} onClick={onLectureClick} />
            </div>
          </div>
        </div>
      </ConditionalLoader>
      <Modal open={isRecordedFeedbackVisible}>
        <RecordedSessionFeedback
          onSubmit={submitRecordingFeedback}
          isRecordedFeedbackVisible={isRecordedFeedbackVisible}
          setIsRecordedFeedbackVisible={setIsRecordedFeedbackVisible}
          feedbackData={feedbackData}
        />
      </Modal>

      <Modal
        open={isViewSubmissionVisible}
        onClose={handleOnCloseBtnModalClick}
        width={questionType === "casestudy" ? true : false}
        padding={questionType === "casestudy" ? true : false}
      >
        <SubmissionResponse
          feedbackData={feedbackResponseData}
          order={order}
          isLoading={isLoading}
          questionName={questionName}
          questionType={questionType}
          selectedQuestion={selectedQuestion}
          handleSelectedQuestionBtnClick={handleSelectedQuestionBtnClick}
        />
      </Modal>

      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayoutv2>
  )
}
