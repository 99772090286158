import React from "react"
import MentorCalendar from "./MentorCalendar"
import MentorshipCard from "./MentorshipCard"
import { formatDate } from "core/utils/date"
import {
  STR_MENTOR_NOT_AVAILABLE,
  STR_SCHEDULE_MENTORSHIP,
  STR_SCHEDULE_MENTORSHIP_DESCRIPTION,
} from "core/constants/strings"
import { CrossIcon } from "core/constants/svgs"
import Loader from "core/components/Loader"

const header = ["S", "M", "T", "W", "Th", "F", "Sa"]

export default function NewMentorSchedule({
  index,
  availability,
  mentor,
  onBooking,
  onClose,
  mentorSessionDetails,
  setBookingDetails,
  setConfirmSessionIndex,
  loading,
}: any) {
  const [daysList, setDaysList] = React.useState<any>([])
  const [activeDate, setActiveDate] = React.useState(new Date())
  const [lastMonth, setLastMonth] = React.useState<any>({
    lastDate: new Date(activeDate.getFullYear(), activeDate.getMonth(), 0),
  })
  const [activeMonth, setActiveMonth] = React.useState<any>({
    firstDate: new Date(activeDate.getFullYear(), activeDate.getMonth(), 1),
    lastDate: new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 0),
  })
  const [nextMonth, setNextMonth] = React.useState<any>({
    firstDate: new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 1),
  })
  const [mentorSessions, setMentorSessions] = React.useState<any>([])
  const [isModalOpen, setModalOpen] = React.useState(false)

  function getAllDaysInCalendar() {
    let days: any[] = []
    for (let i = activeMonth.firstDate.getDay() - 1; i >= 0; i--) {
      days = [
        ...days,
        {
          date: formatDate(
            new Date(
              lastMonth.lastDate.getFullYear(),
              lastMonth.lastDate.getMonth(),
              lastMonth.lastDate.getDate() - i + 1
            )
          ),
        },
      ]
    }
    for (let i = 1; i <= activeMonth.lastDate.getDate(); i++) {
      let formattedDate = formatDate(
        new Date(
          activeMonth.firstDate.getFullYear(),
          activeMonth.firstDate.getMonth(),
          activeMonth.firstDate.getDate() + i
        )
      )
      days = [
        ...days,
        {
          date: formattedDate,
          isActiveDate: formattedDate === formatDate(new Date(activeDate)),
          isCurrentMonth: true,
          sessions: [],
        },
      ]
      if (formattedDate === formatDate(new Date(activeDate))) handleMentorSessions(formattedDate)
    }
    for (let i = 0; i < 6 - activeMonth.lastDate.getDay(); i++) {
      days = [
        ...days,
        {
          date: formatDate(
            new Date(
              nextMonth.firstDate.getFullYear(),
              nextMonth.firstDate.getMonth(),
              nextMonth.firstDate.getDate() + i + 1
            )
          ),
        },
      ]
    }
    for (let i = 0; i < availability.length; i++) {
      if (new Date(availability[i].date).getMonth() !== activeDate.getMonth()) {
        continue
      }
      const index = days.findIndex((day: any) => day.date === formatDate(new Date(availability[i].date)))
      if (index !== -1) {
        days[index] = {
          ...days[index],
          sessions: [
            ...days[index].sessions,
            {
              start_timestamp: availability[i].start_timestamp * 1000,
              end_timestamp: availability[i].end_timestamp * 1000,
              date: availability[i].date,
              start: availability[i].start,
              end: availability[i].end,
            },
          ],
        }
      }
    }
    setDaysList(days)
    if (availability.length === 0) setMentorSessions([])
  }
  function handleMentorSessions(date: string) {
    let index = daysList.findIndex((day: any) => day.date === date)
    const sessions = daysList[index]?.sessions
    setMentorSessions(sessions)
    setDaysList((daysList: any) =>
      daysList.map((day: any) => {
        return day.date === date ? { ...day, isActiveDate: true } : { ...day, isActiveDate: false }
      })
    )
  }

  function handlePrevMonth() {
    let updatedDate = new Date(activeDate.getFullYear(), activeDate.getMonth() - 1, 1)
    handleSetMonths(updatedDate)
  }

  function handleNextMonth() {
    let updatedDate = new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 1)
    handleSetMonths(updatedDate)
  }

  function handleSetMonths(updatedDate: Date) {
    setActiveDate(updatedDate)
    setLastMonth({
      lastDate: new Date(updatedDate.getFullYear(), updatedDate.getMonth(), 0),
    })
    setActiveMonth({
      firstDate: new Date(updatedDate.getFullYear(), updatedDate.getMonth(), 1),
      lastDate: new Date(updatedDate.getFullYear(), updatedDate.getMonth() + 1, 0),
    })
    setNextMonth({
      firstDate: new Date(updatedDate.getFullYear(), updatedDate.getMonth() + 1, 1),
    })
  }

  React.useEffect(() => {
    getAllDaysInCalendar()
  }, [activeDate, availability])

  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={onClose} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto p-8">
          <div className="m-auto w-[926px] transform space-y-8 rounded-[4px] border border-new-accent bg-new-solid-white p-6 font-medium">
            <div className="space-y-6">
              <div className="relative flex items-center justify-between">
                <div>
                  <h2 className="text-new-accent">{STR_SCHEDULE_MENTORSHIP}</h2>
                  <p className="text-new-neutral-dark">{STR_SCHEDULE_MENTORSHIP_DESCRIPTION}</p>
                </div>
                <button onClick={onClose} className="absolute top-0 right-0 ">
                  <CrossIcon className="h-6 w-6" />
                </button>
              </div>
              <div className="flex flex-col gap-4 rounded-sm p-8 shadow">
                <div className="flex w-full gap-[57px]">
                  {loading ? (
                    <Loader />
                  ) : (
                    <MentorCalendar
                      header={header}
                      days={daysList}
                      activeDate={activeDate}
                      setActiveDate={setActiveDate}
                      onClick={handleMentorSessions}
                      onPrevMonth={handlePrevMonth}
                      onNextMonth={handleNextMonth}
                      disabled={availability?.length === 0}
                    />
                  )}

                  <div className="flex h-[316px] w-full flex-col gap-6">
                    <div className="flex h-[35px] items-center justify-center rounded-sm border border-solid border-new-accent">
                      <h4 className="text-new-accent"> Available Time Slots </h4>
                    </div>

                    <div className="mentor-schedule flex h-full max-h-full w-full flex-col gap-4 overflow-y-scroll pr-7">
                      {mentorSessions?.length > 0 ? (
                        mentorSessions?.map((session: any, i: number) => (
                          <MentorshipCard
                            key={i}
                            index={index}
                            session={session}
                            mentor={mentor}
                            onBooking={onBooking}
                            onClick={() => setModalOpen(true)}
                            mentorSessionDetails={mentorSessionDetails}
                            setBookingDetails={setBookingDetails}
                            setConfirmSessionIndex={setConfirmSessionIndex}
                          />
                        ))
                      ) : loading ? (
                        <p className="m-auto w-[230px] text-center">Loading mentor availability...</p>
                      ) : (
                        <p className="m-auto w-[230px] text-center">{STR_MENTOR_NOT_AVAILABLE}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-[4px]">
                  <h4>Agenda of the Meeting</h4>
                  <div className="felx mt-2 gap-2 rounded bg-[#F7F7F7] p-[4px]">
                    <p>{mentorSessionDetails?.agenda}</p>
                  </div>
                </div>
              </div>

              {isModalOpen && (
                <div className="fixed inset-0 z-20">
                  <div className="flex min-h-screen items-center justify-center">
                    <div onClick={() => setModalOpen(false)} className="fixed inset-0" aria-hidden="true">
                      <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
                    </div>
                    <div className="no-scrollbar flex h-screen overflow-y-auto py-5">
                      {/* Your modal content goes here */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
