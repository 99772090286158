import Sidebar from "core/components/v2/Sidebar"
import Loader from "core/components/Loader"
import { useAuth } from "core/context/auth"
import { ReactNode, useEffect, useState } from "react"
import { useApp } from "core/context/app"
import RightPanel from "core/components/v2/RightPanel"
import Header from "core/components/Header/HeaderView"
import { cn } from "core/lib/utils"
import NotificationToast from "core/components/v2/NotificationToast"
import { format } from "date-fns"
import { useLocation } from "react-router-dom"
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react"

type TProps = {
  children: ReactNode
  isLoading?: boolean
  rightpanelEnabled?: boolean
  header?: ReactNode | string
}

export default function DashboardLayout({ children, isLoading, rightpanelEnabled, header }: TProps) {
  const { auth, refreshed } = useAuth()
  const { student, rightPanelDetails, fetchRightPanelDetails, remainder, notificationToastDetails } = useApp()

  const { pathname } = useLocation()

  const initialNotifications = [
    !student?.is_payment_done && auth?.role === "user" && student?.instalmentsLength < 2 && (
      <NotificationToast
        type="Course Payment"
        description="Complete your course payment to get full access of your portal."
        color="#FF6767"
      />
    ),
    student?.is_batch_paused === true && auth?.role == "user" && (
      <NotificationToast
        type="Course Paused"
        description={`Your course has been successfully paused on ${format(new Date(student?.batch_v4.at(-1)["timestamp_end"] * 1000), "do MMM yyyy")} `}
        color="#A27CF3"
      />
    ),
    notificationToastDetails?.["onboarding_data"] &&
      Object.keys(notificationToastDetails?.["onboarding_data"]).length > 0 && (
        <NotificationToast
          type="Onboarding Meet"
          description="Complete your onboarding meeting for better understanding of your portal."
          color="#07B42D"
          time={format(new Date(notificationToastDetails?.["onboarding_data"]?.["start_time"] * 1000), "do MMM yyyy")}
        />
      ),
    remainder?.["Placement Cohort"] && (
      <NotificationToast
        type="Placement Cohort"
        description="Your upcoming Placement Cohort."
        color="#07B42D"
        time={format(new Date(remainder?.["Placement Cohort"]["start_time"] * 1000), "do MMM yyyy")}
      />
    ),
    remainder?.["Exam"] && !remainder?.["Exam"].isCancelled && (
      <NotificationToast
        type="Exam"
        description={`Your upcoming Exam: ${remainder?.["Exam"]?.["instructor"]}`}
        color="#D270E5"
        time={format(new Date(remainder?.["Exam"]?.["start_time"] * 1000), "do MMM yyyy")}
      />
    ),
    notificationToastDetails?.["GSS_data"] && Object.keys(notificationToastDetails?.["GSS_data"]).length > 0 && (
      <NotificationToast
        type="Mentor Session"
        description="You have an upcoming Mentor session with your mentor."
        color="#5DC1F3"
        time={format(new Date(notificationToastDetails?.["GSS_data"]?.["start_date"] * 1000), "do MMM yyyy")}
      />
    ),
  ].filter(Boolean)

  const [currentIndex, setCurrentIndex] = useState(0)

  const handleNext = () => {
    if (currentIndex < initialNotifications.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex < initialNotifications.length - 1 ? prevIndex + 1 : 0))
    }, 5000)

    return () => clearInterval(interval)
  }, [initialNotifications.length])

  return (
    <div className="flex h-screen w-full justify-between gap-6 p-6">
      <div className="flex h-full w-[15%] min-w-[200px] max-w-[287px] flex-1 flex-col">
        <Sidebar user="" />
      </div>
      <div
        className={cn(
          "no-scrollbar flex h-full flex-col rounded-md border-2 border-gray-100",
          !rightpanelEnabled ? "w-[calc(70%-96px)]" : "w-full"
        )}
      >
        {header || <Header />}
        <div className="hide-scrollbar h-full overflow-y-auto">
          <div className="h-full flex-1 text-new-neutral" id="dashboard">
            {isLoading ? (
              <Loader />
            ) : (
              <div className="overflow-hidden">
                {initialNotifications.length > 0 && pathname === "/home" && (
                  <div className="flex gap-4 px-6 pt-6">
                    <div className="self-center">
                      <button
                        className="h-6 w-6 text-white rounded bg-[#162456] p-1 hover:bg-v2-accent-200"
                        onClick={handlePrev}
                        disabled={currentIndex === 0}
                      >
                        <ChevronLeftIcon size={16} />
                      </button>
                    </div>
                    <div className="flex flex-1 transition-transform duration-500 ease-out">
                      {initialNotifications[currentIndex]}
                    </div>
                    <div className="self-center">
                      <button
                        className="h-6 w-6 rounded text-white bg-[#162456] p-1 hover:bg-v2-accent-200"
                        onClick={handleNext}
                        disabled={currentIndex === initialNotifications.length - 1}
                      >
                        <ChevronRightIcon size={16} />
                      </button>
                    </div>
                  </div>
                )}
                {children}
              </div>
            )}
          </div>
        </div>
      </div>
      {!rightpanelEnabled && (
        <div className="flex h-full w-[15%] min-w-[200px] max-w-[287px] flex-1 flex-col">
          <RightPanel rightBar={rightPanelDetails} student={student} />
        </div>
      )}
    </div>
  )
}
