import Input from "core/components/new/Input"
import DashboardLayout from "core/layouts/DashboardLayout"
import DashboardTable from "./components/DashboardTable"
import useStudentDashboardViewModel from "./StudentDashboardViewModel"
import { useEffect } from "react"
import Toast from "core/components/Toast"
import Skeleton from "core/components/Skeleton"
import Modal from "core/components/Modal"
import Button from "core/components/new/Button"
import StandaredSelect from "core/components/StandaredSelect"
import useCreateClassViewModel from "../CreateClass/CreateClassViewModel"

export default function StudentDashboardView() {
  const {
    tableHeaders,
    students,
    mailSendLoading,
    nextStudentsLoading,
    searchingforEmail,
    searchByEmail,
    searchByBatch,
    searchByName,
    toast,
    refreshed,
    emailInputRef,
    disableStudentEmail,
    disableReason,
    updateReasonLoading,
    deleteModel,
    deleteEmail,
    setDeleteEmail,
    setDeleteModel,
    fetchStudentsByBatch,
    fetchStudentsByName,
    fetchStudentByEmail,
    changeToastVisibility,
    handleSearchByEmail,
    handleSearchByBatch,
    handleSearchByName,
    paidToUnpaid,
    setMailSendLoading,
    toggleDisableStudent,
    sendConfirmationMail,
    loadNextData,
    getTop100Students,
    openDisableStudentModal,
    closeDisableStudentModal,
    handleDisableStudentReasonChange,
    handleSubmitDisableStudentReason,
    viewDisableReason,
    deleteStudent,
    // handleEditClick,
  } = useStudentDashboardViewModel()

  const { batch, getBatches, getAllTracks, getInstructorsList } = useCreateClassViewModel()

  useEffect(() => {
    if (refreshed) {
      getTop100Students()
      emailInputRef.current?.focus()
    }
  }, [refreshed])

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchByEmail) {
        fetchStudentByEmail(searchByEmail)
      } else {
        getTop100Students()
      }
    }, 800)
    return () => clearTimeout(getData)
  }, [searchByEmail])

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchByName) fetchStudentsByName(searchByName)
      else getTop100Students()
    }, 800)

    return () => clearTimeout(getData)
  }, [searchByName])

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchByBatch) fetchStudentsByBatch(searchByBatch)
      else getTop100Students()
    }, 800)

    return () => clearTimeout(getData)
  }, [searchByBatch])

  useEffect(() => {
    const launcherFrame = document.querySelector("#launcher-frame") as HTMLElement
    if (launcherFrame) {
      launcherFrame.style.display = "none"
    }
  })
  useEffect(() => {
    if (refreshed) {
      getBatches()
      // getAllModules();
      getAllTracks()
      getInstructorsList()
    }
  }, [refreshed])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div>
          <h2 className="text-new-accent">Students Dashboard</h2>
          <p>View, find, manage & update students details</p>
        </div>

        <div className="flex w-full flex-wrap gap-4">
          <Input
            id="search-email"
            ref={emailInputRef}
            type="email"
            placeholder="Search with email"
            value={searchByEmail}
            onChange={handleSearchByEmail}
            containerClass="w-60"
            search
          />

          <Input
            id="search-name"
            placeholder="Search with name"
            value={searchByName}
            onChange={handleSearchByName}
            containerClass="w-60"
            search
          />
          <div className="w-60">
            <StandaredSelect
              id="search-batch"
              containerClass="w-60"
              required
              options={batch}
              placeholder="Select Batch"
              onChange={handleSearchByBatch}
              value={searchByBatch}
            />
          </div>
        </div>
        <DashboardTable
          tableHeaders={tableHeaders}
          students={students}
          searchByEmail={searchByEmail}
          searchingforEmail={searchingforEmail}
          mailSendLoading={mailSendLoading}
          paidToUnpaid={paidToUnpaid}
          openModal={openDisableStudentModal}
          toggleDisableStudent={toggleDisableStudent}
          sendConfirmationMail={sendConfirmationMail}
          setMailSendLoading={setMailSendLoading}
          viewDisableReason={viewDisableReason}
          deleteStudent={deleteStudent}
          deleteModel={deleteModel}
          setDeleteModel={setDeleteModel}
          deleteEmail={deleteEmail}
          setDeleteEmail={setDeleteEmail}
          // onClickEdit = {handleEditClick}
        />
        <Skeleton loading={nextStudentsLoading} exhausted={false} handler={loadNextData} />
      </div>
      <Modal open={!!disableStudentEmail} onClose={closeDisableStudentModal}>
        <form className="flex flex-col gap-4" onSubmit={handleSubmitDisableStudentReason}>
          <div className="text-new-neutral-dark space-y-1">
            <h3>Reason for disabling the student</h3>
            <p>
              Student email: <span className="text-new-accent font-medium">{disableStudentEmail}</span>
            </p>
          </div>
          <Input
            value={disableReason?.user}
            onChange={handleDisableStudentReasonChange}
            name="user"
            placeholder="Name of the admin"
          />
          <textarea
            className="min-h-[100px] w-full rounded-sm border border-new-neutral bg-new-solid-white p-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            value={disableReason?.reason}
            onChange={handleDisableStudentReasonChange}
            name="reason"
            placeholder="Reason for disabling the user"
          />
          <div className="ml-auto flex gap-2 items-center">
            <Button loading={updateReasonLoading} disabled={updateReasonLoading} outlined className="min-w-[150px]">
              Update Reason
            </Button>
          </div>
        </form>
      </Modal>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
