import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { ReferAndEarnAPIDataSourceImpl } from "data/API/Student/ReferAndEarnAPIDataSourceImpl"
import { ReferAndEarnRepositoryImpl } from "data/repository/Student/ReferAndEarnRepositoryImpl"
import { set } from "date-fns"
import { GetReferralData } from "domain/useCase/Student/ReferAndEarn/GetReferralData"
import { MyReferredCandidates } from "domain/useCase/Student/ReferAndEarn/MyReferredCandidates"
import { ReferCandidates } from "domain/useCase/Student/ReferAndEarn/ReferCandidate"
import React, { ChangeEvent, FormEvent, useState } from "react"
import { useNavigate } from "react-router-dom"
import { SDE_FORM_LINK, DSML_FORM_LINK } from "core/constants/strings"
import useLocalStorage from "core/hooks/useLocalStorage"
import { PostReferralFormData } from "domain/useCase/Student/ReferAndEarn/PostReferralFormData"

export default function ReferViewModel() {
  const [myreferredCandidates, setMyReferredCandidates] = React.useState<any>([])
  const [student] = useLocalStorage<any>("student", {} as any)

  const [myReferralCode, setMyReferralCode] = React.useState<any>("")
  const [candiDate, setCandiDate] = React.useState<any>("")

  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const navigate = useNavigate()
  const [ReferredByActive, setReferredByActive] = React.useState<boolean>(true)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<any>(["SDE", "DSML"])
  // const handleChange = (value: string) => {
  //   const selectedValue = value === "SDE" ? SDE_FORM_LINK : DSML_FORM_LINK
  //   setFormValue(value)
  //   setFormLink(selectedValue)
  // }

  const [formData, setFormData] = useState({
    yourName: student.name,
    yourEmail: student.email,
    yourNumber: "",
    referName: "",
    referEmail: "",
    referNumber: "",
    courseType: "",
    agreedToTerms: false,
  })

  const getReferredCandidatesUseCase = new GetReferralData(
    new ReferAndEarnRepositoryImpl(new ReferAndEarnAPIDataSourceImpl())
  )

  const postReferralFormDataUseCase = new PostReferralFormData(
    new ReferAndEarnRepositoryImpl(new ReferAndEarnAPIDataSourceImpl())
  )

  const toggleCheckbox = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      agreedToTerms: !prevFormData.agreedToTerms,
    }))
  }
  const cancelForm = () => {
    setIsModalOpen(false)
    setFormData({
      yourName: student.name,
      yourEmail: student.email,
      yourNumber: "",
      referName: "",
      referEmail: "",
      referNumber: "",
      courseType: "",
      agreedToTerms: false,
    })
  }
  const handleChange2 = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleOptions = (value: string) => {
    // const { name, value, type } = e.target;
    setFormData((prev) => ({
      ...prev,
      courseType: value,
    }))
  }
  const splitPhoneNumber = async (value: string) => {
    const phoneNumber = value.slice(-10)
    const countryCode = value.slice(0, -10)
    return `${countryCode}-${phoneNumber}`
  }

  const handleUserPhoneNumber = (value: string) => {
    setFormData((prev) => ({
      ...prev,
      yourNumber: value,
    }))
  }

  const handleRefferedPhoneNumber = (value: string) => {
    setFormData((prev) => ({
      ...prev,
      referNumber: value,
    }))
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const updatedFormData = { ...formData }
    setLoading(true)
    updatedFormData.referNumber = await splitPhoneNumber(formData.referNumber)
    updatedFormData.yourNumber = await splitPhoneNumber(formData.yourNumber)

    const postRequestResponse = await postReferralFormData(updatedFormData)
    if (!postRequestResponse.data.success) {
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, `This user has already been referred!`)
    } else {
      getReferralData()
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, `User referred Successfully`)
    }
    setIsModalOpen(false)
    setFormData({
      yourName: student.name,
      yourEmail: student.email,
      yourNumber: "",
      referName: "",
      referEmail: "",
      referNumber: "",
      courseType: "",
      agreedToTerms: false,
    })
  }

  async function getReferralData() {
    const response = await getReferredCandidatesUseCase.invoke(auth)
    if (response.success) {
      setMyReferredCandidates(response.data.referredStudentList)
      setMyReferralCode(response.data.referralCode)
      setCandiDate(response.data.referrerDetails)
    }
  }

  async function postReferralFormData(formData: any): Promise<any> {
    const response = await postReferralFormDataUseCase.invoke(formData, auth)
    return response
  }

  function handleCopy(text: string, msg: string) {
    navigator.clipboard.writeText(text)
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, `${msg} copied to clipboard`)
  }
  function handleViewReward(candidate: any) {
    if (candidate.studentEnrolled && candidate.studentFinalized) {
      window.location.href =
        "https://docs.google.com/forms/d/1u3XqwC6jtnZp_E_6k10cJbZX0OVS4B41kBE0nmehQ3Y/viewform?edit_requested=true#responses"
    }
  }
  return {
    student,
    myreferredCandidates,
    toast,
    candiDate,
    myReferralCode,
    ReferredByActive,
    setReferredByActive,
    handleViewReward,
    setMyReferralCode,
    setCandiDate,
    setMyReferredCandidates,
    changeToastVisibility,
    getReferralData,
    postReferralFormData,
    handleCopy,
    options,
    setOptions,
    isModalOpen,
    setIsModalOpen,
    formData,
    handleOptions,
    setFormData,
    handleSubmit,
    handleRefferedPhoneNumber,
    handleUserPhoneNumber,
    handleChange2,
    cancelForm,
    toggleCheckbox,
    loading,
    setLoading,
  }
}
