import React from "react"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import Spinner from "core/components/Spinner"
import { cn } from "core/lib/utils"
import Button from "core/components/new/Button"
import { Link, useNavigate, useParams } from "react-router-dom"
import { GetSessionDetails } from "domain/useCase/Student/Course/GetSessionDetails"
import { CourseAPIDataSourceImpl } from "data/API/Student/CourseAPIDataSourceImpl"
import { CourseRepositoryImpl } from "data/repository/Student/CourseRepositoryImpl"
import { ToggleLeetCodeProblemComplete } from "domain/useCase/Student/Course/ToggleLeetCodeProblemComplete"
import { GetFeedbackDetails } from "domain/useCase/Student/Course/GetFeedbackDetails"
import { SubmitFeedback } from "domain/useCase/Student/Course/SubmitFeedback"
import { SubmitRecordingFeedback } from "domain/useCase/Student/Course/SubmitFeedback"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { CheckBlankIcon, CheckSuccessIcon } from "core/constants/svgs"
import { isExternalProblem } from "core/utils/others"
import { capitalize, genError } from "core/utils/string"
import { useAuth } from "core/context/auth"
import { TTableData } from "core/constants/types"
import { TogglePlatformProblemStatus } from "domain/useCase/Student/Course/TogglePlatformProblemStatus"
import { GetSSMFeedbackResponse } from "domain/useCase/Student/Course/GetSSMFeedbackResponse"
import TableButton from "core/components/v2/TableButton"

export default function CourseSessionViewModel() {
  const { auth, refreshed } = useAuth()
  const { session_id }: any = useParams()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const navigate = useNavigate()

  const [sessionList, setSessionList] = useLocalStorage<any>("session", {})
  const [assignmentsTableContent, setAssignmentsTableContent] = React.useState<TTableData>({} as TTableData)
  const [warmupTableContent, setWarmupTableContent] = React.useState<TTableData>({} as TTableData)
  const [homeworkTableContent, setHomeworkTableContent] = React.useState<TTableData>({} as TTableData)
  const [practiceTableContent, setPracticeTableContent] = React.useState<TTableData>({} as TTableData)
  const [sessionDetails, setSessionDetails] = React.useState<any>(() => sessionList[session_id])
  const [courseIdsLocally] = useLocalStorage<any>("course-ids", {})
  const [coursesLocally] = useLocalStorage<any>("course", {})
  const [session, setSession] = React.useState<any>(null)
  const [courseLectures, setCourseLectures] = React.useState<any>(null)
  const [activeIndex, setActiveIndex] = React.useState<any>(-1)
  const [sessionId, setSessionId] = React.useState<any>(() => session_id)
  const [loadingTable, setLoadingTable] = React.useState<any>({
    index: -1,
    type: "",
  })
  const [courseName, setCourseName] = React.useState<any>("")
  const [loading, setLoading] = React.useState<any>(false)
  const [feedbackData, setFeedbackData] = React.useState<any>(null)
  const [isFeedbackVisible, setIsFeedbackVisible] = React.useState<boolean>(false)
  const [isRecordedFeedbackVisible, setIsRecordedFeedbackVisible] = React.useState<boolean>(false)
  const [coinsPerProblem, setCoinsPerProblem] = React.useState<number>(0)
  const [isViewSubmissionVisible, setIsViewSubmissionVisible] = React.useState<boolean>(false)
  const [feedbackResponseData, setFeedbackResponseData] = React.useState<any>({})
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [questionName, setQuestionName] = React.useState<string>("")
  const [questionType, setQuestionType] = React.useState<string>("")
  const [selectedQuestion, setSelectedQuestion] = React.useState(0)
  const [tab, setTab] = React.useState(0)

  const order = {
    rating: "Assignment Rating",
    marks: "Assignment Marks",
    feedback_response: "SSM Feedback",
    code_snippet: "Code Snippet",
    files: "Refrences",
  }

  const getSessionDetailsUseCase = new GetSessionDetails(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))

  const toggleLeetCodeProblemCompleteUseCase = new ToggleLeetCodeProblemComplete(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )

  const togglePlatformProblemStatusUseCase = new TogglePlatformProblemStatus(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )

  const getFeedbackDetailsUseCase = new GetFeedbackDetails(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))

  const submitFeedbackUseCase = new SubmitFeedback(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))

  const submitRecordingFeedbackUseCase = new SubmitRecordingFeedback(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )
  const getSSMFeedbackResponseUseCase = new GetSSMFeedbackResponse(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )

  const handleOnCloseBtnModalClick = () => {
    setIsViewSubmissionVisible(false)
  }

  const handleFeedbackBtnClick = (questionName: string, questionType: string) => {
    setIsViewSubmissionVisible(true)
    setQuestionName(questionName)
    setQuestionType(questionType)
  }

  const fetchViewSubmission = async (q: string, qType: string) => {
    setIsLoading(true)

    // Trim and format the question name
    // let questionName = q.trim().replaceAll(" ", "_").toLowerCase();
    // if (questionName.endsWith("?")) {
    //   questionName = questionName.slice(0, -1);
    // }
    setFeedbackResponseData({})

    try {
      const response = await getSSMFeedbackResponseUseCase.invoke(auth, session_id, questionName, qType)

      if (!response?.success || !response.data || response.data.length === 0) {
        changeToastDetails(STR_FAILURE, "No Feedback Response Found")
        changeToastVisibility(true)
        setIsViewSubmissionVisible(false)
        setIsLoading(false)
        return
      }

      setFeedbackResponseData(response.data)
    } catch (error) {
      console.error("Error fetching feedback response:", error)
      changeToastDetails(STR_FAILURE, "Error fetching feedback response")
      changeToastVisibility(true)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchSessionDetails = async () => {
    setLoading(true)
    const data: any = await getSessionDetailsUseCase.invoke(auth, sessionId)
    setLoading(false)

    if ("assignment_points" in data) {
      let externalProblemCount = 0
      data["assignments"]?.forEach((assignment: any) => {
        !assignment["link"].includes("leetcode") && !assignment["link"].includes("bosscoder") && externalProblemCount++
      })
      setCoinsPerProblem(Math.floor(data["assignment_points"] / externalProblemCount))
    }

    setSessionDetails(data)
    setIsFeedbackVisible(
      data?.module_name !== "master_class" &&
        !data?.is_feedback_submitted &&
        data?.is_attended &&
        Date.now() - data?.timestamp < 3 * 24 * 60 * 60 * 1000
    )

    const sessions = { ...sessionList, [sessionId]: data }
    setSessionList(sessions)
  }

  function fetchCourseLectures() {
    const courseIndex = Object.values(courseIdsLocally).findIndex(
      (courseName: any) => courseName === sessionList?.[sessionId]?.module_name
    )
    const courseName = Object.keys(courseIdsLocally)?.[courseIndex]
    const courseLectures = coursesLocally?.find((course: any) => course?.name === courseName)?.recorded_lectures
    const newCourseLectures = [...courseLectures]

    setCourseName(courseName)
    setCourseLectures(newCourseLectures)
  }

  function handleSidebarData(sessions = sessionList) {
    setSession(sessions[sessionId])

    const activeIndex = courseLectures?.findIndex((lecture: any) => lecture?.session_id === sessionId)
    setActiveIndex(activeIndex)
  }

  function onLectureClick(lecture: any, index: number) {
    if (index === activeIndex) return
    setActiveIndex(index)
    setSession(lecture)
    setSessionId(lecture?.session_id)
    navigate(`/course/${lecture?.session_id}`)
  }

  async function handleTableClick(type: string, i: number, problem: any) {
    setLoadingTable({
      index: i,
      type: type,
    })

    const prob_name = problem.name.split(" ")[0]

    await toggleLeetCodeProblemComplete(
      prob_name === "Assignment" || prob_name === "Homework" || prob_name === "Practice" || prob_name === "Warmup"
        ? problem.link
        : problem.problem_id,
      !problem?.status,
      !problem["link"].includes("leetcode") && !problem["link"].includes("bosscoder") ? coinsPerProblem : 0
    )

    setLoadingTable({
      index: -1,
      type: "",
    })
  }

  async function handleInternalProblem(type: string, i: number, problem: any) {
    setLoadingTable({
      index: i,
      type: type,
    })

    let prob_name = problem.name.split(" ")[0]

    await togglePlatformProblemStatus(
      prob_name === "Assignment" || prob_name === "Homework" || prob_name === "Practice" || prob_name === "Warmup"
        ? problem.link
        : problem.problem_id,
      !problem?.solved_on_leetcode
    )

    setLoadingTable({
      index: -1,
      type: "",
    })
  }

  function assignRows(data: any, type: string) {
    return data?.map((problem: any, i: number) => {
      const isExternalProblemLink = isExternalProblem(problem?.link)

      const lastRoute = problem?.link?.split("/")?.at(-1)
      const secondLastRoute = problem?.link?.split("/")?.at(-2)

      const problemType = secondLastRoute === "codeEditor" ? "editor" : secondLastRoute
      const problemLink = isExternalProblemLink ? problem?.link : `/${problemType}/${lastRoute}`

      return [
        <button
          className="flex h-6 w-6 items-center"
          onClick={() =>
            !isExternalProblemLink ? handleInternalProblem(type, i, problem) : handleTableClick(type, i, problem)
          }
          disabled={loadingTable.index === i || (!isExternalProblemLink && problem?.status)}
        >
          {loadingTable.index === i && type === loadingTable.type ? (
            <Spinner small />
          ) : isExternalProblemLink && problem?.status ? (
            <CheckSuccessIcon className="h-4 w-4 text-[#FBBF24]" />
          ) : problem?.status ? (
            <CheckSuccessIcon className="h-4 w-4 text-[#22C55E]" />
          ) : problem?.solved_on_leetcode ? (
            <CheckSuccessIcon className="h-4 w-4 text-[#FBBF24]" />
          ) : (
            <CheckBlankIcon className="h-4 w-4" />
          )}
        </button>,
        <span title={problem?.name} className="block w-full max-w-[130px] truncate">
          {problem?.name}
        </span>,
        <span
          className={cn(
            "block w-full max-w-[100px] truncate",
            (isExternalProblemLink && problem?.status) ||
              (problem?.solved_on_leetcode && typeof problem?.status === "boolean" && problem?.status == false)
              ? "text-[#FBBF24]"
              : typeof problem?.status === "boolean"
                ? problem?.status
                  ? "text-new-success"
                  : "text-new-failure"
                : ["mcqs"].includes(problemType)
                  ? "text-new-neutral-light"
                  : "text-new-failure"
          )}
        >
          {problem?.solved_on_leetcode && typeof problem?.status === "boolean" && problem?.status == false
            ? "Solved on LeetCode"
            : typeof problem?.status === "boolean"
              ? problem?.status
                ? "Solved"
                : ["mcqs"].includes(problemType)
                  ? "Incorrect"
                  : "Unsolved"
              : "Unsolved"}
        </span>,
        !["mcqs", "editor"].includes(problemType) ? (
          <TableButton
            outlined
            className="ml-auto w-fit"
            onClick={() => handleFeedbackBtnClick(problem?.problem_id, problem?.link?.split("/")?.at(-2))}
          >
            Feedback
          </TableButton>
        ) : (
          ""
        ),
        isExternalProblemLink ? (
          <a href={problemLink} target="_blank" rel="noopener noreferrer">
            <TableButton outlined className="ml-auto w-fit">
              Start
            </TableButton>
          </a>
        ) : (
          <Link to={problemLink}>
            <TableButton outlined className="ml-auto w-fit">
              Start
            </TableButton>
          </Link>
        ),
      ]
    })
  }

  function assignHeaders(type: string) {
    return [
      <CheckSuccessIcon className="h-4 w-4 text-new-solid-white" />,
      `${type} problem`,
      "Status",
      "SSM Feedback",
      "Link to assignment",
    ]
  }

  function changeTableData(data: any) {
    const problemTypes = ["assignments", "warmup", "homework", "practice"]
    const setProblemsList = [
      setAssignmentsTableContent,
      setWarmupTableContent,
      setHomeworkTableContent,
      setPracticeTableContent,
    ]

    problemTypes.forEach((type: string, i: number) => {
      setProblemsList[i]({
        header: assignHeaders(capitalize(type)),
        rows: assignRows(data?.[type], type),
      })
    })
  }

  async function toggleLeetCodeProblemComplete(problem_id: string, status: boolean, coins: number) {
    function getProblems(type: string) {
      return sessionDetails[type].map((problem: any) => {
        return ((problem?.name.split(" ")[0] === "Assignment" ||
          problem?.name.split(" ")[0] === "Homework" ||
          problem?.name.split(" ")[0] === "Practice" ||
          problem?.name.split(" ")[0] === "Warmup") &&
          problem.link === problem_id) ||
          problem.problem_id === problem_id
          ? { ...problem, status }
          : problem
      })
    }

    await toggleLeetCodeProblemCompleteUseCase.invoke(auth, problem_id, status, coins)

    const updatedSessionDetails = {
      ...sessionDetails,
      assignments: getProblems("assignments"),
      warmup: getProblems("warmup"),
      homework: getProblems("homework"),
      practice: getProblems("practice"),
    }
    setSessionDetails(updatedSessionDetails)
    setSessionList({
      ...sessionList,
      [session_id]: updatedSessionDetails,
    })
  }

  async function togglePlatformProblemStatus(problem_id: string, solved_on_leetcode: boolean) {
    function getProblems(type: string) {
      return sessionDetails[type].map((problem: any) => {
        return ((problem?.name.split(" ")[0] === "Assignment" ||
          problem?.name.split(" ")[0] === "Homework" ||
          problem?.name.split(" ")[0] === "Practice" ||
          problem?.name.split(" ")[0] === "Warmup") &&
          problem.link === problem_id) ||
          problem.problem_id === problem_id
          ? { ...problem, solved_on_leetcode }
          : problem
      })
    }

    await togglePlatformProblemStatusUseCase.invoke(auth, problem_id, solved_on_leetcode)

    let updatedSessionDetails = {
      ...sessionDetails,
      assignments: getProblems("assignments"),
      warmup: getProblems("warmup"),
      homework: getProblems("homework"),
      practice: getProblems("practice"),
    }
    setSessionDetails(updatedSessionDetails)
    setSessionList({
      ...sessionList,
      [session_id]: updatedSessionDetails,
    })
  }

  async function submitFeedback(ratings: number[], suggestion?: string) {
    const isValid = ratings?.every((rating) => rating !== 0)

    if (!isValid) {
      changeToastDetails(STR_FAILURE, "Please rate all the questions")
      changeToastVisibility(true)
      return
    }

    const data: any = { session_id, live_class_suggestion: suggestion }
    for (let i = 0; i < ratings.length; ++i) {
      data[`${i + 1}`] = ratings[i]
    }

    const response: any = await submitFeedbackUseCase.invoke(auth, data)

    if (!response?.success) {
      changeToastDetails(STR_SUCCESS, genError(response, "Feedback submission failed!"))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Feedback submitted successfully!")
    setIsFeedbackVisible(false)
    changeToastVisibility(true)
    await fetchSessionDetails()
    changeToastVisibility(false)
  }
  async function getFeedbackDetails(class_id: string = ""): Promise<any> {
    const response = await getFeedbackDetailsUseCase.invoke(auth, class_id)
    if (!response?.success) {
      changeToastDetails(STR_SUCCESS, genError(response, "Feedback loading failed!"))
      changeToastVisibility(true)
      return
    }
    setFeedbackData(response?.data[0])
    return response
  }

  async function submitRecordingFeedback(ratings: number[], suggestion?: string) {
    const isValid = ratings?.every((rating) => rating !== 0)

    if (!isValid) {
      changeToastDetails(STR_FAILURE, "Please rate all the questions")
      changeToastVisibility(true)
      return
    }

    const data: any = { session_id, recorded_class_suggestion: suggestion }
    for (let i = 0; i < ratings.length; ++i) {
      data[`${i + 6}`] = ratings[i]
    }

    const response: any = await submitRecordingFeedbackUseCase.invoke(auth, data)

    if (!response?.success) {
      changeToastDetails(STR_SUCCESS, genError(response, "Recording Feedback submission failed!"))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Recording Feedback submitted successfully!")
    setIsRecordedFeedbackVisible(false)
    changeToastVisibility(true)
    await fetchSessionDetails()
    changeToastVisibility(false)
  }

  const handleSelectedQuestionBtnClick = (number: number) => {
    setSelectedQuestion(number)
  }
  const handleTabChange = (selectedTab: number) => {
    setTab(selectedTab)
  }

  const TABS = ["Recorded Video", "Assignments", "Notes"]

  return {
    questionType,
    toast,
    sessionId,
    assignmentsTableContent,
    warmupTableContent,
    homeworkTableContent,
    practiceTableContent,
    sessionDetails,
    courseLectures,
    session,
    sessionList,
    activeIndex,
    loadingTable,
    courseName,
    loading,
    refreshed,
    isFeedbackVisible,
    setIsFeedbackVisible,
    isRecordedFeedbackVisible,
    setIsRecordedFeedbackVisible,
    handleSidebarData,
    onLectureClick,
    fetchSessionDetails,
    feedbackData,
    getFeedbackDetails,
    submitFeedback,
    submitRecordingFeedback,
    changeToastVisibility,
    fetchCourseLectures,
    changeTableData,
    navigate,
    handleFeedbackBtnClick,
    isViewSubmissionVisible,
    order,
    handleOnCloseBtnModalClick,
    fetchViewSubmission,
    questionName,
    isLoading,
    feedbackResponseData,
    selectedQuestion,
    handleSelectedQuestionBtnClick,
    tab,
    handleTabChange,
    TABS,
    setTab,
  }
}
