import React from "react"

export default function Feedback5(props: any) {
  const { selectedColor } = props
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="32" fill={selectedColor} />
      <circle cx="22.8086" cy="28.5918" r="3.40426" fill="white" />
      <circle cx="40.8516" cy="28.5918" r="3.40426" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.644 46.2979C44.3313 46.9818 43.5234 47.2828 42.8394 46.9701L38.7124 45.0835C34.1559 43.0005 28.9044 43.0759 24.4095 45.2888L21.0283 46.9534C20.3535 47.2856 19.5373 47.0079 19.2051 46.3332C18.873 45.6584 19.1507 44.8422 19.8254 44.51L23.2067 42.8454C28.4354 40.2713 34.5443 40.1836 39.8447 42.6067L43.9717 44.4933C44.6557 44.806 44.9566 45.6139 44.644 46.2979Z"
        fill="white"
      />
    </svg>
  )
}
