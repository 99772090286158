export default function Award({ className }: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 18 18" fill="none">
      <path
        d="M8.07203 2.31085C8.40793 1.47438 9.59207 1.47438 9.92797 2.31085L11.0937 5.2137C11.2367 5.56991 11.571 5.8128 11.954 5.83876L15.075 6.05038C15.9743 6.11136 16.3403 7.23755 15.6485 7.81549L13.248 9.82118C12.9534 10.0673 12.8257 10.4603 12.9194 10.8325L13.6825 13.8662C13.9024 14.7403 12.9445 15.4364 12.181 14.9571L9.53171 13.2938C9.20661 13.0897 8.79339 13.0897 8.46829 13.2938L5.81896 14.9571C5.05555 15.4364 4.09756 14.7403 4.31747 13.8662L5.08065 10.8325C5.1743 10.4603 5.0466 10.0673 4.75204 9.82118L2.35148 7.81549C1.65975 7.23755 2.02567 6.11136 2.925 6.05038L6.046 5.83876C6.42897 5.8128 6.76328 5.56991 6.90632 5.2137L8.07203 2.31085Z"
        fill="#E77B14"
      />
    </svg>
  )
}
