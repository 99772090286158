import { cn } from "core/lib/utils"
import { useState } from "react"
// import cn from "classnames"

export default function StandardSelect({
  id,
  className,
  value,
  onChange,
  placeholder,
  options,
  required = false,
}: any) {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleSelectOption = (option: string) => {
    onChange(option)
    setIsOpen(false)
  }

  return (
    <div className="relative w-[300px] rounded-[4px]">
      <button
        type="button"
        className={cn(
          className,
          "w-full cursor-pointer appearance-none border border-gray-300 bg-white px-4 py-2 text-left text-sm    text-[#646464] font-inter font-normal leading-normal focus:none outline-none"
        )}
        onClick={toggleDropdown}
      >
        {value || placeholder}
      </button>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="absolute right-3 top-3 w-5 h-[22px]  text-gray-600 cursor-pointer"
        viewBox="0 0 16 16"
        fill="none"
        onClick={toggleDropdown}
      >
        <path
          d="M7.99993 8.78145L11.2999 5.48145L12.2426 6.42411L7.99993 10.6668L3.75726 6.42411L4.69993 5.48145L7.99993 8.78145Z"
          fill="#646464"
        />
      </svg>
      {isOpen && (
        <ul className="absolute z-10 mt-1 w-full bg-white border border-gray-300 shadow-md p-1 rounded-[4px]">
          {options?.map((option: string, i: number) => (
            <li
              key={i}
              className="px-4 py-2  leading-4  cursor-pointer hover:bg-gray-100 rounded-[4px]
              text-gray-500 font-inter text-base font-medium"
              onClick={() => handleSelectOption(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
