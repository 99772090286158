import React, { useEffect, useRef } from "react"
import Avatar from "react-avatar"
import useViewModel from "./HeaderViewModel"
import ProfileMenu from "./components/ProfileMenu"
import NotificationCard from "./components/NotificationCard"
import { useApp } from "core/context/app"
import { CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "./components/Command"
import TableButton from "core/components/v2/TableButton"
import { RemoveKeyAccelerator, RemoveKeyKCE } from "./utils/keyToRemoveBatchWise"
import SearchItemLogo from "assets/svgs/HomePage/SearchItemLogo"
import { BellIcon, ChevronDownIcon, ChevronUpIcon, SearchIcon } from "lucide-react"

const SearchView: React.FC = () => {
  const {
    auth,
    open,
    refreshed,
    searchQuery,
    dropdownVisible,
    ProfileMenuListData,
    notificationDetails,
    filteredList,
    notificationVisible,
    notificationBubble,
    notificationRef,
    dropdownRef,
    searchMapping,
    setOpen,
    setSearchQuery,
    setFilteredList,
    handleNotificationRedirect,
    fetchNotificationDetails,
    handleSearchModal,
    toggleDropdown,
    toggleNotification,
    handleSelectedSearch,
    setDropdownVisible,
    setNotificationVisible,
    handleProfileMenubuttonsClick,
  } = useViewModel()

  const { student } = useApp()

  const isAccelerator = student?.batch?.includes("Accelerator")
  const isKCE = student?.batch?.includes("KCE")
  const isAC = student?.batch?.includes("AC")

  const commandListRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if ((e.key === "k" && (e.metaKey || e.ctrlKey)) || e.key === "/") {
        e.preventDefault()
        setOpen((open) => !open)
      }
    }
    document.addEventListener("keydown", down)
    return () => document.removeEventListener("keydown", down)
  }, [])

  useEffect(() => {
    if (refreshed) {
      let filteredKeys = Object.keys(searchMapping)

      if (isAccelerator) {
        filteredKeys = filteredKeys.filter((key) => !RemoveKeyAccelerator.includes(key))
      }

      if (isKCE) {
        filteredKeys = filteredKeys.filter((key) => !RemoveKeyKCE.includes(key))
      }

      setFilteredList(filteredKeys)
    }
  }, [refreshed])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) ||
        (notificationRef.current && !notificationRef.current.contains(event.target as Node))
      ) {
        setDropdownVisible(false)
        setNotificationVisible(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div className="flex justify-between rounded bg-[#f9f9f9] py-3 px-6">
      <div className="relative min-w-[300px] max-w-[600px]">
        <TableButton
          className="flex justify-between gap-4 rounded border-[0.5px] border-[#d3d3d3] bg-white px-4 py-2"
          onClick={handleSearchModal}
        >
          <p className="self-center inline-flex items-center gap-2">
            Search
            <code className="rounded border-[0.5px] border-v2-gray-400 p-1 pb-0 leading-tight text-[10px] font-semibold text-v2-gray-400">
              /
            </code>
          </p>
          <div className="h-4 w-4">
            <SearchIcon size={16} />
          </div>
        </TableButton>
      </div>
      <div className="flex gap-2">
        {!isAccelerator && (
          <div className="relative inline-block">
            <div
              className="relative h-8 w-8 flex items-center justify-center cursor-pointer rounded bg-white p-1 hover:bg-[#e3e3e3]"
              onClick={toggleNotification}
            >
              <BellIcon size={20} />
              {notificationBubble && (
                <div className="z-8 absolute top-1 right-[6px] h-2 w-2 rounded-full border-[1px] border-white bg-[#DC2626]"></div>
              )}
            </div>
            {notificationVisible && (
              <div
                className="hide-scrollbar absolute top-full right-0 z-10 mt-1 h-fit max-h-[300px] w-[200px] space-y-2 overflow-y-auto rounded bg-white p-2 shadow-lg"
                ref={notificationRef}
              >
                {notificationDetails?.length > 0 &&
                  notificationDetails.map((notification: any, index: number) => (
                    <NotificationCard
                      key={notification.type}
                      data={notification}
                      onClick={handleNotificationRedirect}
                      index={index}
                    />
                  ))}
              </div>
            )}
          </div>
        )}
        <Avatar className="rounded-sm object-cover" src={student?.photo} name={student?.name} size="32" />
        <div className="relative inline-block">
          <div className="h-8 w-8 cursor-pointer rounded bg-white p-2 hover:bg-[#e3e3e3]" onClick={toggleDropdown}>
            {dropdownVisible ? <ChevronUpIcon size={16} /> : <ChevronDownIcon size={16} />}
          </div>
          {dropdownVisible && (
            <ProfileMenu
              listdata={ProfileMenuListData}
              handleButtonClick={handleProfileMenubuttonsClick}
              Ref={dropdownRef}
            />
          )}
        </div>
      </div>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput
          autoFocus
          value={searchQuery}
          onValueChange={setSearchQuery}
          placeholder="Type a command or search..."
        />
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandList ref={commandListRef}>
          <CommandGroup>
            {filteredList
              .filter((result) => {
                if (isAC) {
                  return result !== "Placement Cohort Test" && result !== "Placement"
                }
                return true
              })
              .map((result, index) => (
                <CommandItem
                  className={`flex gap-2 rounded-lg`}
                  key={result}
                  value={result}
                  keywords={searchMapping[result]}
                  onSelect={(value) => handleSelectedSearch(value)}
                >
                  <SearchItemLogo />
                  {result}
                </CommandItem>
              ))}
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </div>
  )
}

export default SearchView
