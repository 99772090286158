import DashboardLayout from "core/layouts/DashboardLayout"
import React, { ChangeEvent, FormEvent, useState } from "react"
import ReferAndEarnBasicInfo from "./components/ReferAndEarnBasicInfo"
import ReferralList from "./components/ReferralList"
import useReferViewModel from "./ReferViewModel"
import GeneralGuideline from "./components/GeneralGuideline"
import Toast from "core/components/Toast"
import Model from "core/components/Model"
import Spinner from "core/components/Spinner"
import { Button } from "core/components/v2/Button"
import CloseSharpIcon from "@mui/icons-material/CloseSharp"
import { Link } from "react-router-dom"
import StandardSelect from "core/components/v2/StandardSelect"
import Modal from "core/components/Modal"
import Cross from "assets/svgs/CrossWithRoundCorners"
import Input from "core/components/new/Input"
import { useAuth } from "core/context/auth"
import NewReferralFormComponent from "./components/NewReferralFormComponent"
import { Token } from "@mui/icons-material"
import { useApp } from "core/context/app/useApp"
import { PostReferralFormData } from "domain/useCase/Student/ReferAndEarn/PostReferralFormData"
import Select from "core/components/new/Select"
import Loader from "core/components/Loader"
import ConditionalLoader from "core/components/ConditionalLoader"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"

export default function ReferView() {
  const { auth } = useAuth()
  const {
    myReferralCode,
    ReferredByActive,
    getReferralData,
    postReferralFormData,
    candiDate,
    myreferredCandidates,
    handleCopy,
    toast,
    setReferredByActive,
    changeToastVisibility,
    handleViewReward,
    options,
    setOptions,
    isModalOpen,
    setIsModalOpen,
    student,
    formData,
    handleOptions,
    setFormData,
    handleSubmit,
    handleRefferedPhoneNumber,
    handleUserPhoneNumber,
    handleChange2,
    cancelForm,
    toggleCheckbox,
    loading,
    setLoading,
  } = useReferViewModel()

  React.useEffect(() => {
    getReferralData()
  }, [])

  return (
    <DashboardLayoutv2>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      <div className="m-6 flex h-full flex-col space-y-4 rounded-lg border-[0.5px] p-6 md:flex-row md:space-y-0 md:space-x-4">
        <div className="flex h-full w-full flex-col space-y-4 md:w-3/5">
          <ReferAndEarnBasicInfo setIsModalOpen={setIsModalOpen} />
          <ReferralList
            myreferredCandidates={myreferredCandidates}
            handleCopy={handleCopy}
            candidate={candiDate}
            referredByActive={ReferredByActive}
            setReferredByActive={setReferredByActive}
            handleViewReward={handleViewReward}
          />
        </div>

        <Modal
          open={isModalOpen}
          width="w-[349px]"
          height="h-[658px]"
          className="p-[24px] rounded-[10px] border border-gray-300 bg-white shadow-md"
        >
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col justify-between m-0 p-0">
              <div className="flex flex-col justify-between">
                <div className="flex items-center justify-between mb-[8px]">
                  <div className="w-[121px] h-[19px] text-[#1b3473] text-base font-['Inter'] font-semibold">
                    Referee details
                  </div>
                </div>

                <div className="flex flex-col justify-between">
                  <NewReferralFormComponent
                    fieldName="Your Name"
                    type="text"
                    name="yourName"
                    value={formData.yourName}
                    onChange={handleChange2}
                  />
                  <NewReferralFormComponent
                    fieldName="Your Email Id"
                    type="email"
                    name="yourEmail"
                    value={formData.yourEmail}
                    onChange={handleChange2}
                  />
                  <NewReferralFormComponent
                    fieldName="Your Contact Number"
                    type="phoneNumber"
                    name="yourNumber"
                    value={formData.yourNumber}
                    onChange={handleUserPhoneNumber}
                  />
                  <NewReferralFormComponent
                    fieldName="Name (You want to refer)"
                    type="text"
                    name="referName"
                    value={formData.referName}
                    onChange={handleChange2}
                  />
                  <NewReferralFormComponent
                    fieldName="Email (You want to refer)"
                    type="email"
                    name="referEmail"
                    value={formData.referEmail}
                    onChange={handleChange2}
                  />
                  <NewReferralFormComponent
                    fieldName="Contact Number (You want to refer)"
                    type="phoneNumber"
                    name="referNumber"
                    value={formData.referNumber}
                    onChange={handleRefferedPhoneNumber}
                  />
                  <div className="flex flex-col justify-between gap-0 font-semibold my-[8px]">
                    <div className="text-new-solid-black text-[14px] font-normal">
                      Select Course type<span className="text-red-600">*</span>
                    </div>
                    <StandardSelect
                      className="px-4 font-['Inter'] text-sm font-normal rounded-[4px] mt-1 text-new-neutral border border-gray-300 focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      placeholder={"Select an option..."}
                      value={formData.courseType}
                      onChange={handleOptions}
                      options={options}
                      required
                    ></StandardSelect>
                  </div>

                  <div className="flex flex-row justify-between my-[8px]">
                    <label className="flex items-start cursor-pointer">
                      <input
                        type="checkbox"
                        className="mr-1 m-[2px] checked:bg-[#07B42D]"
                        checked={formData.agreedToTerms}
                        onChange={toggleCheckbox}
                      />
                      <span className="pl-3 pt-0 w-[265px] h-[34px] font-['Inter'] text-[14px] leading-[17px]">
                        I have cross-checked the information provided above.
                      </span>
                    </label>
                  </div>
                  <div className="grid grid-cols-3 gap-[14px] mt-[8px]">
                    <div></div>
                    <div
                      onClick={cancelForm}
                      className="flex justify-center items-center w-full border-2 border-gray-300 bg-white text-new-solid-black rounded-[4px] cursor-pointer font-inter text-base font-medium leading-normal h-[35px] mt-[6px] p-[6px] text-[14px]"
                    >
                      {" "}
                      Cancel
                    </div>
                    {formData.yourName != "" &&
                    formData.yourEmail != "" &&
                    formData.yourNumber != "" &&
                    formData.referName != "" &&
                    formData.referEmail != "" &&
                    formData.referNumber != "" &&
                    formData.agreedToTerms == true &&
                    (formData.courseType === "SDE" || formData.courseType === "DSML") ? (
                      <div>
                        <ConditionalLoader
                          isLoading={loading}
                          loader={
                            <div className="flex justify-center items-center w-full hover:bg-new-accent-light bg-new-accent-light text-white rounded-[4px]  font-inter text-base font-bold leading-normal h-[35px] mt-[6px] p-[6px] text-[14px]">
                              <Loader height={"35px"} dark={false} xs={true} />
                            </div>
                          }
                          children={
                            <button
                              type="submit"
                              className={
                                "flex justify-center w-full hover:bg-[#03249A] bg-new-accent text-white rounded-[4px]  font-inter text-base font-medium leading-normal h-[35px] mt-[6px] p-[6px] text-[14px]"
                              }
                            >
                              Submit
                            </button>
                          }
                        />
                      </div>
                    ) : (
                      <div className="flex justify-center w-full bg-gray-400 text-white rounded-[4px] cursor-pointer font-inter text-base font-medium leading-normal h-[35px] mt-[6px] p-[6px] text-[14px]">
                        {" "}
                        Submit
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal>

        <div className="flex h-full w-full flex-col rounded-lg border-[0.5px] shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)] md:w-2/5">
          <GeneralGuideline myReferralCode={student?.email} handleCopy={handleCopy} />
        </div>
      </div>
    </DashboardLayoutv2>
  )
}
