import Button from "core/components/new/Button"
import StarRating from "core/components/StarRating"
import { STR_MENTOR_ALLOTED } from "core/constants/strings"
import Avatar from "react-avatar"
import { LinkedInIcon } from "core/constants/svgs"
import Alumni from "assets/svgs/Alumni"
import { useState } from "react"

export default function MentorCard({ index, selectedMentors, mentor, alloted, handleMentorSelection }: any) {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <div className="relative rounded-sm bg-new-solid-white shadow-[0px_3px_7px_rgba(0,0,0,0.1)]">
      {mentor?.is_alumni && <Alumni className="absolute top-2" setIsHovered={setIsHovered} />}
      {isHovered && (
        <div className="absolute left-[80px] top-[-16px] flex h-[28px] w-[110px] items-center justify-center bg-black">
          <h5 className="text-white">Bosscoder Alumni</h5>
        </div>
      )}
      <div className="mx-6 my-4 flex gap-4">
        <Avatar className="shrink-0 rounded-sm object-cover" src={mentor?.photo} name={mentor?.name} size="67" />
        <div className="flex w-full flex-1 justify-between">
          <div className="flex flex-col gap-1">
            <h3 className="text-new-neutral-dark">{mentor?.name}</h3>
            <h5 className="mb-auto flex gap-1 text-new-neutral-dark">
              <span>{mentor?.current_company}</span>
              <span>|</span>
              <span>{mentor?.experience}yrs</span>
            </h5>
            <StarRating rating={Math.round(mentor?.rating * 10) / 10} total={5} />
          </div>
          <div className="flex flex-col items-end justify-between">
            <a href={mentor?.linkedin} target="_blank" rel="noreferrer">
              <LinkedInIcon className="h-5 w-5" />
            </a>
            {alloted ? (
              <h4 className="text-new-success">{STR_MENTOR_ALLOTED}</h4>
            ) : (
              <Button
                small
                outlined
                selected={selectedMentors?.includes(index)}
                onClick={() => handleMentorSelection(index)}
                className="w-fit"
              >
                {selectedMentors?.includes(index) ? "Selected" : "Select Mentor"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
