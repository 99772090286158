import DashboardLayout from "core/layouts/DashboardLayout"
import Toast from "core/components/Toast"
import useAvailabilityViewModel from "./AvailabilityViewModel"
import Button from "core/components/new/Button"
import { AddIcon, ArrowIcon, CrossIcon, InfoIcon, SubtractIcon } from "core/constants/svgs"
import Select from "core/components/new/Select"
import React from "react"
import { isEmpty } from "core/utils/misc"
import ConditionalLoader from "core/components/ConditionalLoader"
import Loader from "core/components/Loader"
import { capitalize } from "core/utils/string"
import Input from "core/components/new/Input"
import { format } from "date-fns"

export default function AvailabilityView() {
  const {
    toast,
    refreshed,
    isLoading,
    dayValues,
    updateLoading,
    BDLoading,
    BDList,
    date,
    OPTION_HOUR24_LIST,
    newStart,
    newEnd,
    getCurrentDate,
    changeToastVisibility,
    fetchAvailability,
    handleSlotChange,
    handleNewSlotChange,
    handleAddSlot,
    handleRemoveSlot,
    handleToggleDay,
    updateAvailabilitySlots,
    fetchGoalSettingAvailability,
    submitBlockDates,
    handleDateChange,
    handleRemoveDate,
  } = useAvailabilityViewModel()

  React.useEffect(() => {
    if (refreshed) {
      fetchAvailability()
      fetchGoalSettingAvailability()
    }
  }, [refreshed])

  React.useEffect(() => {}, [newStart, newEnd])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <h2 className="text-new-accent">Availability</h2>
        <ConditionalLoader isLoading={isLoading} loader={<Loader small />}>
          <div className="flex w-full flex-wrap divide-x">
            <div className="flex flex-1 flex-col gap-4 pr-6">
              <div className="flex w-full items-center justify-between">
                <h3 className="text-new-neutral-dark">Slots</h3>
                <Button outlined className="w-[100px]" onClick={updateAvailabilitySlots} loading={updateLoading}>
                  Update Availability
                </Button>
              </div>
              <div className="flex flex-col divide-y">
                {Object.keys(dayValues).map((dayKey) => {
                  const day = dayValues[dayKey]
                  return (
                    <div key={dayKey} className="flex flex-col gap-2 py-4">
                      <div className="flex items-start gap-3">
                        <div className="flex items-center gap-3">
                          <input
                            type="checkbox"
                            checked={day?.length > 0}
                            onChange={(e) => handleToggleDay(e, dayKey)}
                            className="cursor-pointer p-2"
                          />
                          <p className="font-medium">{capitalize(dayKey)}</p>
                        </div>
                        <div className="ml-auto">
                          <ConditionalLoader isLoading={isEmpty(day)} loader="Unavailable">
                            <div key={dayKey} className="flex flex-col gap-0.5">
                              <h6 className="text-xs font-medium text-new-neutral-shallow">Choose timings</h6>
                              <div className="flex items-center gap-2">
                                <div className="flex items-center gap-2 border p-1">
                                  <Select
                                    value={newStart[dayKey]}
                                    onChange={(e) => handleNewSlotChange(e, dayKey)}
                                    options={OPTION_HOUR24_LIST}
                                  />
                                  <span>
                                    <ArrowIcon className="h-4 w-4" />
                                  </span>
                                  <Select disabled value={newEnd[dayKey]} options={OPTION_HOUR24_LIST} />
                                </div>
                                <button
                                  className="w-fit rounded-full border border-new-neutral-light p-1 hover:border-new-solid-white hover:bg-new-accent hover:text-new-solid-white"
                                  onClick={() => handleAddSlot(dayKey)}
                                >
                                  <AddIcon className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                          </ConditionalLoader>
                        </div>
                      </div>
                      {day?.length > 0 &&
                        day?.map((s: any, i: number) => {
                          const index = i
                          return (
                            <div key={JSON.stringify(s) + i} className="flex items-center gap-2 self-end">
                              <div className="flex items-center gap-2 border p-1">
                                <Select
                                  value={parseInt(day[index]?.start)}
                                  onChange={(e) => handleSlotChange(e, dayKey, index)}
                                >
                                  {[...Array(24)].map((_, i) => (
                                    <option key={i} value={i}>
                                      {i}
                                    </option>
                                  ))}
                                </Select>
                                <span>
                                  <ArrowIcon className="h-4 w-4" />
                                </span>
                                <Select disabled value={parseInt(day[index]?.end)} onChange={() => {}}>
                                  {[...Array(24)].map((_, i) => (
                                    <option key={i} value={i}>
                                      {i}
                                    </option>
                                  ))}
                                </Select>
                              </div>
                              <button
                                className="w-fit rounded-full border border-new-neutral-light p-1 hover:border-new-solid-white hover:bg-new-accent hover:text-new-solid-white"
                                onClick={() => handleRemoveSlot(dayKey, index)}
                              >
                                <SubtractIcon className="h-4 w-4" />
                              </button>
                            </div>
                          )
                        })}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="flex flex-1 flex-col gap-4 px-6">
              <div className="space-y-2">
                <h3 className="text-new-neutral-dark">Block Dates</h3>
                <p>Add dates when you will unavailable to take calls</p>
              </div>
              <Input
                type="date"
                containerClass="w-full"
                value={date}
                onChange={handleDateChange}
                min={getCurrentDate()}
              />
              <div className="flex flex-col gap-4 rounded-sm border p-4">
                <ConditionalLoader isLoading={isEmpty(BDList)} loader={<em>No dates blocked</em>}>
                  {BDList?.filter((bd: any) => new Date(bd) >= new Date()).map((bd: any, i: number) => (
                    <div key={i} className="flex items-center gap-4">
                      <h4>{format(new Date(bd), "dd/MM/yyyy")}</h4>
                      <button
                        onClick={() => handleRemoveDate(i)}
                        className="rounded-sm bg-new-neutral-dark p-1 text-new-solid-white"
                      >
                        <CrossIcon className="h-4 w-4" />
                      </button>
                    </div>
                  ))}
                </ConditionalLoader>
              </div>
              <Button outlined className="w-[100px]" loading={BDLoading} onClick={submitBlockDates}>
                Save Dates
              </Button>
            </div>
            <div className="flex flex-1 flex-col gap-4 pl-6">
              <div className="flex items-center gap-2">
                <span>
                  <InfoIcon className="h-4 w-4" />
                </span>
                <h3 className="text-new-neutral-dark">Information Panel</h3>
              </div>
              <div className="space-y-4">
                <div className="space-y-1">
                  <h4>Target for mentees:</h4>
                  <p>2-3 problems per day is a good enough target.</p>
                </div>
                <div className="space-y-1">
                  <h4>Motivation:</h4>
                  <p>Motivate the mentees that it is possible to achieve the target with consistency.</p>
                </div>
                <div className="space-y-1">
                  <h4>Mentor session format:</h4>
                  <ul className="list-decimal pl-6">
                    <li>First 10-15 min: Ask about mentee's performance and live classes.</li>
                    <li>Next 30-40 min: Mock interview (2 coding problems)</li>
                    <li>Next 10-15 min: Detailed feedback to mentees.</li>
                  </ul>
                </div>
                <div className="space-y-1">
                  <h4>Set action item:</h4>
                  <p>Set action item for mentees to be achieved before the next mentor sessions.</p>
                </div>
                <div className="space-y-1">
                  <h4>Pointers on feedback:</h4>
                  <p>
                    Confidence, Time complexity analysis, Thinking process, Coding, Naming convention, Communication
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ConditionalLoader>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
