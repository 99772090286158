import React from "react"

export default function Feedback10(props: any) {
  const { selectedColor } = props
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="32" fill={selectedColor} />
      <path
        d="M27.8842 27.1867C28.5229 27.1867 29.0512 26.6649 28.938 26.0363C28.8633 25.6212 28.7482 25.2143 28.5938 24.8221C28.2988 24.0725 27.8665 23.3913 27.3214 22.8176C26.7763 22.2438 26.1292 21.7887 25.417 21.4782C24.7049 21.1676 23.9416 21.0078 23.1707 21.0078C22.3999 21.0078 21.6366 21.1676 20.9244 21.4782C20.2122 21.7887 19.5651 22.2438 19.02 22.8176C18.475 23.3913 18.0426 24.0725 17.7476 24.8221C17.5933 25.2143 17.4781 25.6212 17.4034 26.0363C17.2903 26.6649 17.8185 27.1867 18.4572 27.1867C19.0959 27.1867 19.597 26.658 19.7825 26.0468C19.8126 25.9479 19.8466 25.8501 19.8845 25.7538C20.0632 25.2996 20.3252 24.8868 20.6555 24.5391C20.9858 24.1914 21.378 23.9157 21.8095 23.7275C22.2411 23.5393 22.7036 23.4425 23.1707 23.4425C23.6378 23.4425 24.1004 23.5393 24.5319 23.7275C24.9635 23.9157 25.3556 24.1914 25.6859 24.5391C26.0162 24.8868 26.2782 25.2996 26.457 25.7538C26.4949 25.8501 26.5288 25.9479 26.5589 26.0468C26.7445 26.658 27.2455 27.1867 27.8842 27.1867Z"
        fill="white"
      />
      <path
        d="M47.0387 27.1867C47.6774 27.1867 48.2057 26.6649 48.0926 26.0363C48.0178 25.6212 47.9027 25.2143 47.7484 24.8221C47.4534 24.0725 47.021 23.3913 46.4759 22.8176C45.9309 22.2438 45.2838 21.7887 44.5716 21.4782C43.8594 21.1676 43.0961 21.0078 42.3253 21.0078C41.5544 21.0078 40.7911 21.1676 40.0789 21.4782C39.3668 21.7887 38.7197 22.2438 38.1746 22.8176C37.6295 23.3913 37.1971 24.0725 36.9021 24.8221C36.7478 25.2143 36.6327 25.6212 36.558 26.0363C36.4448 26.6649 36.9731 27.1867 37.6118 27.1867C38.2505 27.1867 38.7515 26.658 38.9371 26.0468C38.9671 25.9479 39.0011 25.8501 39.039 25.7538C39.2178 25.2996 39.4798 24.8868 39.8101 24.5391C40.1404 24.1914 40.5325 23.9157 40.9641 23.7275C41.3956 23.5393 41.8581 23.4425 42.3253 23.4425C42.7924 23.4425 43.2549 23.5393 43.6865 23.7275C44.118 23.9157 44.5101 24.1914 44.8404 24.5391C45.1707 24.8868 45.4328 25.2996 45.6115 25.7538C45.6494 25.8501 45.6834 25.9479 45.7134 26.0468C45.899 26.658 46.4 27.1867 47.0387 27.1867Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1881 41.3037C19.5007 40.6197 20.3087 40.3187 20.9926 40.6314L25.1196 42.518C29.6761 44.601 34.9276 44.5257 39.4225 42.3128L42.8038 40.6482C43.4785 40.316 44.2947 40.5937 44.6269 41.2684C44.9591 41.9431 44.6814 42.7594 44.0067 43.0915L40.6254 44.7562C35.3966 47.3303 29.2878 47.418 23.9873 44.9949L19.8603 43.1083C19.1764 42.7956 18.8754 41.9877 19.1881 41.3037Z"
        fill="white"
      />
    </svg>
  )
}
