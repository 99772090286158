import React from "react"
// import { MoreIcon } from 'core/constants/svgs';
import Button from "core/components/new/Button"
import { STR_MENTOR_SESSION } from "core/constants/strings"
import { format } from "date-fns"
import Avatar from "react-avatar"
import { cn } from "core/lib/utils"
import useTimer from "core/hooks/useTimer"

export default function MentorSessionCard({ session, mentor }: any) {
  const [timer] = useTimer(session?.start_timestamp * 1000)
  const minsLeft = (session?.start_timestamp * 1000 - Date.now()) / 1000 / 60
  return (
    <div className="flex gap-4 rounded bg-new-solid-red-light p-8">
      <Avatar
        className="shrink-0 rounded-sm object-cover shadow-[0px_4px_8px_rgba(232,108,108,0.15)]"
        src={mentor?.photo}
        name={mentor?.name}
        size="120"
      />
      <div className="space-y-2">
        <h2 className="text-new-accent">{STR_MENTOR_SESSION}</h2>
        <p className="text-new-neutral-dark">
          By {session?.mentor_name} ·{" "}
          {session?.start_timestamp ? format(new Date(session?.start_timestamp * 1000), "do MMMM, hh:mm a") : ""}
        </p>
      </div>
      <div className="flex flex-1 flex-col items-end justify-between">
        <h3 className="text-new-failure">{timer}</h3>
        <a
          className={cn(minsLeft > 60 && "pointer-events-none")}
          href={session?.meet_link}
          target="_blank"
          rel="noreferrer"
        >
          <Button disabled={minsLeft > 30} className="w-fit">
            Join meet
          </Button>
        </a>
      </div>
    </div>
  )
}
