import { Button } from "core/components/v2/Button"
import useLocalStorage from "core/hooks/useLocalStorage"

const GeneralGuideline = (props: any) => {
  const [student] = useLocalStorage<any>("student", {} as any)
  const guidelines = [
    {
      id: 1,
      text: "Recommend any Bosscoder course to your friends.",
    },
    {
      id: 2,
      text: `Share the details of the referee by filling out the referral form provided below.`,
      // text: `Share the referral email provided below with your friend to receive a cashback of Rs ${student?.batch?.includes("AC2") ? 2000 : 3000}.`,
    },
    {
      id: 3,
      text: "Our team will connect with the referred individual to discuss enrollment in the respective course.",
    },
    {
      id: 4,
      text: "The Reward form will be accessible to you after the referred individual successfully completes their enrollment and observation period.",
    },
    {
      id: 5,
      text: "Share your bank details with us by filling out the Reward form.",
    },
    {
      id: 6,
      text: `The referral amount will be directly deposited into your provided bank account.`,
    },
    {
      id: 7,
      text: "Repeat the above steps to refer more friends and continue earning referral rewards indefinitely.",
    },
  ]
  const { myReferralCode, handleCopy } = props
  return (
    <div className="h-full w-full flex flex-col">
      <div className="flex-grow flex flex-col gap-y-4 p-6 min-h-0">
        <div className="text-lg text-[#1C3474] font-semibold">General Guidelines</div>
        {/* <div className="h-full flex flex-1 flex-col space-y-4 overflow-y-auto hide-scrollbar"> */}
        <div className="h-[456px] flex flex-col space-y-4 overflow-y-auto hide-scrollbar">
          {guidelines.map((guideline) => (
            <div key={guideline.id} className="text-sm space-y-2">
              <p className="font-semibold text-[#1C3474]">Step {guideline.id}</p>
              <p>{guideline.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-[#FEFFE4] flex flex-col items-center rounded-b-lg py-4 px-6">
        <div className="pb-4">
          <div className="text-xs pb-1 text-center">Your Referral Email</div>
          <div
            className="text-sm font-bold text-center"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {myReferralCode.length > 30 ? myReferralCode.substring(0, 30) + " " : myReferralCode}
          </div>
        </div>
        <Button className="bg-[#07B42D] px-4 py-2.25" onClick={() => handleCopy(myReferralCode, "Referral Email")}>
          Copy Email
        </Button>
      </div>
    </div>
  )
}

export default GeneralGuideline
