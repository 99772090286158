// import Input from "core/components/new/Input"
import Input from "core/components/Input"
import PhoneInput from "react-phone-number-input"

export default function NewReferralFormComponent({ fieldName, type, name, value, onChange }: any) {
  return (
    <div className="my-[8px]">
      <div className="flex flex-col justify-between gap-0 font-normal w-[301px] h-[52px] ">
        <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
          {fieldName}
          <span className="text-red-600">*</span>
        </div>
        {type == "phoneNumber" ? (
          <div className="w-full mt-1 rounded-[4px] bg-new-solid-white text-sm leading-[17px] outline-none ">
            <PhoneInput
              placeholder="Enter phone number"
              name={name}
              defaultCountry="IN"
              value={value}
              className="focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              onChange={onChange}
            />
          </div>
        ) : (
          <div className="h-[33px] mt-[1px] ">
            <Input
              placeholder="Type here"
              type={type}
              name={name}
              value={value}
              className="font-Inter text-sm rounded-[4px] h-[33px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              onChange={onChange}
            />
          </div>
        )}
      </div>
    </div>
  )
}
