import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { genError } from "core/utils/string"
import { SheetsAPIDataSourceImpl } from "data/API/Admin/SheetsAPIDataSourceImpl"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { SheetsRepositoryImpl } from "data/repository/Admin/SheetsRepositoryImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { GetBatchStudentDetails } from "domain/useCase/Admin/Sheets/GetBatchStudentDetails"
import { getAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import React from "react"
import { GetBatchYearData } from "domain/useCase/Admin/Sheets/GetBatchYearData"
import { GetMentorListData } from "domain/useCase/Admin/Sheets/GetMentorListData"
import { GetDoubtRaisedStats } from "domain/useCase/Admin/Sheets/GetDoubtRaisedStats"
import { UpdateBatchData } from "domain/useCase/Admin/Sheets/UpdateBatchData"

export default function SheetsViewModel() {
  const [allBatches, setAllBatches] = React.useState([])
  const [batch, setBatch] = React.useState("")
  const [fromDate, setFromDate] = React.useState("")
  const [toDate, setToDate] = React.useState("")
  const [fromDate2, setFromDate2] = React.useState("")
  const [toDate2, setToDate2] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false)
  const [isLoading2, setIsLoading2] = React.useState(false)
  const [isLoading3, setIsLoading3] = React.useState(false)
  const [isLoading4, setIsLoading4] = React.useState(false)
  const [month, setMonth] = React.useState<any>("")
  const [monthList, setMonthList] = React.useState<any>([])
  const [year, setYear] = React.useState<any>({})
  const [allBatches2, setAllBatches2] = React.useState([])
  const [batch2, setBatch2] = React.useState("")
  const [dataSetButton, setDataSetButton] = React.useState(false)
  const [blurSelect, setBlurSelect] = React.useState(false)
  const [dataButtonLoading, setDataButtonLoading] = React.useState(false)
  const [downloadData, setDownloadData] = React.useState(false)
  const [batch3, setBatch3] = React.useState("")

  const { auth, refreshed } = useAuth()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()

  const getAllBatchesUseCase = new getAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const getBatchStudentDetailsUseCase = new GetBatchStudentDetails(
    new SheetsRepositoryImpl(new SheetsAPIDataSourceImpl())
  )

  const getBatchYearDataUseCase = new GetBatchYearData(new SheetsRepositoryImpl(new SheetsAPIDataSourceImpl()))

  const getMentorListDataUseCase = new GetMentorListData(new SheetsRepositoryImpl(new SheetsAPIDataSourceImpl()))

  const getDoubtRaisedStatsUseCase = new GetDoubtRaisedStats(new SheetsRepositoryImpl(new SheetsAPIDataSourceImpl()))

  const updateBatchDataUseCase = new UpdateBatchData(new SheetsRepositoryImpl(new SheetsAPIDataSourceImpl()))

  const fetchAllBatches = async () => {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error fetching batches"))
      return
    }

    setAllBatches(response?.data)
  }
  const fetchAllBatches2 = async () => {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error fetching batches"))
      return
    }

    setAllBatches2(response?.data)
  }

  const handleFormSubmit = async (e: any) => {
    e.preventDefault()

    if (!batch || !fromDate || !toDate) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Please fill all the fields")
      return
    }

    const fromTimestamp = new Date(fromDate).getTime()
    const toTimestamp = new Date(toDate).getTime()
    const currTimestamp = Date.now()
    if (fromTimestamp > toTimestamp) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "From date cannot be greater than to date")
      return
    }
    if (fromTimestamp > currTimestamp || toTimestamp > currTimestamp) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Enter a valid date")
      return
    }

    try {
      setIsLoading(true)
      const response = await getBatchStudentDetailsUseCase.invoke({
        batch,
        fromDate,
        toDate,
      })

      const csvContent = `data:text/csv;charset=utf-8,${response}`

      const encodedUri = encodeURI(csvContent)
      const link = document.createElement("a")
      link.setAttribute("href", encodedUri)
      link.setAttribute("download", `${batch}.csv`)
      document.body.appendChild(link) // Required for FF

      link.click()
    } catch (error) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Error downloading file")
    }
    setIsLoading(false)
  }

  const handleFormSubmitDoubtSupport = async (e: any) => {
    e.preventDefault()
    if (!fromDate2 || !toDate2) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Please fill all the fields")
      return
    }

    const fromTimestamp = new Date(fromDate2).getTime()
    const toTimestamp = new Date(toDate2).getTime()

    if (fromTimestamp > toTimestamp) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "From date cannot be greater than to date")
      return
    }

    try {
      setIsLoading3(true)
      const response = await getDoubtRaisedStatsUseCase.invoke({
        fromDate2,
        toDate2,
      })

      const csvContent = `data:text/csv;charset=utf-8,${response}`

      const encodedUri = encodeURI(csvContent)
      const link = document.createElement("a")
      link.setAttribute("href", encodedUri)
      link.setAttribute("download", "DOUBTQUERIES.csv")
      document.body.appendChild(link) // Required for FF

      link.click()
    } catch (error) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Error downloading file")
    }
    setIsLoading3(false)
  }

  const handleFormSubmitMentorList = async (e: any) => {
    e.preventDefault()
    if (!batch3) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Please Fill the Batch")
      return
    }

    try {
      setIsLoading4(true)
      const response = await getMentorListDataUseCase.invoke(auth, batch3)

      const csvContent = `data:text/csv;charset=utf-8,${response}`

      const encodedUri = encodeURI(csvContent)
      const link = document.createElement("a")
      link.setAttribute("href", encodedUri)
      link.setAttribute("download", `${batch3}.csv`)
      document.body.appendChild(link) // Required for FF
      link.click()
    } catch (error) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Error downloading file")
    }
    setIsLoading4(false)
  }

  const handleFormSubmitMonthYear = async (e: any) => {
    e.preventDefault()
    if (downloadData == false) return
    setDownloadData(!downloadData)
    if (!batch2 || !monthList || !year) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Please fill all the fields")
      return
    }

    try {
      setIsLoading2(true)
      const response = await getBatchYearDataUseCase.invoke(auth, batch2, monthList, year)

      const csvContent = `data:text/csv;charset=utf-8,${response}`

      const encodedUri = encodeURI(csvContent)
      const link = document.createElement("a")
      link.setAttribute("href", encodedUri)
      link.setAttribute("download", `${batch}.csv`)
      document.body.appendChild(link) // Required for FF
      link.click()
    } catch (error) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Error downloading file")
    }
    setIsLoading2(false)
  }

  const handleUpdateBatchData = async () => {
    setDataButtonLoading(true)
    const response = await updateBatchDataUseCase.invoke(auth, batch2)
    if (response.success) {
      setDataButtonLoading(false)
      changeToastDetails(STR_SUCCESS, "Updated Batch Data")
      changeToastVisibility(true)
    } else {
      setDataButtonLoading(false)
      changeToastDetails(STR_FAILURE, response.error)
      changeToastVisibility(true)
    }

    return response.data
  }

  const handleBatchChange = (e: any) => {
    setBatch(e.target.value)
  }

  const handleBatchChange2 = (e: any) => {
    setBatch2(e.target.value)
  }
  const handleBatchChange3 = (e: any) => {
    setBatch3(e.target.value)
  }
  const handleFromDateChange = (e: any) => {
    setFromDate(e.target.value)
  }

  const handleToDateChange = (e: any) => {
    setToDate(e.target.value)
  }

  const handleFromDateChange2 = (e: any) => {
    setFromDate2(e.target.value)
  }

  const handleToDateChange2 = (e: any) => {
    setToDate2(e.target.value)
  }

  const handleMonthChange = (e: any) => {
    setMonth(e.target.value)
  }

  const handleAddMonth = () => {
    if (month === "") return
    if (monthList.includes(month)) return

    setMonthList([...monthList, month])
    setMonth("")
  }

  const handleRemoveMonth = (index: number) => {
    let newMonthList = monthList
    newMonthList = newMonthList.filter((m: string, i: number) => i !== index)
    setMonthList(newMonthList)
  }

  const handleYearChange = (e: any) => {
    setYear(e.target.value)
  }

  function submitForm() {
    setDownloadData(!downloadData)
  }

  async function setBatchData() {
    // setDataSetButton(!dataSetButton);
    setBlurSelect(true)
    await handleUpdateBatchData()
    setBlurSelect(false)
  }

  return {
    refreshed,
    toast,
    allBatches,
    batch,
    fromDate,
    toDate,
    fromDate2,
    toDate2,
    isLoading,
    isLoading2,
    year,
    month,
    monthList,
    batch2,
    allBatches2,
    isLoading3,
    isLoading4,
    dataSetButton,
    blurSelect,
    dataButtonLoading,
    batch3,
    handleBatchChange3,
    submitForm,
    handleUpdateBatchData,
    setBatchData,
    handleFormSubmitDoubtSupport,
    handleFromDateChange2,
    handleToDateChange2,
    handleBatchChange2,
    handleFormSubmitMonthYear,
    handleRemoveMonth,
    handleAddMonth,
    handleMonthChange,
    handleYearChange,
    handleFromDateChange,
    handleToDateChange,
    handleFormSubmit,
    handleBatchChange,
    handleFormSubmitMentorList,
    fetchAllBatches2,
    fetchAllBatches,
    changeToastDetails,
    changeToastVisibility,
  }
}
