import { useEffect, useState } from "react"
import MentorProfileAPIDataSourceImpl from "data/API/Mentor/ProfileAPIDataSourceImpl"
import FetchMentorProfile from "domain/useCase/Mentor/Profile/FetchMentorProfile"
import { useAuth } from "core/context/auth"
import { useApp } from "core/context/app"
import { GetProfileDetails } from "domain/useCase/Student/Profile/GetProfileDetails"
import { ProfileRepositoryImpl } from "data/repository/Student/ProfileRepositoryImpl"
import { ProfileRepositoryImpl as MentorProfileRepositoryImpl } from "data/repository/Mentor/ProfileRepositoryImpl"
import { ProfileAPIDataSourceImpl } from "data/API/Student/ProfileAPIDataSourceImpl"
import RouteSwitcher from "core/routes/RouteSwitcher"
import useMobileWarning from "core/hooks/useMobileWarning"
import { CheckMonthlyFeedback } from "domain/useCase/Student/MonthlyFeedback/CheckMonthlyFeedback"
import { MonthlyFeedbackRepositoryImpl } from "data/repository/Student/MonthlyFeedbackRepositoryImpl"
import MonthlyFeedbackAPIDataSourceImpl from "data/API/Student/MonthlyFeedbackAPIDataSourceImpl"
import { PlacementScore } from "domain/useCase/Student/Profile/PlacementScore"
import FeedbackModal from "core/components/FeedbackModal"
import BannerCardModel from "presentation/Student/Payment/component/BannerCardModel"

function App() {
  const { auth } = useAuth()
  const { student, setStudent, setMentor, fetchNotificationToast, fetchRightPanelDetails, fetchNotificationDetails } =
    useApp()
  const { isMobile } = useMobileWarning()

  const [feedbackResponse, setFeedbackResponse] = useState<boolean>(false)
  const [model, setModel] = useState<boolean>(false)
  const [monthlyFeedbackStatus, setMonthlyFeedbackStatus] = useState<boolean>(false)

  const checkMonthlyFeedbackUseCase = new CheckMonthlyFeedback(
    new MonthlyFeedbackRepositoryImpl(new MonthlyFeedbackAPIDataSourceImpl())
  )
  const placementReadinessScoreUseCase = new PlacementScore(new ProfileRepositoryImpl(new ProfileAPIDataSourceImpl()))
  const getProfileDetailsUseCase = new GetProfileDetails(new ProfileRepositoryImpl(new ProfileAPIDataSourceImpl()))
  const fetchMentorProfileUseCase = new FetchMentorProfile(
    new MentorProfileRepositoryImpl(new MentorProfileAPIDataSourceImpl())
  )

  const fetchUserDetails = async (role: string) => {
    if (role === "user") {
      const response = await getProfileDetailsUseCase.invoke(auth)
      setStudent(response?.data)
    } else if (role === "mentor") {
      const response = await fetchMentorProfileUseCase.invoke(auth)
      setMentor(response?.data?.response)
    }
  }

  async function fetchPlacementScore() {
    const response = await placementReadinessScoreUseCase.invoke(auth)
    if (!response?.success) return
  }

  const fetchMonthlyFeedbackStatus = async () => {
    const response = await checkMonthlyFeedbackUseCase.invoke(auth)
    if (!response?.success) return
    setMonthlyFeedbackStatus(response?.data)
  }

  useEffect(() => {
    if (auth && "role" in auth) {
      if (auth.role === "user") {
        fetchMonthlyFeedbackStatus()
        fetchPlacementScore()
      }
      fetchUserDetails(auth.role)
    }
  }, [auth, feedbackResponse])

  useEffect(() => {
    if (auth && "role" in auth && auth.role === "user") {
      fetchNotificationToast(auth)
      fetchRightPanelDetails(auth)
      fetchNotificationDetails(auth)
    }
  }, [auth])

  useEffect(() => {
    if (!student?.is_payment_done && student?.walk_through_date) {
      const walk_through_date = student?.walk_through_date
      const walk_through_date_in_millis = walk_through_date * 1000
      const current_date = new Date()
      const future_date = walk_through_date_in_millis + 66.5 * 60 * 60 * 1000
      if (current_date.getTime() <= future_date) {
        setTimeout(() => {
          setModel(true)
        }, 2000)
      }
    }
  }, [student?.email])

  if (isMobile) {
    return (
      <div className="flex h-screen">
        <div className="m-auto px-2 text-center font-medium">Please open it on desktop for better experience.</div>
      </div>
    )
  }

  return (
    <>
      <FeedbackModal status={monthlyFeedbackStatus} onSubmit={setFeedbackResponse} />
      {!student?.is_payment_done && student?.walk_through_date && (
        <BannerCardModel isOpen={model} onClose={() => setModel(false)} />
      )}
      <RouteSwitcher />
    </>
  )
}

export default App
