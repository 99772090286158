import { STR_FAILURE, STR_SUCCESS, STR_UNKNOWN_ERROR_OCCURRED } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { isEmpty } from "core/utils/misc"
import { genError } from "core/utils/string"
import AvailabilityAPIDataSourceImpl from "data/API/Mentor/AvailabilityAPIDataSourceImpl"
import { AvailabilityRepositoryImpl } from "data/repository/Mentor/AvailabilityRepositoryImpl"
import { GetAvailability } from "domain/useCase/Mentor/Availability/GetAvailability"
import { UpdateAvailability } from "domain/useCase/Mentor/Availability/UpdateAvailability"
import { BlockDates } from "domain/useCase/Mentor/Availability/BlockDates"
import React from "react"
import { UpdateGoalSettingAvailability } from "domain/useCase/Mentor/Availability/UpdateGoalSettingAvailability"
import { GetGoalSettingAvailability } from "domain/useCase/Mentor/Availability/GetGoalSettingAvailability"
import { BlockGoalSettingDates } from "domain/useCase/Mentor/Availability/BlockGoalSettingDates"

export default function GoalSettingAvailabilityViewModel() {
  const OPTION_HOUR24_LIST = [...Array(48)].map((_, i) => {
    const value = i * 0.5
    const hours = Math.floor(value)
    const minutes = value % 1 === 0.5 ? "30" : "00"

    return {
      label: `${hours}:${minutes}`,
      value: value,
    }
  })

  console.log(OPTION_HOUR24_LIST, "jj")
  const [optionHour24List, setOptionHour24List] = React.useState<any>({
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
  })
  const { auth, refreshed } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [availability, setAvailability] = React.useState<any>(null)
  const [sunday, setSunday] = React.useState<any>([])
  const [monday, setMonday] = React.useState<any>([])
  const [tuesday, setTuesday] = React.useState<any>([])
  const [wednesday, setWednesday] = React.useState<any>([])
  const [thursday, setThursday] = React.useState<any>([])
  const [friday, setFriday] = React.useState<any>([])
  const [saturday, setSaturday] = React.useState<any>([])
  const [updateLoading, setUpdateLoading] = React.useState<any>(false)
  const [BDLoading, setBDLoading] = React.useState<any>(false)
  const [BDList, setBDList] = React.useState<any>([])
  const [date, setDate] = React.useState<any>([])
  const [normalAvailability, setNormalAvailability] = React.useState<any>([])
  const initialWeekMap = {
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    sunday: 0,
  }
  const [newStart, setNewStart] = React.useState<any>(initialWeekMap)

  const [newEnd, setNewEnd] = React.useState<any>(initialWeekMap)

  const dayFunctions: any = {
    monday: setMonday,
    tuesday: setTuesday,
    wednesday: setWednesday,
    thursday: setThursday,
    friday: setFriday,
    saturday: setSaturday,
    sunday: setSunday,
  }

  const dayValues: any = {
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
  }

  const getAvailabilityUseCase = new GetGoalSettingAvailability(
    new AvailabilityRepositoryImpl(new AvailabilityAPIDataSourceImpl())
  )

  const getNormalAvailabilityUseCase = new GetAvailability(
    new AvailabilityRepositoryImpl(new AvailabilityAPIDataSourceImpl())
  )

  const updateAvailabilityUseCase = new UpdateGoalSettingAvailability(
    new AvailabilityRepositoryImpl(new AvailabilityAPIDataSourceImpl())
  )

  const blockDatesUseCase = new BlockDates(new AvailabilityRepositoryImpl(new AvailabilityAPIDataSourceImpl()))
  const blockGoalSettingDatesUseCase = new BlockGoalSettingDates(
    new AvailabilityRepositoryImpl(new AvailabilityAPIDataSourceImpl())
  )

  const fetchAvailability = async () => {
    setIsLoading(true)

    const response = await getAvailabilityUseCase.invoke(auth)

    setIsLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }
    console.log(response?.data?.date_obj, "hh")
    const availability = response?.data?.date_obj
    setAvailability(response?.data?.date_obj)
    console.log(availability, "hh", response?.data?.date_obj)

    Object.keys(dayFunctions).forEach(
      (dayKey) => !isEmpty(availability[dayKey]) && dayFunctions[dayKey](availability[dayKey])
    )
    console.log("BLOCKED DATES HAIN", availability)
    setBDList(availability?.mentor_blocked_dates || availability?.blocked_dates)
  }

  const fetchNormalAvailability = async () => {
    setIsLoading(true)

    const response = await getNormalAvailabilityUseCase.invoke(auth)

    setIsLoading(false)
    setNormalAvailability(response?.availability)
    console.log("ye hai normal availability", response, normalAvailability)
  }

  const handleNewSlotChange = (e: any, dayKey: string) => {
    const { value } = e.target
    const newStartCopy = { ...newStart }
    const newEndCopy = { ...newEnd }

    // Parse the value as a float to handle half-hour increments
    const parsedValue = parseFloat(value)

    // Set the new start value
    newStartCopy[dayKey] = value

    // Calculate the new end value with half-hour increments and ensure it wraps correctly
    const newEndValue = (parsedValue + 0.5) % 24
    newEndCopy[dayKey] = newEndValue.toString()

    // Update state
    setNewStart(newStartCopy)
    setNewEnd(newEndCopy)
  }

  interface Slot {
    start: number
    end: number
  }
  const formatTime = (value: number): string => {
    const hours = Math.floor(value) // Get the integer part (hours)
    const minutes = value % 1 === 0.5 ? "30" : "00" // Check if the value has a .5 for 30 minutes
    return `${hours}:${minutes}` // Return formatted time string
  }
  const handleSlotChange = (e: React.ChangeEvent<HTMLSelectElement>, dayKey: string, index: number) => {
    const start = parseFloat(e.target.value)

    // Calculate the end value with half-hour increments and ensure it wraps correctly
    const end = ((start + 0.5) % 24).toString()

    // Create the updated slot object
    const updatedSlot: Slot = { start, end: parseFloat(end) }

    // Get current slots for the day
    const currentSlots = dayValues[dayKey] as Slot[]

    // Update the slot at the specified index
    const updatedSlots = currentSlots.map((slot, i) => (i === index ? updatedSlot : slot))

    // Get the function for updating the specific day
    const setDay = dayFunctions[dayKey]

    // Check if the updated slot overlaps with existing slots in the same day's availability
    const availabilitySlots = availability[dayKey] || []
    const normalAvailabilitySlots = normalAvailability[dayKey] || []

    const isOverlappingWithAvailability = availabilitySlots.some((slot: Slot) => isOverlapping(updatedSlot, slot))
    const skipNormalAvailabilityCheck = !normalAvailabilitySlots || Object.keys(normalAvailabilitySlots).length === 0

    const isOverlappingWithNormalAvailability =
      !skipNormalAvailabilityCheck && normalAvailabilitySlots.some((slot: Slot) => isOverlapping(updatedSlot, slot))

    if (isOverlappingWithAvailability || isOverlappingWithNormalAvailability) {
      changeToastDetails(STR_FAILURE, "The particular slot is being overlapped with mentor session availability.")
      changeToastVisibility(true)
      console.warn("The updated slot overlaps with existing availability or normal availability on the same day")
      return
    }

    // If no overlap, update the slot
    setDay(updatedSlots)
  }

  // Utility function to check if two slots overlap
  const isOverlapping = (slot1: { start: number; end: number }, slot2: { start: number; end: number }): boolean => {
    return slot1.start < slot2.end && slot1.end > slot2.start
  }

  // List of valid days (adjust this according to your use case)
  const validDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]

  const handleAddSlot = (dayKey: string) => {
    // Check if the dayKey is a valid day
    if (!validDays.includes(dayKey.toLowerCase())) {
      console.warn("Invalid day selected")
      return
    }

    const setDay = dayFunctions[dayKey]
    const day = dayValues[dayKey]
    const start = newStart[dayKey]
    const end = newEnd[dayKey]

    // Create the new slot
    const newSlot = { start, end }

    // Reset start and end input values
    const newStartCopy = { ...newStart }
    const newEndCopy = { ...newEnd }
    newStartCopy[dayKey] = 0
    newEndCopy[dayKey] = 0
    setNewStart(newStartCopy)
    setNewEnd(newEndCopy)

    // Check if the new slot already exists in the current day
    const slotExists = day.some((slot: any) => slot.start === start && slot.end === end)
    if (slotExists) {
      console.log("ovveer")

      return
    }

    // Check if the new slot overlaps with existing slots in the same day's availability
    const availabilitySlots = availability[dayKey] || [] // Ensuring availabilitySlots is an array even if undefined
    const normalAvailabilitySlots = normalAvailability[dayKey] || [] // Same here

    const isOverlappingWithAvailability = availabilitySlots.some((slot: any) => isOverlapping(newSlot, slot))
    const skipNormalAvailabilityCheck = !normalAvailabilitySlots || Object.keys(normalAvailabilitySlots).length === 0

    const isOverlappingWithNormalAvailability =
      !skipNormalAvailabilityCheck && normalAvailabilitySlots.some((slot: any) => isOverlapping(newSlot, slot))

    if (isOverlappingWithAvailability || isOverlappingWithNormalAvailability) {
      changeToastDetails(STR_FAILURE, "The particular slot is being overlapped with mentor session availability.")
      changeToastVisibility(true)
      console.warn("The new slot overlaps with existing availability or normal availability on the same day")
      return
    }

    // If no overlap, add the new slot
    setDay([...day, newSlot])
  }

  const handleRemoveSlot = (dayKey: string, index: number) => {
    const setDay = dayFunctions[dayKey]
    const day = dayValues[dayKey]
    const newDay = day?.filter((s: any, i: number) => i !== index)
    setDay(newDay)
  }

  const handleToggleDay = (e: React.ChangeEvent<HTMLInputElement>, dayKey: string) => {
    const setDay = dayFunctions[dayKey]
    const currentSlots = dayValues[dayKey] as Slot[]
    const availabilitySlots = availability[dayKey] || [] // Default to empty array if undefined
    const normalAvailabilitySlots = normalAvailability[dayKey] || [] // Default to empty array if undefined

    if (e.target.checked) {
      // Determine the next available slot
      let nextStart = 0 // Starting from 0 (midnight) as an example
      let nextEnd = nextStart + 0.5
      const skipNormalAvailabilityCheck = !normalAvailabilitySlots || Object.keys(normalAvailabilitySlots).length === 0
      // Find the next available slot not overlapping with existing slots
      while (
        currentSlots?.some((slot) => isOverlapping({ start: nextStart, end: nextEnd }, slot)) ||
        availabilitySlots?.some((slot: any) => isOverlapping({ start: nextStart, end: nextEnd }, slot)) ||
        (!skipNormalAvailabilityCheck &&
          normalAvailabilitySlots?.some((slot: any) => isOverlapping({ start: nextStart, end: nextEnd }, slot)))
      ) {
        nextStart += 0.5
        nextEnd = nextStart + 0.5

        // Handle wrap-around (not exceeding 24 hours)
        if (nextStart >= 24) {
          changeToastDetails(STR_FAILURE, "No available slot for the selected day")
          changeToastVisibility(true)
          return
        }
      }

      const newSlot: Slot = { start: nextStart, end: nextEnd }

      // Set the day with the new slot
      setDay([newSlot])
    } else {
      // If unchecked, clear the slots for the day
      setDay([])
    }
  }

  const updateAvailabilitySlots = async () => {
    setUpdateLoading(true)

    const response = await updateAvailabilityUseCase.invoke(auth, dayValues)

    setUpdateLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, STR_UNKNOWN_ERROR_OCCURRED)
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Updated successfully")
    changeToastVisibility(true)
  }

  const submitBlockDates = async (e: any) => {
    e.preventDefault()
    setBDLoading(true)
    console.log("ye hain blocked dates", BDList)
    const response = await blockDatesUseCase.invoke(auth, BDList)

    setBDLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, STR_UNKNOWN_ERROR_OCCURRED)
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Dates blocked successfully")
    changeToastVisibility(true)
  }

  const submitGoalSettingBlockDates = async (e: any) => {
    e.preventDefault()
    setBDLoading(true)
    console.log("ye hain blocked dates", BDList)
    const response = await blockGoalSettingDatesUseCase.invoke(auth, BDList)

    setBDLoading(false)
    console.log("ye hai block", response)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, STR_UNKNOWN_ERROR_OCCURRED)
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Dates blocked successfully")
    changeToastVisibility(true)
  }

  const handleDateChange = (e: any) => {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    const dateString = e.target.value
    const dateParts = dateString.split("-") // Split the date string by "-"
    const year = parseInt(dateParts[0]) // Extract the year and parse it to an integer
    const month = parseInt(dateParts[1]) - 1 // Extract the month and parse it to an integer
    const day = parseInt(dateParts[2]) // Extract the day and parse it to an integer
    const selectedDate = new Date(year, month, day) // Create a Date object from the parsed parts
    if (selectedDate > yesterday) {
      console.log(">>>>", e.target.value, typeof e.target.value)
      setDate(e.target.value)
      console.log("BDLIST", e.target.value, BDList)

      if (!BDList.includes(e.target.value)) setBDList([...BDList, e.target.value])
    }
  }

  console.log("idhar hai bdlist", BDList)
  const handleRemoveDate = (index: number) => {
    setBDList(BDList.filter((_: any, i: number) => i !== index))
  }

  function getCurrentDate() {
    const today_curr = new Date()
    const year_curr = today_curr.getFullYear().toString()
    let month_curr = (today_curr.getMonth() + 1).toString()
    let day_curr = today_curr.getDate().toString()

    // Add leading zero if month or day is less than 10
    if (month_curr < "10") {
      month_curr = "0" + month_curr
    }
    if (day_curr < "10") {
      day_curr = "0" + day_curr
    }

    return `${year_curr}-${month_curr}-${day_curr}`
  }

  return {
    toast,
    refreshed,
    availability,
    isLoading,
    dayValues,
    dayFunctions,
    updateLoading,
    BDLoading,
    BDList,
    date,
    OPTION_HOUR24_LIST,
    newStart,
    newEnd,
    normalAvailability,
    isOverlapping,
    getCurrentDate,
    changeToastVisibility,
    fetchAvailability,
    handleSlotChange,
    handleNewSlotChange,
    handleAddSlot,
    handleRemoveSlot,
    handleToggleDay,
    updateAvailabilitySlots,
    submitBlockDates,
    handleDateChange,
    handleRemoveDate,
    submitGoalSettingBlockDates,
    fetchNormalAvailability,
    formatTime,
  }
}
