import React from "react"

export default function Feedback1(props: any) {
  const { selectedColor } = props

  return (
    <svg
      className={props.className}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
    >
      <circle cx="32" cy="32" r="32" fill={selectedColor} />
      <path
        d="M25.9368 29.9345C25.5807 30.7641 24.9096 31.4184 24.0711 31.7532C23.2327 32.0881 22.2955 32.0761 21.4659 31.72C20.6362 31.3639 19.982 30.6928 19.6471 29.8544C19.3122 29.0159 19.3242 28.0787 19.6803 27.2491L22.8086 28.5918L25.9368 29.9345Z"
        fill="white"
      />
      <path
        d="M44.1465 27.7357C44.3735 28.6096 44.2441 29.5378 43.7868 30.3163C43.3294 31.0947 42.5815 31.6596 41.7077 31.8866C40.8338 32.1137 39.9056 31.9843 39.1271 31.5269C38.3487 31.0695 37.7838 30.3217 37.5568 29.4478L40.8516 28.5918L44.1465 27.7357Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.644 46.2979C44.3313 46.9818 43.5234 47.2828 42.8394 46.9701L38.7124 45.0835C34.1559 43.0005 28.9044 43.0759 24.4095 45.2888L21.0283 46.9534C20.3535 47.2856 19.5373 47.0079 19.2051 46.3332C18.873 45.6584 19.1507 44.8422 19.8254 44.51L23.2067 42.8454C28.4354 40.2713 34.5443 40.1836 39.8447 42.6067L43.9717 44.4933C44.6557 44.806 44.9566 45.6139 44.644 46.2979Z"
        fill="white"
      />
    </svg>
  )
}
