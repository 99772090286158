import Feedback1 from "assets/svgs/feedback/Feedback1"
import Feedback2 from "assets/svgs/feedback/Feedback2"
import Feedback3 from "assets/svgs/feedback/Feedback3"
import Feedback4 from "assets/svgs/feedback/Feedback4"
import Feedback5 from "assets/svgs/feedback/Feedback5"
import Feedback6 from "assets/svgs/feedback/Feedback6"
import Feedback7 from "assets/svgs/feedback/Feedback7"
import Feedback8 from "assets/svgs/feedback/Feedback8"
import Feedback9 from "assets/svgs/feedback/Feedback9"
import Feedback10 from "assets/svgs/feedback/Feedback10"
import Toast from "core/components/Toast"
import Button from "core/components/new/Button"
import { STR_FAILURE, STR_SUBMIT } from "core/constants/strings"
import useToast from "core/hooks/useToast"
import React from "react"
import MonthlyFeedbackAPIDataSourceImpl from "data/API/Student/MonthlyFeedbackAPIDataSourceImpl"
import { SubmitMonthlyFeedback } from "domain/useCase/Student/MonthlyFeedback/SubmitMonthlyFeedback"
import { MonthlyFeedbackRepositoryImpl } from "data/repository/Student/MonthlyFeedbackRepositoryImpl"
import { useAuth } from "core/context/auth"
import { CrossIcon } from "core/constants/svgs"

export default function FeedbackModal({ status, onSubmit }: any) {
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const { auth } = useAuth()

  const [formVisible, setFormVisible] = React.useState(false)
  const [selectedEmojiIndex, setSelectedEmojiIndex] = React.useState<number | null>(null)
  const [response1, setResponse1] = React.useState("")
  const [response2, setResponse2] = React.useState("")
  const [loading, setLoading] = React.useState(false)

  const [secretClose, setSecretClose] = React.useState<boolean>(false)

  const submitMonthlyFeedbackUseCase = new SubmitMonthlyFeedback(
    new MonthlyFeedbackRepositoryImpl(new MonthlyFeedbackAPIDataSourceImpl())
  )

  async function handleSubmitFeedback(e: any) {
    e.preventDefault()

    if (response1.length === 0 || response2.length === 0) {
      changeToastDetails(STR_FAILURE, "Please write The Feedback to Improve Us")
      changeToastVisibility(true)
      return
    }

    const data = {
      1: selectedEmojiIndex,
      2: response1,
      3: response2,
    }

    setLoading(true)

    const response = await submitMonthlyFeedbackUseCase.invoke(auth, data)

    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Please the submit monthly feedback")
      changeToastVisibility(true)
      return
    }

    onSubmit(response?.data)
  }

  const handleResponse1Change = (e: any) => {
    setResponse1(e.target.value)
  }

  const handleResponse2Change = (e: any) => {
    setResponse2(e.target.value)
  }

  const handleClick = (i: number) => {
    setSelectedEmojiIndex(i)
  }

  const handleNextClick = () => {
    if (!selectedEmojiIndex) {
      changeToastDetails(STR_FAILURE, "Please select the emotion")
      changeToastVisibility(true)
      return
    }

    setFormVisible(true)
  }

  const handlePrevClick = () => {
    setFormVisible(false)
  }

  const emojis = [
    <Feedback1 selectedColor={selectedEmojiIndex === 0 ? "#E02626" : "#ADADAD"} />,
    <Feedback2 selectedColor={selectedEmojiIndex === 1 ? "#E05326" : "#ADADAD"} />,
    <Feedback3 selectedColor={selectedEmojiIndex === 2 ? "#E08026" : "#ADADAD"} />,
    <Feedback4 selectedColor={selectedEmojiIndex === 3 ? "#E09626" : "#ADADAD"} />,
    <Feedback5 selectedColor={selectedEmojiIndex === 4 ? "#E0CE26" : "#ADADAD"} />,
    <Feedback6 selectedColor={selectedEmojiIndex === 5 ? "#DCE026" : "#ADADAD"} />,
    <Feedback7 selectedColor={selectedEmojiIndex === 6 ? "#B0E026" : "#ADADAD"} />,
    <Feedback8 selectedColor={selectedEmojiIndex === 7 ? "#8EE026" : "#ADADAD"} />,
    <Feedback9 selectedColor={selectedEmojiIndex === 8 ? "#62E026" : "#ADADAD"} />,
    <Feedback10 selectedColor={selectedEmojiIndex === 9 ? "#0DD746" : "#ADADAD"} />,
  ]

  const handleSecretCloseModal = () => {
    setSecretClose(true)
  }

  if (!status || secretClose) return null

  return (
    <div className="fixed inset-0 z-50">
      <div className="flex min-h-screen items-center justify-center">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
        </div>
        <div className="transform relative bg-new-solid-white">
          <button
            onClick={handleSecretCloseModal}
            className="cursor-default opacity-0 absolute bottom-0 text-new-solid-white left-0"
          >
            <CrossIcon className="w-4 h-4" />
          </button>
          {!formVisible ? (
            <div className="rounded-lg shadow">
              <div className="p-8">
                <h1 className="text-2xl font-bold leading-[29px] text-new-accent">Monthly Feedback</h1>
                <p className="font-medium">Select the emotion based on your experience</p>
              </div>
              <div className="flex flex-col pb-8 font-medium text-new-neutral-dark">
                <div className="flex flex-col gap-8 border-y px-8 py-6">
                  <p className="h-22px w-711px after-star text-lg">
                    How likely is that you will recommend Bosscoder Academy to your friends?
                  </p>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-8">
                      <div className="flex gap-4">
                        {emojis.map((emoji, i) => {
                          return (
                            <button onClick={() => handleClick(i)} className="flex flex-col items-center gap-4" key={i}>
                              <span>{emoji}</span>
                              <span>{i + 1}</span>
                            </button>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="flex justify-end px-8">
                  <Button small className="w-1/4" onClick={handleNextClick}>
                    Next
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col content-start p-8 ">
              <h2 className="font-sans text-2xl font-bold leading-normal text-[#312E81]">Monthly FeedBack</h2>
              <p className="font-sans text-sm font-normal leading-5 text-[#6B6B6B] ">
                Select the emotion based on your experience
              </p>
              <div className="mt-8 flex w-full">
                <div className="w-[50%] pr-4">
                  <div className="after-star mb-2 font-semibold">What do you like about the program? </div>
                  <div className="text-primary-color-neutral text-xs">
                    <textarea
                      className="flex h-[136px] w-[447px] flex-shrink-0 resize-none content-start gap-0 rounded-sm bg-white p-4	"
                      placeholder="Type your response here!"
                      onChange={handleResponse1Change}
                      value={response1}
                      required
                    />
                  </div>
                </div>
                <div className="w-[50%] pl-4">
                  <div className="after-star mb-2 font-semibold">What can be improved? </div>
                  <div className="text-primary-color-neutral text-xs">
                    <textarea
                      className="flex h-[136px] w-[447px] flex-shrink-0 resize-none content-start gap-0 rounded-sm bg-white p-4	"
                      placeholder="Type your response here!"
                      onChange={handleResponse2Change}
                      value={response2}
                      required
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="flex justify-end gap-4 px-8">
                <Button outlined failure className="w-1/4" onClick={handlePrevClick}>
                  Back
                </Button>
                <Button small className="w-1/4" onClick={handleSubmitFeedback} loading={loading}>
                  {STR_SUBMIT}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
