import { MyMenteeDataSource } from "data/dataSource/Mentor/MyMenteeDataSource"
import { Auth } from "domain/model/Auth"
import { getMenteeDetail, mentorFeedback } from "domain/model/MyMentee"
import { MyMenteeRepository } from "domain/repository/Mentor/MyMenteesRepository"

export class MyMenteeRepositoryImpl implements MyMenteeRepository {
  private dataSource: MyMenteeDataSource

  constructor(dataSource: MyMenteeDataSource) {
    this.dataSource = dataSource
  }

  async getMentorMentee(auth: Auth): Promise<any> {
    return this.dataSource.getMentorMentee(auth)
  }

  async getMenteeMentorSessions(details: getMenteeDetail): Promise<any> {
    return this.dataSource.getMenteeMentorSessions(details)
  }

  async addMentorFeedback(feedback: mentorFeedback): Promise<any> {
    return this.dataSource.addMentorFeedback(feedback)
  }

  async getMentorSessionsByStudentMentor(auth: Auth, data: any): Promise<any> {
    return this.dataSource.getMentorSessionsByStudentMentor(auth, data)
  }
  async addMentorFeedbackNew(auth: Auth, feedback: any, rating: any, action_items: any, id: any): Promise<any> {
    return this.dataSource.addMentorFeedbackNew(auth, rating, feedback, action_items, id)
  }
  async markMenteeAbsent(auth: Auth, data: any): Promise<any> {
    return this.dataSource.markMenteeAbsent(auth, data)
  }
  async cancelMentorSessionByMentor(auth: Auth, session_id: any, type: any, student_id: any): Promise<any> {
    return this.dataSource.cancelMentorSessionByMentor(auth, session_id, type, student_id)
  }
  async getMentorSessionDetails(auth: Auth, data: any): Promise<any> {
    return this.dataSource.getMentorSessionDetails(auth, data)
  }
  async allowExtraMentorSession(auth: Auth, email: string): Promise<any> {
    return this.dataSource.allowExtraMentorSession(auth, email)
  }
  async cancelMentorSessionByMentorOld(auth: Auth, date: any, start: any, end: any): Promise<any> {
    return this.dataSource.cancelMentorSessionByMentorOld(auth, date, start, end)
  }
}
