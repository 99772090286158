import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "react-phone-number-input/style.css"
import { BrowserRouter } from "react-router-dom"
import { AuthProvider } from "core/context/auth"
import { AppProvider } from "core/context/app/AppContext"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AuthProvider>
      <AppProvider>
        <App />
      </AppProvider>
    </AuthProvider>
  </BrowserRouter>
  // </React.StrictMode>
)
