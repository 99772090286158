import React from "react"
import { differenceInMinutes, format } from "date-fns"
import { MentorGlobeIllustration } from "core/constants/svgs"
import Button from "core/components/new/Button"
export default function MentorshipCard({
  index,
  session,
  mentor,
  onBooking,
  mentorSessionDetails,
  setBookingDetails,
  setConfirmSessionIndex,
}: any) {
  const [isBookingLoading, setIsBookingLoading] = React.useState(false)
  const startTimestamp = new Date(session?.start_timestamp)
  const endTimestamp = new Date(session?.end_timestamp)
  if (mentorSessionDetails?.status === "booked") {
  }
  const durationInMinutes = differenceInMinutes(endTimestamp, startTimestamp)
  return (
    <div className="flex items-center gap-2 rounded-sm text-new-accent">
      <div className="h-[85px] w-1.5 bg-new-solid-blue-dark" />
      <div className="flex h-[115px] w-[359px] rounded-sm bg-new-solid-blue-light">
        <div className="stroke-1.5 flex h-full w-[86px] items-center justify-center rounded border border-new-solid-blue-dark bg-new-solid-white px-4">
          <MentorGlobeIllustration />
        </div>
        <div className="flex w-full flex-col justify-between p-4 ">
          <div className="flex items-start justify-center gap-[47px]">
            <h4>Mentorship Session</h4>
            <div className="flex items-center gap-2 rounded-sm bg-new-solid-white px-2 py-1 shadow-[2px_2px_#398DB8]">
              <h4>{format(new Date(session?.start_timestamp), "h:mm")}</h4>
              <p className="text-xs leading-[19px]">{format(new Date(session?.start_timestamp), "a")}</p>
            </div>
          </div>
          <div className="inline-flex items-end gap-8">
            <div className="w-[82px]">
              <h5 className="h-3 w-[82px]">{durationInMinutes} Min</h5>
              <h5 className="h-3 w-[82px]">{mentor?.name || mentor}</h5>
            </div>
            <Button
              outlined
              className="h-[33px] w-[127px] whitespace-nowrap rounded-sm bg-transparent px-4 py-2"
              onClick={() => {
                setBookingDetails(session)
                setConfirmSessionIndex(index)
              }}
              disabled={isBookingLoading || mentorSessionDetails?.status === "booked"}
              loading={isBookingLoading}
            >
              Book Session
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
