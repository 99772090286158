export default function ChevronLeft({ className }: any) {
  return (
    <svg className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4372 16L21.0372 22.6L19.1518 24.4853L10.6665 16L19.1518 7.51465L21.0372 9.39998L14.4372 16Z"
        fill="currentColor"
      />
    </svg>
  )
}
