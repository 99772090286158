import { STR_ACCEPTED, STR_FAILURE } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { CompanyAPIDataSourceImpl } from "data/API/Admin/CompanyAPIDataSourceImpl"
import AdminDocDataSourceImpl from "data/API/Admin/DocDataSourceImpl"
import { CompanyRepositoryImpl } from "data/repository/Admin/CompanyRepositoryImpl"
import { DocRepositoryImpl } from "data/repository/Admin/DocRepositoyImpl"
import { Company, EditCompany } from "domain/model/Questions"
import { CreateCompany } from "domain/useCase/Admin/Company/CreateCompany"
import { DeleteCompany } from "domain/useCase/Admin/Company/DeleteCompany"
import { GetCompany } from "domain/useCase/Admin/Company/GetCompany"
import { UpdateCompany } from "domain/useCase/Admin/Company/UpdateCompany"
import { UploadImage } from "domain/useCase/Admin/Document/UploadImage"
import React, { ChangeEvent } from "react"

export default function CompanyViewModel() {
  const { auth } = useAuth()
  const [name, setName] = React.useState<string>("")
  const [imgSrc, setImgSrc] = React.useState<string>("")
  const [editName, setEditName] = React.useState<string>("")
  const [editImgSrc, setEditImgSrc] = React.useState<string>("")
  const [openEditModal, setOpenEditModal] = React.useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false)
  const [createLoader, setCreateLoader] = React.useState<boolean>(false)
  const [editLoader, setEditLoader] = React.useState<boolean>(false)
  const [deleteLoader, setDeleteLoader] = React.useState<boolean>(false)
  const [id, setId] = React.useState<string>("")
  const [companyFilter, setCompanyFilter] = React.useState<string>("")
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const [companyLogo, setCompanyLogo] = React.useState<FormData>(new FormData())
  const [editCompanyLogo, setEditCompanyLogo] = React.useState<FormData>(new FormData())
  const [getAllCompaniesFilter, setGetAllCompaniesFilter] = React.useState<EditCompany[]>([])

  const [createCompanyResponse, setCreateCompanyResponse] = React.useState<string>("")
  const [updateCompanyResponse, setUpdateCompanyResponse] = React.useState<string>("")
  const [deleteCompanyResponse, setDeleteCompanyResponse] = React.useState<string>("")
  const [getCompanyResponse, setGetCompanyResponse] = React.useState<EditCompany[]>([])

  console.log("edit img--", editCompanyLogo)

  const handleImgSrc = (event: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = (evt) => {
      setImgSrc(event?.target?.result)
    }
    console.log(event.target.files)
    const form = new FormData()
    form.append("img", event.target.files[0])
    setCompanyLogo(form)
  }

  const handleEditImgSrc = (event: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = (evt) => {
      setEditImgSrc(event?.target?.result)
    }
    console.log(event.target.files)
    const form = new FormData()
    form.append("img", event.target.files[0])
    setEditCompanyLogo(form)
  }

  function handleName(e: React.ChangeEvent<HTMLInputElement>) {
    setName(e.target.value)
  }

  function handleEditName(e: React.ChangeEvent<HTMLInputElement>) {
    setEditName(e.target.value)
  }
  // function handleImgSrc(e: React.ChangeEvent<HTMLInputElement>) {
  //   handleProfile_picChange()
  //   // setImgSrc(e.target.value)
  // }

  const CompanyLogoUploadUseCase = new UploadImage(new DocRepositoryImpl(new AdminDocDataSourceImpl()))

  const createCompanyUseCase = new CreateCompany(new CompanyRepositoryImpl(new CompanyAPIDataSourceImpl()))

  const updateCompanyUseCase = new UpdateCompany(new CompanyRepositoryImpl(new CompanyAPIDataSourceImpl()))

  const getCompanyUseCase = new GetCompany(new CompanyRepositoryImpl(new CompanyAPIDataSourceImpl()))

  const deleteCompanyUseCase = new DeleteCompany(new CompanyRepositoryImpl(new CompanyAPIDataSourceImpl()))

  const handleCreateCompany = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setCreateLoader(true)
    // console.log("this is company logo--", companyLogo)
    const companyLogoURL = await CompanyLogoUploadUseCase.invoke(auth, companyLogo)
    // console.log("2345678")
    if (!companyLogoURL?.success) {
      setCreateLoader(false)
      changeToastDetails(STR_FAILURE, "Unknown error occurred!")
      changeToastVisibility(true)
      return
    }
    const response = await createCompanyUseCase.invoke({
      auth,
      name: name,
      img_src: companyLogoURL.data,
    })
    setCreateLoader(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Error Occurred")
      console.log("Error", response)
      changeToastVisibility(true)
      return
    }

    setName("")
    setCompanyLogo(new FormData())
    setImgSrc("")
    setCreateCompanyResponse(response?.data)
    changeToastDetails(STR_ACCEPTED, "Company Tag Added")
    changeToastVisibility(true)
  }

  const handleUpdateCompany = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setEditLoader(true)

    let response = null
    let companyLogoURL: { success?: boolean; data?: string } | null = null

    // Check if editCompanyLogo is an empty FormData object
    console.log("edit company name--", editCompanyLogo, "this is compared--", new FormData())
    if (editCompanyLogo.has("img")) {
      console.log("thsi si true")
      // Upload the logo if there is a file in editCompanyLogo
      companyLogoURL = await CompanyLogoUploadUseCase.invoke(auth, editCompanyLogo)

      if (!companyLogoURL?.success) {
        changeToastDetails(STR_FAILURE, "Unknown error occurred!")
        changeToastVisibility(true)
        setEditLoader(false) // Stop the loader in case of failure
        return
      }

      response = await updateCompanyUseCase.invoke({
        auth,
        id: id,
        name: editName,
        img_src: companyLogoURL?.data || "", // Use the new image source
      })
    } else {
      // If no logo update, just update the company details
      response = await updateCompanyUseCase.invoke({
        auth,
        id: id,
        name: editName,
        img_src: editImgSrc, // Keep the current image
      })
    }
    const data = new FormData()
    setEditCompanyLogo(data)
    setEditLoader(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Error Occurred")
      console.log("Error", response)
      changeToastVisibility(true)
      return
    }

    setOpenEditModal(false)

    setGetCompanyResponse((getCompanyResponse) =>
      getCompanyResponse.map((company) =>
        company.id === id
          ? {
              ...company,
              name: editName,
              img_src: editCompanyLogo.has("img") ? companyLogoURL?.data || "" : editImgSrc || "", // Ensure img_src is always a string
            }
          : company
      )
    )

    changeToastDetails(STR_ACCEPTED, "Company Tag Updated")
    changeToastVisibility(true)
  }

  const handleDeleteCompany = async () => {
    setDeleteLoader(true)
    const response = await deleteCompanyUseCase.invoke(auth, id)
    setDeleteLoader(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Error Occurred")
      console.log("Error", response)
      changeToastVisibility(true)
      return
    }

    setOpenDeleteModal(false)
    setGetCompanyResponse((getCompanyResponse) => getCompanyResponse.filter((company) => company?.id !== id))
    changeToastDetails(STR_ACCEPTED, "Company Tag Deleted")
    changeToastVisibility(true)
  }

  const fetchAllCompanies = async () => {
    const response = await getCompanyUseCase.invoke(auth)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Error Occurred")
      console.log("Error", response)
      changeToastVisibility(true)
      return
    }
    setGetAllCompaniesFilter(response?.data)
    setGetCompanyResponse(response?.data)
  }

  const handleCompanyFilter = async (e: ChangeEvent<HTMLInputElement>) => {
    setCompanyFilter(e.target.value)
    if (e.target.value == "") {
      setGetCompanyResponse(getAllCompaniesFilter)
    }
    setGetCompanyResponse((getAllCompaniesFilter) =>
      getAllCompaniesFilter.filter((company) => company.name.toLowerCase().includes(e.target.value.toLowerCase()))
    )
  }

  return {
    toast,
    name,
    editName,
    imgSrc,
    editImgSrc,
    setName,
    setEditName,
    setEditImgSrc,
    openEditModal,
    companyFilter,
    openDeleteModal,
    setId,
    setCompanyFilter,
    setOpenEditModal,
    setOpenDeleteModal,
    createLoader,
    editLoader,
    deleteLoader,
    setDeleteLoader,
    handleName,
    handleEditName,
    handleImgSrc,
    handleEditImgSrc,
    createCompanyResponse,
    getCompanyResponse,
    fetchAllCompanies,
    handleDeleteCompany,
    handleCreateCompany,
    handleCompanyFilter,
    handleUpdateCompany,
    changeToastDetails,
    changeToastVisibility,
  }
}
