import { useEffect } from "react"
import {
  STR_LOGIN,
  STR_LOGIN_SUBTITLE,
  STR_LOGIN_TITLE,
  STR_PASSWORD,
  STR_EMAIL,
  STR_EMAIL_FIELD,
  STR_PASSWORD_FIELD,
} from "core/constants/strings"
import Input from "core/components/new/Input"
import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import LoginLayout from "core/layouts/LoginLayout"
import useLoginViewModel from "./LoginViewModel"
import { cn } from "core/lib/utils"
import LoginPageGradient from "core/components/new/LoginPageGradient"
import { useNavigate } from "react-router-dom"

export default function LoginView() {
  const {
    auth,
    user,
    toast,
    loading,
    emailValidity,
    inputRef,
    authUser,
    handleInputChange,
    handleFormSubmit,
    changeToastVisibility,
    redirectToPath,
    handleEmailBlur,
  } = useLoginViewModel()

  const navigate = useNavigate()

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  useEffect(() => {
    if (authUser && !loading) {
      navigate(redirectToPath(auth.role))
    }
  }, [authUser, loading])

  return (
    <>
      <div className="fixed top-0 left-0 translate-x-[-50%] translate-y-[-50%] w-[42%]">
        <div className="absolute z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>

        <div className="absolute transform translate-x-[30%] scaleX(-1) z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
      </div>
      <div className="absolute flex justify-center left-[60%]">
        <div className="absolute z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
        <div className="absolute transform translate-x-[30%] scaleX(-1) z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} className="w-[370px]" />
      <LoginLayout className="bg-new-solid-white z-10 gap-0">
        <form className="flex w-full flex-col gap-8" onSubmit={handleFormSubmit}>
          <div className="w-full">
            <h1 className="text-[#162456] text-[24px]">{STR_LOGIN_TITLE}</h1>
            <p className="text-[14px] text-[#414141] top-0">{STR_LOGIN_SUBTITLE}</p>
          </div>
          <div className="relative">
            <Input
              ref={inputRef}
              type={STR_EMAIL_FIELD}
              name={STR_EMAIL_FIELD}
              value={user.email}
              onChange={handleInputChange}
              placeholder={STR_EMAIL}
              label="Email ID"
              id="login-email"
              onBlur={handleEmailBlur}
              className="text-[#333333] placeholder:text-[#D3D3D3] border-[#D3D3D3] rounded-[4px]"
              containerClass="text-sm gap-0 text-[#333333]"
            />
            <span
              className={cn(
                "absolute top-[calc(100%+4px)] text-xs",
                emailValidity.status ? "text-new-success" : "text-new-failure"
              )}
            >
              {emailValidity.message}
            </span>
          </div>
          <Input
            type={STR_PASSWORD_FIELD}
            name={STR_PASSWORD_FIELD}
            value={user.password}
            onChange={handleInputChange}
            placeholder={STR_PASSWORD}
            label="Password"
            id="login-password"
            className="text-[#333333] placeholder:text-[#D3D3D3] border-[#D3D3D3] rounded-[4px]"
            containerClass="text-sm gap-0 text-[#333333]"
          />
          <div className="flex flex-col gap-4 ">
            <Button loading={loading} className="rounded-md text-sm bg-[#1369EB] mb-[10px]">
              {STR_LOGIN}
            </Button>
            {/* <Link
            to="reset"
            className="w-full text-right text-sm text-new-accent underline underline-offset-2"
          >
            {STR_FORGOT_PASSWORD}
          </Link> */}
          </div>
        </form>
      </LoginLayout>
      <div className="flex justify-center absolute right-[60%] transform scale-y-[-1] scale-x-[-1]">
        <div className="absolute z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
        <div className="absolute transform translate-x-[30%] scaleX(-1) z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
      </div>
      <div className="fixed bottom-0 right-0 translate-x-[50%] translate-y-[-50%] scale-y-[-1] scale-x-[-1] w-[42%]">
        <div className="absolute z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
        <div className="absolute transform translate-x-[30%] scaleX(-1) z-0">
          <LoginPageGradient className={"#C9E5F5"} />
        </div>
      </div>
    </>
  )
}
