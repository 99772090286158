import TableButton from "core/components/v2/TableButton"
import { STR_PAID } from "core/constants/strings"
import { useApp } from "core/context/app"
import { useAuth } from "core/context/auth"
import { isEmpty } from "core/utils/misc"
import React, { forwardRef, useRef, Ref } from "react"
import { Link } from "react-router-dom"

const BasicCard = forwardRef(
  (
    { step, checked, heading, description, btnType, link, disabled, handleViewDetailsClick }: any,
    ref: Ref<HTMLDivElement>
  ) => {
    const buttonRef = useRef<HTMLButtonElement>(null)
    const { auth } = useAuth()
    const { student } = useApp()
    const [isPaymentDone, setIsPaymentDone] = React.useState(false)
    React.useEffect(() => {
      if (!isEmpty(student) && !isEmpty(auth) && auth?.role === "user") {
        setIsPaymentDone(auth?.payment_status === STR_PAID)
      }
    }, [auth, student])

    const type: {
      [type: string]: {
        text: string
        onClick: () => void
      }
    } = {
      details: {
        text: "View Details",
        onClick: () => handleViewDetailsClick(step),
      },
      join: {
        text: "Join Now",
        onClick: () => handleViewDetailsClick(step),
      },
      video: {
        text: "Play Video",
        onClick: () => handleViewDetailsClick(step),
      },
      book: {
        text: "Book Now",
        onClick: () => handleViewDetailsClick(step),
      },
      payment: {
        text: "Pay Now",
        onClick: () => handleViewDetailsClick(step),
      },
    }
    return (
      <div
        ref={ref}
        className="flex max-h-[187px] min-h-[187px] min-w-[146px] max-w-[146px] flex-col space-y-2 rounded-[8px] border-[0.5px] border-[#D3D3D3] p-3 shadow-[-1px_1px_3px_0_rgba(132,136,139,0.08)]"
      >
        <div className="flex h-full flex-col justify-between">
          <div className="space-y-1">
            <div className="flex justify-between">
              <p className="text-[10px] font-semibold leading-3 text-[#03249A]">{step}</p>
              {!disabled && (
                <input
                  type="checkbox"
                  className="no-ring no-hover rounded border-[#D3D3D3] checked:bg-[#00C22B]"
                  checked={checked}
                  readOnly
                />
              )}
            </div>
            {/* div for underline */}
            <div className="w-[17px] border-b-[0.5px] border-[#D3D3D3]"></div>
            <div className="space-y-1">
              <p className="h-[34px] overflow-hidden text-[14px] font-semibold leading-[17px] text-black">{heading}</p>
              <p className="text-[11px] font-normal leading-[14.3px] text-[#646464]">{description}</p>
            </div>
          </div>
          {btnType !== "" && (
            <Link className="mt-auto" to={link} rel="noopener noreferrer">
              <TableButton
                ref={buttonRef}
                outlined
                onClick={handleViewDetailsClick && type[btnType].onClick}
                disabled={heading === "Course Payment" && isPaymentDone}
              >
                {type[btnType].text}
              </TableButton>
            </Link>
          )}
        </div>
      </div>
    )
  }
)

export default BasicCard
