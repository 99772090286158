import React from "react"
import { StarIcon } from "core/constants/svgs"

export default function StarRating({ rating, total }: any) {
  return (
    <div className="flex items-center gap-2">
      <StarIcon className="h-5 w-5" />
      <h4 className="text-new-accent">
        {rating}/{total}
      </h4>
    </div>
  )
}
