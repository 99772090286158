import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import ContestPopup from "../components/ContestPopup"
import ConditionalLoader from "core/components/ConditionalLoader"
import useViewModel from "./ViewModel"
import { STR_PLACEHOLDER_IMAGE } from "core/constants/strings"
import { format } from "date-fns"
import { Tooltip } from "@mui/material"
import { isEmpty } from "core/utils/misc"
import { useEffect } from "react"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"

export default function ContestStart() {
  const {
    loadingContest,
    contest,
    openPopup,
    contest_id,
    isPast,
    isRegistered,
    isApproved,
    enterContestDisabled,
    fetchedRegisteredStatus,
    registering,
    toast,
    refreshed,
    student,
    reattempt,
    onOpenPopup,
    handleRegister,
    redirect,
    onClosePopup,
    changeToastVisibility,
    checkContestStatus,
    fetchStudentContestDetails,
    fetchContest,
    fetchRegistered,
    navigate,
  } = useViewModel()

  useEffect(() => {
    checkContestStatus()
    const interval = setInterval(checkContestStatus, 1000)
    return () => clearInterval(interval)
  }, [registering, contest])

  useEffect(() => {
    if (contest_id) {
      fetchContest()
      fetchStudentContestDetails()
    }
  }, [contest_id])

  useEffect(() => {
    if (contest_id) {
      fetchRegistered()
    }
  }, [contest_id, registering])

  useEffect(() => {
    if (!isPast) return
    if (!reattempt && !isApproved) {
      navigate(`/weekly-test-series`)
    }
  }, [student, contest])

  const duration = parseInt(contest?.duration)
  const hours = Math.floor(duration / 60)
  const minutes = Math.floor(duration % 60)

  return (
    <DashboardLayoutv2 isLoading={loadingContest}>
      <ContestPopup data={contest} open={openPopup} onClose={onClosePopup} onClick={redirect} />
      <ConditionalLoader
        isLoading={isEmpty(contest)}
        loader={
          <div className="flex h-[100px] w-full items-center justify-center">No contest found/Not registered</div>
        }
      >
        <div className="relative flex">
          <div className="flex w-[55%] flex-col">
            <div className="h-[211px] w-full">
              <img src={STR_PLACEHOLDER_IMAGE} alt="banner" className="h-full w-full object-cover" />
            </div>
            <div className="space-y-6 p-6">
              <div className="space-y-2">
                <h2 className="text-new-accent">{contest?.name}</h2>
                <h4 className="text-new-neutral-dark">
                  {contest?.start_time &&
                    `${format(new Date(contest?.start_time * 1000), "do MMM yyyy")} | ${format(
                      new Date(contest?.start_time * 1000),
                      "hh:mm a O"
                    )} | `}
                  {contest?.duration && (
                    <>
                      Duration: {hours > 0 && `${hours}hr${hours > 1 ? "s " : " "}`}
                      {minutes > 0 && `${minutes}min${minutes > 1 ? "s" : ""}`}
                    </>
                  )}
                </h4>
              </div>
              <div className="space-y-2">
                <h3 className="text-new-neutral-dark">General Information</h3>
                <ol className="space-y-[1ch]">
                  {contest?.general_information?.split("\n").map((info: string, index: number) => (
                    <li key={index} className="text-new-neutral">
                      {index + 1}. {info}
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
          <div className="sticky top-0 flex h-full min-h-[calc(100vh-49px)] flex-1 flex-col justify-between p-6 shadow-[0px_3px_7px_0px_rgba(0,0,0,0.10)]">
            <div className="space-y-2 text-new-neutral-dark">
              <h3>Challenge Details</h3>
              <div className="space-y-[2ch] font-medium">
                {contest?.mcq_lis?.length > 0 && (
                  <div className="space-y-[1ch]">
                    <p>Multiple Choice Questions:</p>
                    {/* <p>
                    MCQs marks will be added to contest-score after the contest
                    gets over. You can modify your answers any number of times
                    during the contest.
                  </p> */}
                    <ul className="list-disc pl-8">
                      <li>{contest?.mcq_lis?.length} Questions</li>
                      <li>{contest?.mcq_lis?.reduce((total: number, mcq: any) => total + mcq?.score, 0)} Marks</li>
                    </ul>
                  </div>
                )}
                {contest?.question_lis?.length > 0 && (
                  <div className="space-y-[1ch]">
                    <p>Coding Problem Details:</p>
                    {/* <p>
                    Solve as many problem(s) as you can before the time runs out
                    and based on the accuracy of your correct submission you
                    will get the scores.
                  </p> */}
                    <ul className="list-disc pl-8">
                      <li>{contest?.question_lis?.length} Questions</li>
                      <li>
                        {contest?.question_lis?.reduce((total: number, question: any) => total + question?.score, 0)}{" "}
                        Marks
                      </li>
                    </ul>
                  </div>
                )}
                {contest?.subjective_lis?.length > 0 && (
                  <div className="space-y-[1ch]">
                    <p>Subjective Questions:</p>
                    <ul className="list-disc pl-8">
                      <li>{contest?.subjective_lis?.length} Questions</li>
                      <li>{contest?.subjective_lis?.reduce((total: number, q: any) => total + q?.score, 0)} Marks</li>
                    </ul>
                  </div>
                )}
                {contest?.casestudy_lis?.length > 0 && (
                  <div className="space-y-[1ch]">
                    <p>Casestudy Questions:</p>
                    <ul className="list-disc pl-8">
                      <li>{contest?.casestudy_lis?.length} Questions</li>
                      <li>{contest?.casestudy_lis?.reduce((total: number, q: any) => total + q?.score, 0)} Marks</li>
                    </ul>
                  </div>
                )}
                {contest?.mcq_lis?.length === 0 &&
                  contest?.question_lis?.length === 0 &&
                  contest?.subjective_lis?.length === 0 &&
                  contest?.casestudy_list?.length === 0 && <p>No questions found</p>}
              </div>
            </div>
            <div className="space-y-4">
              {contest?.type === "instant" || !isPast ? (
                isRegistered ? (
                  <Tooltip title={enterContestDisabled ? "Contest has not started yet." : ""} placement="top">
                    <span>
                      <Button
                        className="disabled:opacity-50"
                        disabled={enterContestDisabled}
                        onClick={() => onOpenPopup(contest_id as string)}
                        success
                      >
                        Start Contest
                      </Button>
                    </span>
                  </Tooltip>
                ) : fetchedRegisteredStatus ? (
                  <Button loading={registering} disabled={registering} onClick={handleRegister}>
                    Register Now
                  </Button>
                ) : (
                  <Button disabled>Fetching registration status</Button>
                )
              ) : (
                <Button onClick={onOpenPopup}>{reattempt == true ? "Reattempt" : "Start Exam"}</Button>
              )}
            </div>
          </div>
        </div>
      </ConditionalLoader>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayoutv2>
  )
}
