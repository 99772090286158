import { CrossIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import Button from "./Button"

export default function Modal({ open, children, onClose, width, height = "", padding }: any) {
  const ableToClose = onClose && typeof onClose === "function"
  console.log("inside modal")
  if (!open) return null

  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div
          {...(ableToClose ? { onClick: onClose } : {})}
          className="fixed inset-0 backdrop-blur-sm"
          aria-hidden="true"
        >
          <div className="absolute inset-0 bg-new-solid-black opacity-75" />
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-5">
          <div
            className={cn(
              "relative my-auto w-[600px] rounded bg-new-solid-white p-6",
              !width && padding ? "w-[862px] py-0" : width,
              height
            )}
          >
            <button
              className={cn("absolute top-4 right-4", !ableToClose && "hidden")}
              {...(ableToClose ? { onClick: onClose } : {})}
            >
              <CrossIcon className="h-6 w-6" />
            </button>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
