import { cn } from "core/lib/utils"
import * as React from "react"
import { InfoIcon } from "lucide-react"

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  subInfo?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type = "text", label, subInfo, ...props }, ref) => {
    return (
      <div className="flex flex-col gap-1">
        <div className="flex justify-between items-center">
          {label && (
            <label htmlFor="label" className="text-[14px] font-semibold">
              {label}
            </label>
          )}
          {subInfo && <InfoIcon className="w-4 h-4 text-v2-gray-400" />}
        </div>
        <input
          type={type}
          className={cn(
            "w-[300px] rounded px-4 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-v2-gray-500 disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
          ref={ref}
          {...props}
        />
        {subInfo && (
          <label htmlFor="label" className="text-[10px] font-normal text-v2-gray-500">
            {subInfo}
          </label>
        )}
      </div>
    )
  }
)

Input.displayName = "Input"
export { Input }
