import { Auth } from "domain/model/Auth"
import { MentorRepository } from "domain/repository/Student/MentorRepository"

export interface GetMentorAvailabilityUseCase {
  invoke(auth: Auth, isMilestoneSession: boolean, data: any): Promise<any>
}

export class GetMentorAvailability implements GetMentorAvailabilityUseCase {
  private repository: MentorRepository

  constructor(repository: MentorRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, isMilestoneSession: boolean, data: any): Promise<any> {
    return await this.repository.getMentorAvailability(auth, isMilestoneSession, data)
  }
}
