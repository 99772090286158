import { ChangeEvent, useRef, useState } from "react"
import { User } from "domain/model/User"
import { STR_FAILURE, STR_FILL_ALL_FIELDS, STR_LOGIN_SUCCESSFUL, STR_SUCCESS } from "core/constants/strings"
import { useNavigate } from "react-router-dom"
import { isEmail } from "core/utils/validator"
import { isEmpty } from "core/utils/misc"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import delay from "core/utils/delay"
import { redirectPath } from "core/lib/utils"

export default function LoginViewModel() {
  const [user, setUser] = useState<User>({ email: "", password: "" })
  const [loading, setLoading] = useState<boolean>(false)
  const [emailValidity, setEmailValidity] = useState({ status: false, message: "" })

  const inputRef = useRef<HTMLInputElement>(null)

  const navigate = useNavigate()

  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const { auth, user: authUser, login } = useAuth()

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newUser = { ...user, [e.target.name]: e.target.value }
    setUser(newUser)
  }

  const checkEmailValidity = () => {
    const isValid = isEmail(user.email)
    setEmailValidity({
      status: isValid,
      message: isValid ? "Good to go!" : "Sorry, there might be something wrong!",
    })
  }

  const handleEmailBlur = () => {
    checkEmailValidity()
  }

  const handleLogin = async (e: any) => {
    e.preventDefault()

    if (isEmpty(user.email) || isEmpty(user.password)) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, STR_FILL_ALL_FIELDS)
      return
    }

    if (!isEmail(user.email)) return

    const email = user.email.trim().toLowerCase()
    const password = user.password.trim()

    setLoading(true)

    try {
      await login(email, password)
    } catch (error: any) {
      changeToastDetails(STR_FAILURE, error.message)
      changeToastVisibility(true)
      setLoading(false)
      return
    }

    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, STR_LOGIN_SUCCESSFUL)

    await delay(1000)
    setLoading(false)
  }

  return {
    auth,
    user,
    toast,
    loading,
    emailValidity,
    inputRef,
    authUser,
    handleInputChange,
    handleFormSubmit: handleLogin,
    changeToastVisibility,
    changeToastDetails,
    redirectToPath: redirectPath,
    handleEmailBlur,
    navigate,
  }
}
