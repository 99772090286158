import DoubtSupportAPIDataSourceImpl from "data/API/Student/DoubtSupportAPIDataSourceImpl"
import { DoubtSupportRepositoryImpl } from "data/repository/Student/DoubtSupportRepositoryImpl"
import { GetAllClassTopics } from "domain/useCase/Student/DoubtSupport/GetAllClassTopics"
import { GetAllQuestions } from "domain/useCase/Student/DoubtSupport/GetAllQuestions"
import { GetDoubtQuerySolution } from "domain/useCase/Student/DoubtSupport/GetDoubtQuerySolution"
import { GetStudentDoubtHistory } from "domain/useCase/Student/DoubtSupport/GetStudentDoubtHistory"
import { UpdateDoubtStatus } from "domain/useCase/Student/DoubtSupport/UpdateDoubtStatus"
import { useAuth } from "core/context/auth"
import React from "react"
import { SearchStudentDoubt } from "domain/useCase/Student/DoubtSupport/SearchStudentDoubt"
import { GetStudentDoubtPending } from "domain/useCase/Student/DoubtSupport/GetStudentDoubtPending"
import { GetFutureBatches } from "domain/useCase/Student/DoubtSupport/GetFutureBatches"
import CheckboxEmpty from "assets/svgs/CheckboxEmpty"
import CheckboxFilled from "assets/svgs/CheckboxFilled"
import { StudentBatchShift } from "domain/useCase/Student/DoubtSupport/StudentBatchShift"
import { IsEligibleForBatchChange } from "domain/useCase/Student/DoubtSupport/IsEligibleForBatchChange"
import { StudentBatchPause } from "domain/useCase/Student/DoubtSupport/StudentBatchPause"
import { GetEligibleMentors } from "domain/useCase/Student/DoubtSupport/GetEligibleMentors"
import { UpdateMentor } from "domain/useCase/Student/DoubtSupport/UpdateMentor"
import { useApp } from "core/context/app"

export default function DoubtSupportViewModel() {
  const { auth, refreshed } = useAuth()
  const { setStudent } = useApp()

  const getAllClassTopicsUseCase = new GetAllClassTopics(
    new DoubtSupportRepositoryImpl(new DoubtSupportAPIDataSourceImpl())
  )

  const getAllQuestionsUseCase = new GetAllQuestions(
    new DoubtSupportRepositoryImpl(new DoubtSupportAPIDataSourceImpl())
  )

  const getDoubtQuerySolutionUseCase = new GetDoubtQuerySolution(
    new DoubtSupportRepositoryImpl(new DoubtSupportAPIDataSourceImpl())
  )

  const updateDoubtStatusUseCase = new UpdateDoubtStatus(
    new DoubtSupportRepositoryImpl(new DoubtSupportAPIDataSourceImpl())
  )

  const getStudentDoubtHistoryUseCase = new GetStudentDoubtHistory(
    new DoubtSupportRepositoryImpl(new DoubtSupportAPIDataSourceImpl())
  )

  const searchStudentDoubtUseCase = new SearchStudentDoubt(
    new DoubtSupportRepositoryImpl(new DoubtSupportAPIDataSourceImpl())
  )

  const getStudentDoubtPendingUseCase = new GetStudentDoubtPending(
    new DoubtSupportRepositoryImpl(new DoubtSupportAPIDataSourceImpl())
  )

  const getFutureBatchesUseCase = new GetFutureBatches(
    new DoubtSupportRepositoryImpl(new DoubtSupportAPIDataSourceImpl())
  )

  const studentBatchShiftUseCase = new StudentBatchShift(
    new DoubtSupportRepositoryImpl(new DoubtSupportAPIDataSourceImpl())
  )

  const isEligibleForBatchChangeUseCase = new IsEligibleForBatchChange(
    new DoubtSupportRepositoryImpl(new DoubtSupportAPIDataSourceImpl())
  )

  const studentBatchPauseUseCase = new StudentBatchPause(
    new DoubtSupportRepositoryImpl(new DoubtSupportAPIDataSourceImpl())
  )

  const getEligibleMentorsUseCase = new GetEligibleMentors(
    new DoubtSupportRepositoryImpl(new DoubtSupportAPIDataSourceImpl())
  )

  const updateMentorUseCase = new UpdateMentor(new DoubtSupportRepositoryImpl(new DoubtSupportAPIDataSourceImpl()))

  const [classTopics, setClassTopics] = React.useState<any>()
  const [questions, setQuestions] = React.useState<any>([])
  const [solution, setSolution] = React.useState<any>({})
  const [topicDic, setTopicDic] = React.useState<any>({})
  const [questionsDic, setQuestionsDic] = React.useState<any>({})
  const [history, setHistory] = React.useState<any>()
  const [doubtStatus, SetDoubtStatus] = React.useState<any>({})
  const [searchHistory, setSearchHistory] = React.useState<any>({})
  const [pendingDoubt, setPendingDoubt] = React.useState<any>()
  const [futureBatchDes, setFutureBatchDesc] = React.useState<any>({
    header: [],
    rows: [],
  })

  const [selectedBatch, setSelectedBatch] = React.useState<any>(-1)
  const [batchChangeStatus, setBatchChangeStatus] = React.useState<any>({})
  const [eligibleMentorDetails, setEligibleMentorDetails] = React.useState<any>({})

  const [operationalChangesDone, setOperationalChangesDone] = React.useState<any>(false)

  const [futureBatchEligiblity, setFutureBatchEligiblity] = React.useState<any>([])

  const fetchClassTopics = async () => {
    const response = await getAllClassTopicsUseCase.invoke(auth)

    const valueList = []
    const topics: any = {}
    for (const key in response.data) {
      valueList.push(response.data[key])
      topics[response.data[key]] = key
    }
    setTopicDic(topics)
    setClassTopics(valueList)
  }

  const fetchQuestions = async (class_name_id: any) => {
    if (class_name_id !== "") {
      const response = await getAllQuestionsUseCase.invoke(auth, topicDic[class_name_id])
      const valueList = []
      const ques: any = {}
      for (const key in response.data) {
        ques[response.data[key].problem_name] = response.data[key].problem_id
        valueList.push(response.data[key].problem_name)
      }
      setQuestionsDic(ques)
      setQuestions(valueList)
    }
  }

  const fetchDoubtQuerySolution = async (problem_id: any) => {
    const response = await getDoubtQuerySolutionUseCase.invoke(auth, questionsDic[problem_id])
    setSolution(response.data)
  }

  const fetchStudentDoubtHistory = async () => {
    const response = await getStudentDoubtHistoryUseCase.invoke(auth)
    setHistory(response)
  }

  const updateDoubtStatus = async (like: any, problem_id: any) => {
    const response = await updateDoubtStatusUseCase.invoke(auth, like, problem_id)
    SetDoubtStatus(response)
  }

  const searchStudentDoubt = async (problem_id: any) => {
    const response = await searchStudentDoubtUseCase.invoke(auth, questionsDic[problem_id])
    setSearchHistory(response?.data)
  }

  const fetchStudentDoubtPending = async () => {
    const response = await getStudentDoubtPendingUseCase.invoke(auth)
    setPendingDoubt(response)
  }

  const fetchFutureBatches = async () => {
    const response = await getFutureBatchesUseCase.invoke(auth)
    setFutureBatchEligiblity(response?.data)

    const header = ["Batch", "Last Class Held", "Select Batch"]

    const rows = response?.data?.map((batch_status: any, i: number) => ({
      batch:
        batch_status["batch"][0] === "B"
          ? batch_status["batch"].slice(0, 3).concat(batch_status["batch"][3] === "A" ? " Advance" : " Beginner")
          : batch_status["batch"],
      status: batch_status["status"],
      selection: (
        <button
          className="cursor-pointer"
          onClick={() => {
            response?.data[i]["is_batch_open_to_choose"] && setSelectedBatch(i)
          }}
        >
          <CheckboxEmpty className="absolute right-[23px] mt-[-12px] flex h-6 w-6 " />
        </button>
      ),
    }))

    setFutureBatchDesc({ header, rows })
  }

  const setFutureBatches = async () => {
    const rows = futureBatchDes["rows"]?.map((batch_status: any, i: number) => ({
      batch: batch_status["batch"],
      status: batch_status["status"],
      selection: (
        <button
          className="cursor-pointer"
          onClick={() => {
            futureBatchEligiblity[i]["is_batch_open_to_choose"] && setSelectedBatch(i)
          }}
        >
          {selectedBatch === i ? (
            <CheckboxFilled className="absolute right-[23px] mt-[-12px] flex h-6 w-6 " />
          ) : (
            <CheckboxEmpty className="absolute right-[23px] mt-[-12px] flex h-6 w-6 " />
          )}
        </button>
      ),
    }))

    setFutureBatchDesc({ ...futureBatchDes, rows })
  }

  const setStudentBatchShift = async (batch: any, reason: any, is_batch_resume: any, discord_username: any) => {
    const response = await studentBatchShiftUseCase.invoke(auth, batch, reason, is_batch_resume, discord_username)

    if (response === "success") {
      setOperationalChangesDone(true)
      setStudent((prevStudent: any) => ({
        ...prevStudent,
        is_batch_paused: false,
        batch_paused_date: null,
      }))
    }
  }

  const eligibleForBatchChange = async () => {
    const response = await isEligibleForBatchChangeUseCase.invoke(auth)
    setBatchChangeStatus(response?.data)
  }

  const setStudentBatchPause = async (reason: any) => {
    const response = await studentBatchPauseUseCase.invoke(auth, reason)
    if (response === "success") {
      setStudent((prevStudent: any) => ({
        ...prevStudent,
        is_batch_paused: true,
        batch_paused_date: Math.floor(Date.now() / 1000),
      }))
      setOperationalChangesDone(true)
    }
  }

  const fetchEligibleMentors = async () => {
    const response = await getEligibleMentorsUseCase.invoke(auth)
    setEligibleMentorDetails(response?.data)
  }

  const setMentor = async (mentor_id: any, reason: any) => {
    const response = await updateMentorUseCase.invoke(auth, mentor_id, reason)
    if (response?.data === "success") {
      setOperationalChangesDone(true)
    }
  }

  return {
    refreshed,
    fetchClassTopics,
    fetchQuestions,
    fetchDoubtQuerySolution,
    fetchStudentDoubtHistory,
    updateDoubtStatus,
    searchStudentDoubt,
    fetchStudentDoubtPending,
    fetchFutureBatches,
    eligibleForBatchChange,
    futureBatchDes,
    classTopics,
    questions,
    solution,
    setSolution,
    doubtStatus,
    history,
    searchHistory,
    setSearchHistory,
    setQuestions,
    pendingDoubt,
    selectedBatch,
    batchChangeStatus,
    setPendingDoubt,
    setStudentBatchShift,
    setStudentBatchPause,
    setSelectedBatch,
    fetchEligibleMentors,
    eligibleMentorDetails,
    setMentor,
    operationalChangesDone,
    setOperationalChangesDone,
    futureBatchEligiblity,
  }
}
