import {
  API_ADMIN,
  API_GET_STUDENTS_BY_BATCH,
  API_GET_STUDENTS_BY_NAME,
  API_GET_STUDENT_BY_EMAIL,
  API_GET_ALL_MENTORS,
  API_SEND_LOGIN_DETAILS,
  API_V4_GET_NEXT_100_STUDENTS,
  API_v4_GET_TOP_100_STUDENTS,
  API_V4_GET_USER_DATA,
  API_v4_UPDATE_DISABLE_STATE,
  API_v4_UPDATE_PAID_TO_UNPAID,
  API_V3_ADMIN,
  API_V4_ADMIN,
  API_REMOVE_MENTOR,
  API_GET_ALL_ONBOARDING_MANAGERS,
  API_UPDATE_STUDENT_PROFILE,
  API_DISABLE_STUDENT_REASON_UPDATE,
  API_V4_REFUND_STUDENT,
  API_V4_GET_BATCH_HISTORY,
  API_V4_DELETE_STUDENT,
  API_V4_UPDATE_MILESTONE_SESSION_MENTOR,
} from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import {
  TDisableStudentReasonUpdate,
  getStudentDetails,
  loadNextData,
  mentorList,
  saveResponse,
  sendStates,
  TPaymentStatusReq,
  deleteStudent,
} from "domain/model/StudentDashboard"
import { StudentDashboardDataSource } from "data/dataSource/Admin/StudentDashboardDataSource"
import { Auth } from "domain/model/Auth"
import { Server } from "core/utils/axios"
const server = new Server()

export class StudentDashboardAPIDataSourceImpl implements StudentDashboardDataSource {
  async getStudentByEmail(data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_ADMIN, API_GET_STUDENT_BY_EMAIL), data)
      return response
    } catch (error) {
      return error
    }
  }

  async getStudentsByName(data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_ADMIN, API_GET_STUDENTS_BY_NAME), data)
      return response
    } catch (error) {
      return error
    }
  }

  async getStudentsByBatch(data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_ADMIN, API_GET_STUDENTS_BY_BATCH), data)
      return response
    } catch (error) {
      return error
    }
  }
  async getTop100Students(details: getStudentDetails): Promise<any> {
    const response = await postAPI(pn(API_V3_ADMIN, API_v4_GET_TOP_100_STUDENTS), {
      admin_uid: details,
    })

    return response
  }

  async updateDisableState(updateState: sendStates): Promise<any> {
    const response = await postAPI(pn(API_V3_ADMIN, API_v4_UPDATE_DISABLE_STATE), {
      admin_uid: updateState.admin_uid,
      disable_user: updateState.status,
      email: updateState.email,
    })
    return response
  }

  async updatePaidToUnpaid(updateState: TPaymentStatusReq): Promise<any> {
    const response = await postAPI(pn(API_V3_ADMIN, API_v4_UPDATE_PAID_TO_UNPAID), updateState)
    return response
  }

  async loadNextData(nextData: loadNextData): Promise<any> {
    const response = await postAPI(pn(API_V3_ADMIN, API_V4_GET_NEXT_100_STUDENTS), nextData)
    return response
  }
  async sendEmail(data: loadNextData): Promise<any> {
    const response = await postAPI(pn(API_V3_ADMIN, API_SEND_LOGIN_DETAILS), data)
    return response
  }

  async getUserData(userData: loadNextData): Promise<any> {
    const response = await postAPI(pn(API_V3_ADMIN, API_V4_GET_USER_DATA), userData)
    return response
  }

  async getMentorList(userData: mentorList): Promise<any> {
    const response = await postAPI(pn(API_V3_ADMIN, API_GET_ALL_MENTORS), userData)
    return response
  }

  async getOnboardingManagerList(auth: Auth): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_GET_ALL_ONBOARDING_MANAGERS), auth)
    return response
  }

  async updateStudentProfile({ auth, ...data }: saveResponse): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_STUDENT_PROFILE), {
        id_token: auth?.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async removeMentor(auth: Auth, email: string): Promise<any> {
    const response = await postAPI(pn(API_V4_ADMIN, API_REMOVE_MENTOR), {
      id_token: auth.id_token,
      email,
    })
    return response
  }
  async disableStudentReasonUpdate(auth: Auth, data: TDisableStudentReasonUpdate): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_DISABLE_STUDENT_REASON_UPDATE), {
        id_token: auth?.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getBatchHistoryData(auth: Auth, email: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_BATCH_HISTORY), {
        id_token: auth.id_token,
        email,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async deleteStudent(student: deleteStudent): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_DELETE_STUDENT), student)
      return response
    } catch (error) {
      return error
    }
  }

  async refundStudent(Auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_REFUND_STUDENT), {
        id_token: Auth?.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async updateMilestoneSessionMentor(auth: Auth, student_id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_UPDATE_MILESTONE_SESSION_MENTOR), {
        id_token: auth?.id_token,
        student_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getPlacementCompany(auth: Auth, email: string): Promise<any> {
    try {
      const response = await server.post(
        pn(API_ADMIN, "student_dashboard", "get_placement_details"),
        { email },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response.data
    } catch (error) {
      return error
    }
  }

  async addPlacementCompany(auth: Auth, company_details: any): Promise<any> {
    try {
      const response = await server.post(
        pn(API_ADMIN, "student_dashboard", "add_placement_details"),
        { company_details },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response.data
    } catch (error) {
      return error
    }
  }
  async editPlacementCompany(auth: Auth, company_details: any): Promise<any> {
    try {
      const response = await server.post(
        pn(API_ADMIN, "student_dashboard", "edit_placement_details"),
        { company_details },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response.data
    } catch (error) {
      return error
    }
  }
  async deletePlacementCompany(auth: Auth, company_details: any): Promise<any> {
    try {
      const response = await server.post(
        pn(API_ADMIN, "student_dashboard", "delete_placement_details"),
        { company_details },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response.data
    } catch (error) {
      return error
    }
  }

  async getPlacementReady(auth: Auth, email: string): Promise<any> {
    try {
      const response = await server.post(
        pn(API_ADMIN, "student_dashboard", "get_placement_ready_details"),
        { email },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response.data
    } catch (error) {
      return error
    }
  }
  async setPlacementReady(auth: Auth, placement_details: any): Promise<any> {
    try {
      const response = await server.post(
        pn(API_ADMIN, "student_dashboard", "set_placement_ready_details"),
        { placement_details },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response.data
    } catch (error) {
      return error
    }
  }

  async addStudentComment(auth: Auth, comment_details: any): Promise<any> {
    try {
      const response = await server.post(
        pn(API_ADMIN, "student_dashboard", "add_student_comment"),
        { comment_details },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response.data
    } catch (error) {
      return error
    }
  }
  async getStudentComment(auth: Auth, email: string): Promise<any> {
    try {
      const response = await server.get(pn(API_ADMIN, "student_dashboard", "get_student_comment", email), {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response.data
    } catch (error) {
      return error
    }
  }

  async editStudentComment(auth: Auth, comment_details: any): Promise<any> {
    try {
      const response = await server.put(
        pn(API_ADMIN, "student_dashboard", "edit_student_comment", comment_details.email),
        { comment_details },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response.data
    } catch (error) {
      return error
    }
  }
  async deleteStudentComment(auth: Auth, comment_details: any): Promise<any> {
    try {
      const response = await server.post(
        pn(API_ADMIN, "student_dashboard", "delete_student_comment"),
        { comment_details },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response.data
    } catch (error) {
      return error
    }
  }
}
