import React from "react"
import Avatar from "react-avatar"

export default function MenteesTable(props: any) {
  return (
    <div className="relative overflow-x-auto rounded-md shadow-md">
      <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400 ">
        <thead className="h-[40px] bg-gradient-to-r from-[#06A1F9] to-[#2246C3] text-[16px] text-white">
          <tr>
            <th className="whitespace-nowrap px-4 py-4 text-[16px] font-medium "></th>
            <th className="whitespace-nowrap px-10 py-4 text-[16px] font-medium ">Mentees</th>
          </tr>
        </thead>
        <tbody>
          {props.mentees?.map((data: any, index: any) => (
            <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={index}>
              <td className="px-6 py-4 ">
                <Avatar name={data} round size="50" />
              </td>
              <td className="px-6 py-4 ">
                <p>{data}</p>
                <p className="font-bold">{data.email}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
