import { format } from "date-fns"
import { STR_CANCEL, STR_SUBMIT } from "core/constants/strings"
import { CrossIcon, StarIcon } from "core/constants/svgs"
import getOrdinal from "core/utils/ordinal"
import Button from "core/components/new/Button"
import { isEmpty } from "core/utils/misc"

export default function MentorModal({ sessions, index, onClose, newMentorshipFlow }: any) {
  function formatTime(time: string): string {
    const hour = parseInt(time, 10)
    const isAM = hour >= 0 && hour < 12
    const formattedHour = isAM ? (hour === 0 ? 12 : hour) : hour - 12
    const period = isAM ? "AM" : "PM"

    const formattedTime = `${formattedHour < 10 ? "0" : ""}${formattedHour}:00 ${period}`
    return formattedTime
  }
  // const formattedDate = format(new Date(sessions?.session_date), "do MMM, yyyy");
  const formattedStartTime = formatTime(!newMentorshipFlow ? sessions?.start : sessions?.session_start_time)

  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={onClose} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-5">
          <div className="m-auto flex w-[850px] transform rounded border border-new-accent bg-new-solid-white font-medium">
            <div className="h-auto w-[238px] flex-col items-start gap-4 border-r-[1px] border-r-[#E9E9E9] p-6">
              <h3 className="gap-2 text-new-accent">Mentor Session</h3>
              <p className="text-neutral flex items-center">
                {" "}
                #{getOrdinal(index + 1)} {sessions?.name}
              </p>
            </div>
            <div className="w-[612px] p-6">
              <div className="relative flex w-full">
                <h4 className="flex-1 text-[#414141]">Mentor Sessions Feedback</h4>
                <button onClick={onClose}>
                  <CrossIcon className="h-6 w-6" />
                </button>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex ">
                  <div className="w-[180px]">
                    <p className="font-montserrat text-sm font-normal leading-6 text-[#414141]">Status</p>
                  </div>
                  <div className="flex-1 items-center text-new-success">Present</div>
                </div>

                <div className="flex ">
                  <div className="w-[180px]">
                    <p className="font-montserrat text-sm font-normal leading-6 text-[#414141]">Date / Time :</p>
                  </div>
                  <div className="flex-1 items-center">
                    {!newMentorshipFlow ? sessions?.date : sessions?.session_date} | {formattedStartTime}
                  </div>
                </div>
                {newMentorshipFlow ? (
                  <div className="flex ">
                    <div className="w-[180px]">
                      <p className="font-montserrat text-sm font-normal leading-6 text-[#414141]">Your Rating :</p>
                    </div>
                    <div className="flex-1 items-center text-[#FBBF24]">
                      <div className="flex gap-2">
                        {Array(5)
                          .fill(0)
                          .map((_, i) =>
                            !isEmpty(sessions?.mentor_feedback) ? (
                              <span key={i}>
                                <StarIcon
                                  className="h-6 w-6"
                                  pathClassName={
                                    sessions?.mentor_feedback?.rating >= i + 1 ? "fill-[#FBBF24]" : "fill-border"
                                  }
                                />
                              </span>
                            ) : null
                          )}
                        {sessions?.mentor_feedback?.rating}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="flex ">
                  <div className="w-[180px]">
                    <p className="font-montserrat text-sm font-normal leading-6 text-[#414141]">Remark from mentor :</p>
                  </div>
                  <div className="h-full flex-1 items-center rounded-sm bg-[#F7F7F7] p-2 pl-2">
                    <p>{sessions?.mentor_feedback?.remarks || sessions?.mentor_feedback?.feedback}</p>
                  </div>
                </div>
                <div className="flex ">
                  <div className="w-[180px]">
                    <p className="font-montserrat text-sm font-normal leading-6 text-[#414141]">
                      Work Action for student :{" "}
                    </p>
                  </div>
                  <div className="flex-1 items-center">
                    <div className="h-full flex-1 items-center rounded-sm bg-[#F7F7F7] p-2 pl-2 ">
                      <p>{sessions?.mentor_feedback?.actions || sessions?.mentor_feedback?.action_items}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
