import Button from "core/components/editor/Button"
import { MainLogo, ThumbDownFilledIcon, ThumbDownIcon, ThumbUpFilledIcon, ThumbUpIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import useEditorViewModel from "./EditorViewModel"
import Toast from "core/components/Toast"
import Loader from "core/components/Loader"
import { capitalize } from "core/utils/string"
import Spinner from "core/components/Spinner"
import { STR_DESCRIPTION, STR_SOLUTION, STR_SUBMISSIONS } from "core/constants/strings"
import { Tooltip } from "@mui/material"
import SubmissionDisclosure from "core/components/editor/SubmissionDisclosure"
import Editor, { OnMount } from "@monaco-editor/react"
import RunCodeDetails from "core/components/editor/RunCodeDetails"
import { isEmpty } from "core/utils/misc"
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "core/components/v2/ui/Resizable"

export default function EditorView() {
  const {
    toast,
    topic,
    templates,
    question,
    activeTab,
    language,
    code,
    submissions,
    isTemplateLoaded,
    isQuestionLoaded,
    isCodeRunning,
    isCodeSubmitting,
    isAllSubmissionsLoaded,
    submitCodeDetails,
    showRunCodeDetails,
    resetingTemplate,
    runCodeDetails,
    fetchDefaultTemplateCode,
    handleCloseRunCodeDetails,
    handleLanguageChange,
    handleTabChange,
    fetchProblemData,
    handleCodeChange,
    handleLikeProblem,
    handleDislikeProblem,
    handleRunCode,
    handleSubmitCode,
    changeToastVisibility,
    fetchSubmissions,
  } = useEditorViewModel()

  const TABS = [STR_DESCRIPTION, STR_SUBMISSIONS, STR_SOLUTION]

  const [errorLine, setErrorLine] = useState<number | null>(null)

  const LANGUAGE_LIST = {
    cpp: "C++",
    java: "Java",
    python: "Python",
    javascript: "JavaScript",
  }

  useEffect(() => {
    const style = document.createElement("style")
    style.innerHTML = `
      .myLineHighlight {
        background-color: rgba(255, 0, 0, 0.3);
      }
    `
    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [])
  const editorRef = useRef(null)
  const monacoRef = useRef(null)
  const decorationRef = useRef([])

  const handleEditorDidMount: OnMount = (editor: any, monacoInstance: any) => {
    editorRef.current = editor
    monacoRef.current = monacoInstance

    editor.onKeyDown((event: KeyboardEvent) => {
      if (event.key === monacoInstance.KeyCode.Enter) {
        editor.deltaDecorations(decorationRef.current, [])
      }
    })
  }

  const highlightErrorLine = (errorLine: number) => {
    if (!editorRef.current || !monacoRef.current || !errorLine) return

    const editor = editorRef.current as any
    const monacoInstance = monacoRef.current as any

    if (decorationRef.current) {
      editor.deltaDecorations(decorationRef.current, [])
    }

    const highlightDecoration = editor.deltaDecorations(
      [],
      [
        {
          range: new monacoInstance.Range(
            language === "cpp" ? errorLine - 3 : language === "java" ? errorLine - 2 : errorLine,
            1,
            language === "cpp" ? errorLine - 3 : language === "java" ? errorLine - 2 : errorLine,
            1
          ),
          options: {
            isWholeLine: true,
            className: "myLineHighlight",
          },
        },
      ]
    )
    decorationRef.current = highlightDecoration
  }

  useEffect(() => {
    highlightErrorLine(errorLine!)
  }, [errorLine])

  const handleErrorInfo = (info: { lineNumber?: number }) => {
    setErrorLine(info?.lineNumber!)
  }

  useEffect(() => {
    fetchProblemData()
  }, [])

  useEffect(() => {
    if (isTemplateLoaded) {
      handleCodeChange(templates[language], language)
    }
  }, [language, isTemplateLoaded])

  useEffect(() => {
    if (!isEmpty(topic) && typeof isCodeSubmitting === "boolean" && !isCodeSubmitting) {
      fetchSubmissions(topic!)
    }
  }, [isCodeSubmitting, topic])

  useLayoutEffect(() => {
    const launcherFrame = document.querySelector("#launcher-frame") as HTMLElement
    if (launcherFrame) {
      launcherFrame.style.display = "none"
    }
  })

  const ProblemHeader = () => (
    <div className="flex justify-between">
      <div className="space-y-6">
        <div className="flex items-center gap-2 text-new-neutral-light">
          <h3 className="text-new-solid-white">
            {parseInt(question?.question_number) + 1}. {question?.problem_name}
          </h3>
        </div>
        <div className="item-center flex gap-4">
          {question?.tags?.map((tag: string, i: number) => (
            <h5 key={i} className="rounded-sm bg-new-editor-dark-500 px-3 py-1 text-new-neutral-light">
              {capitalize(tag)}
            </h5>
          ))}
        </div>
      </div>
      <div className="flex items-center gap-6 text-new-neutral">
        <Tooltip title={question?.likes}>
          <button className="" onClick={handleLikeProblem}>
            {question?.liked ? <ThumbUpFilledIcon className="h-4 w-4" /> : <ThumbUpIcon className="h-4 w-4" />}
          </button>
        </Tooltip>
        <Tooltip title={question?.dislikes}>
          <button className="" onClick={handleDislikeProblem}>
            {question?.disliked ? <ThumbDownFilledIcon className="h-4 w-4" /> : <ThumbDownIcon className="h-4 w-4" />}
          </button>
        </Tooltip>
      </div>
    </div>
  )

  return (
    <div className="h-screen">
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel defaultSize={40} minSize={20}>
          <div className="editor relative flex h-full w-full flex-col overflow-y-scroll bg-new-editor-dark-700">
            <div className="no-scrollbar sticky top-0 z-20 flex shrink-0 overflow-x-auto">
              {TABS.map((tab, i) => (
                <button
                  key={i}
                  className={cn(
                    "flex-1 px-4 py-2 text-sm font-medium leading-[20px]",
                    TABS[activeTab] === tab
                      ? "bg-new-editor-dark-700 text-new-solid-white"
                      : "bg-new-editor-dark-500 text-new-neutral-light"
                  )}
                  onClick={() => handleTabChange(i)}
                >
                  {tab}
                </button>
              ))}
            </div>
            <div className="space-y-8 p-8">
              {isQuestionLoaded ? (
                <>
                  <ProblemHeader />
                  {TABS[activeTab] === STR_DESCRIPTION ? (
                    <div className="space-y-6 text-new-neutral-light">
                      <div
                        className="text-new-neutral-light"
                        dangerouslySetInnerHTML={{
                          __html: question?.question_details,
                        }}
                      />
                      {question?.input &&
                        question?.input?.map((eachInput: any, i: number) => (
                          <div
                            key={i}
                            className="rounded-sm bg-new-editor-dark-500 p-5 text-new-editor-dark-50"
                            dangerouslySetInnerHTML={{
                              __html: eachInput?.value,
                            }}
                          />
                        ))}
                      {question?.constraints && (
                        <div
                          className="rounded-sm bg-new-editor-dark-500 p-5 text-new-editor-dark-50"
                          dangerouslySetInnerHTML={{
                            __html: question?.constraints,
                          }}
                        />
                      )}
                    </div>
                  ) : TABS[activeTab] === STR_SUBMISSIONS ? (
                    <div className="flex flex-col gap-6 text-new-neutral-light">
                      <div className="flex items-center gap-4 rounded-sm bg-new-editor-dark-500 px-6 py-3">
                        <h4 className="text-new-solid-white">
                          {submissions?.length > 0 ? Math.max(...submissions?.map((s: any) => s?.score)) : 0}/
                          {submissions?.length > 0 ? Math.max(...submissions?.map((s: any) => s?.total_testcase)) : 0}
                        </h4>
                        <p>Your Best score</p>
                      </div>
                      <h4 className="mt-2 text-new-solid-white">Your submissions :</h4>
                      {isAllSubmissionsLoaded ? (
                        <div className="flex flex-col gap-6">
                          {isCodeSubmitting && (
                            <div className="mb-6 flex flex-col items-center gap-2 rounded-sm bg-new-editor-dark-500 px-6 py-3">
                              <Spinner dark small />
                              <h4 className="text-new-solid-white">
                                {submitCodeDetails?.description?.length > 0
                                  ? submitCodeDetails?.description
                                  : "Submitting..."}
                              </h4>
                            </div>
                          )}
                          {!isCodeSubmitting && submissions?.length === 0 && (
                            <div className="flex flex-col items-center gap-2 rounded-sm bg-new-editor-dark-500 px-6 py-6">
                              <h4 className="text-new-editor-dark-300">No submissions yet</h4>
                            </div>
                          )}
                          {submissions?.map((submission: any, i: number) => {
                            const isAccepted =
                              submission?.total_testcase > 0 && submission?.score === submission?.total_testcase

                            const isPartiallyAccepted = !isAccepted && submission?.score > 0

                            const isTimeLimitExceeded = submission?.description === "Time Limit Exceeded"

                            const isError = submission?.total_testcase === 0 && submission?.stderr?.length > 0

                            return (
                              <div
                                key={i}
                                className="space-y-2 rounded-sm bg-new-editor-dark-500 px-6 py-3 text-new-editor-dark-50"
                              >
                                <div className="flex justify-between gap-1 flex-wrap">
                                  <h5>
                                    Language:{" "}
                                    {submission?.language?.length > 0
                                      ? LANGUAGE_LIST?.[submission?.language as keyof typeof LANGUAGE_LIST]
                                      : "Not Specified"}
                                  </h5>
                                  <h5 className="text-new-editor-dark-100">{submission?.time}</h5>
                                </div>
                                {submission?.compile_output?.length > 0 || isError ? (
                                  <SubmissionDisclosure label="Error">
                                    <p>{isError ? submission?.stderr[0] : submission?.compile_output}</p>
                                  </SubmissionDisclosure>
                                ) : (
                                  <div className="space-y-1">
                                    <h4
                                      className={cn(
                                        isAccepted
                                          ? "text-new-success"
                                          : isPartiallyAccepted
                                            ? "text-new-misc-yellow"
                                            : "text-new-failure"
                                      )}
                                    >
                                      {isAccepted
                                        ? "Accepted"
                                        : isPartiallyAccepted
                                          ? "Partially Accepted"
                                          : isTimeLimitExceeded
                                            ? "Time Limit Exceeded"
                                            : "Wrong Answer"}
                                    </h4>
                                    {!isTimeLimitExceeded && (
                                      <p>
                                        Test cases passed {submission?.score}/{submission?.total_testcase}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </div>
                            )
                          })}
                        </div>
                      ) : (
                        <Loader dark />
                      )}
                    </div>
                  ) : (
                    question?.solution && (
                      <div className="space-y-6 text-new-solid-white">
                        {question?.solution?.map((solution: any, i: number) => (
                          <div key={i} className="space-y-2">
                            <h4>{solution?.heading}</h4>
                            <div className="overflow-hidden rounded border border-new-editor-dark-500 bg-new-editor-dark-600">
                              <pre className="editor overflow-auto p-4 text-new-editor-dark-50" key={i}>
                                <code>{solution?.description}</code>
                              </pre>
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </>
              ) : (
                <Loader dark />
              )}
            </div>
          </div>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel defaultSize={60} minSize={20}>
          <div className="editor flex h-full flex-col overflow-y-auto bg-new-editor-dark-700">
            <div className="flex shrink-0 items-center gap-4 p-6">
              <span className="w-fit rounded-sm bg-[#FFA200] px-2 py-px text-sm font-semibold text-new-solid-white">
                BETA
              </span>
              <div className="ml-auto">
                <MainLogo className="h-7" />
              </div>
            </div>
            <div className="flex-1 px-6 pb-6">
              <div
                id="editor-container"
                className="flex h-full flex-col divide-y divide-new-neutral-light rounded-sm border border-new-neutral-dark"
              >
                <div className="flex items-center gap-4 bg-new-editor-dark-600 py-2 px-4">
                  <Button
                    className="ml-auto min-w-[120px] bg-new-editor-dark-200 text-new-neutral-light hover:text-new-solid-white"
                    onClick={() => fetchDefaultTemplateCode("reset")}
                  >
                    Reset Code
                    {resetingTemplate && <Spinner dark xs />}
                  </Button>
                  <select
                    className="w-28 rounded-sm bg-new-editor-dark-700 py-1 px-4 text-sm font-medium leading-normal text-new-neutral-light"
                    value={language}
                    onChange={handleLanguageChange}
                  >
                    {Object.entries(LANGUAGE_LIST).map(([langId, langName]) => (
                      <option key={langId} value={langId}>
                        {langName}
                      </option>
                    ))}
                  </select>
                </div>
                {isTemplateLoaded ? (
                  <div className="h-auto flex-1">
                    <Editor
                      language={language}
                      value={code}
                      onChange={(c) => handleCodeChange(c)}
                      theme="vs-dark"
                      onMount={handleEditorDidMount}
                      options={{
                        minimap: {
                          enabled: false,
                        },
                        contextmenu: false,
                      }}
                    />
                  </div>
                ) : (
                  <div className="flex h-full w-full items-center justify-center">
                    <Loader dark />
                  </div>
                )}
              </div>
            </div>
            {showRunCodeDetails && (
              <RunCodeDetails
                data={runCodeDetails}
                language={language}
                running={isCodeRunning}
                onClose={handleCloseRunCodeDetails}
                onErrorInfo={handleErrorInfo}
              />
            )}
            <div className="sticky bottom-0 mt-auto flex items-center gap-4 border-t border-new-neutral-light bg-new-editor-dark-500 px-6 py-4">
              <div className="ml-auto flex gap-2">
                <Button
                  className="w-[120px] bg-new-gradient text-new-solid-white hover:bg-new-accent hover:bg-none"
                  onClick={() => handleSubmitCode(language, code, topic!)}
                  disabled={isCodeRunning || isCodeSubmitting}
                >
                  Submit
                  {isCodeSubmitting && <Spinner dark xs />}
                </Button>
                <Button
                  className="w-[120px] bg-new-editor-dark-100 text-new-solid-white"
                  onClick={() => {
                    handleRunCode(language, code, topic!)
                  }}
                  disabled={isCodeRunning || isCodeSubmitting}
                >
                  Run
                  {isCodeRunning && <Spinner dark xs />}
                </Button>
              </div>
            </div>
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
