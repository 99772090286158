import React, { useState } from "react"
import { GetMonthlyFeedback } from "domain/useCase/Student/MonthlyFeedback/GetMonthlyFeedback"
import { MonthlyFeedbackRepositoryImpl } from "data/repository/Student/MonthlyFeedbackRepositoryImpl"
import { format } from "date-fns"
import { getAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import MonthlyFeedbackAPIDataSourceImpl from "data/API/Student/MonthlyFeedbackAPIDataSourceImpl"
import { useAuth } from "core/context/auth"

export default function FeedbackViewModel() {
  const { auth } = useAuth()
  const [monthlyFeedback, setMonthlyFeedback] = React.useState<any>([])
  const [allMonthFeedback, setAllMonthFeedback] = React.useState<any>([])
  const [data, setData] = React.useState({
    header: [],
    rows: [],
  })
  const [searchByEmail, setSearchByEmail] = React.useState("")
  const [studentBatch, setStudentBatch] = React.useState("")
  const [month, setMonth] = React.useState("")
  const [batch, setBatch] = React.useState([])
  const [range, setRange] = useState({
    start_index: 0,
    end_index: 100,
  })
  const [loadingNext, setLoadingNext] = React.useState(false)
  const [average, setAverage] = React.useState<number>(0)
  const [median, setMedian] = React.useState<number>(0)

  const GetMonthlyFeedbackUseCase = new GetMonthlyFeedback(
    new MonthlyFeedbackRepositoryImpl(new MonthlyFeedbackAPIDataSourceImpl())
  )

  const getAllBatchesUseCase = new getAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (response.success) {
      setBatch(response.data)
    }
  }

  function handleBatchChange(e: any) {
    setStudentBatch(e.target.value)
  }

  async function fetchAllFeedback() {
    const range = {
      start_index: 0,
      end_index: 100,
    }
    const response = await GetMonthlyFeedbackUseCase.invoke(auth, range)
    if (!response?.success) {
      return
    }
    setMonthlyFeedback(response?.data?.feedback_list)
    setAverage(response?.data?.average_rating)
    setMedian(response?.data?.median_rating)
    setAllMonthFeedback(response?.data?.feedback_list)
  }

  async function handleNext() {
    const newRange = {
      start_index: range.end_index + 1,
      end_index: range.end_index + 10,
    }
    setLoadingNext(true)
    setRange(newRange)
    const response = await GetMonthlyFeedbackUseCase.invoke(auth, newRange)
    setLoadingNext(false)
    if (!response?.success) {
      return
    }
    setMonthlyFeedback([...monthlyFeedback, ...response?.data?.feedback_list])
    allMonthFeedback([...allMonthFeedback, ...response?.data?.feedback_list])
  }

  function HandleTableData() {
    const header: any = ["Name", "Email", "Batch", "Rate", "Reviews", "Suggestion", "Month", "Year"]
    let rows: any = []

    rows = monthlyFeedback?.map((f: any) => [
      f["name"],
      f["email"],
      f["batch"],
      <div>{f["1"]}</div>,
      <div className="h-auto w-[200px] whitespace-normal">{f["2"]}</div>,
      <div className="h-auto w-[200px] whitespace-normal">{f["3"]}</div>,
      format(parseInt(f["submit_timestamp"]) * 1000, "MMMM"),
      format(parseInt(f["submit_timestamp"]) * 1000, "y"),
    ])

    setData({ header, rows })
  }

  function handleChange(e: any) {
    if (e.target.value) setSearchByEmail(e.target.value)
    else setSearchByEmail("")
  }

  function handleMonthChange(e: any) {
    if (e.target.value) setMonth(e.target.value)
    else setMonth("")
  }

  function filterByEmail(data: any) {
    let newMonthlyFeedback: any = []
    data?.forEach((f: any) => {
      if (f["email"].includes(searchByEmail)) {
        newMonthlyFeedback = [...newMonthlyFeedback, f]
      }
    })
    return newMonthlyFeedback
  }

  function filterByBatch(data: any) {
    let newMonthlyFeedback: any = []
    data?.forEach((f: any) => {
      if (f["batch"].includes(studentBatch)) {
        newMonthlyFeedback = [...newMonthlyFeedback, f]
      }
    })
    return newMonthlyFeedback
  }

  function filterByMonth(data: any) {
    let newMonthlyFeedback: any = []
    data?.forEach((f: any) => {
      if (format(parseInt(f["submit_timestamp"]) * 1000, "MMMM") === month) {
        newMonthlyFeedback = [...newMonthlyFeedback, f]
      }
    })
    return newMonthlyFeedback
  }

  function downloadMonthlyFeedback(data: any = monthlyFeedback) {
    let rows: any[] = [["name", "email", "batch", "rate", "reviews", "improvements", "month", "year"]]
    data.forEach((f: any) => {
      rows = [
        ...rows,
        [
          f["name"],
          f["email"],
          f["batch"],
          f["1"],
          `"${f["2"].replace(/[\r\n\t]/g, " ")}"`,
          `"${f["3"].replace(/[\r\n\t]/g, " ")}"`,
          format(parseInt(f["submit_timestamp"]) * 1000, "MMMM"),
          format(parseInt(f["submit_timestamp"]) * 1000, "y"),
        ],
      ]
    })
    let csvContent = "data:text/csv;charset=utf-8,"
    rows.forEach(function (rowArray) {
      const row = rowArray.join(",")
      csvContent += row + "\r\n"
    })

    const encodedUri = encodeURI(csvContent)
    const link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", `Feedback.csv`)
    document.body.appendChild(link)
    link.click()
  }

  return {
    monthlyFeedback,
    fetchAllFeedback,
    setMonthlyFeedback,
    HandleTableData,
    data,
    filterByEmail,
    searchByEmail,
    handleChange,
    studentBatch,
    filterByBatch,
    downloadMonthlyFeedback,
    handleMonthChange,
    month,
    filterByMonth,
    allMonthFeedback,
    handleBatchChange,
    batch,
    getBatches,
    handleNext,
    loadingNext,
    average,
    median,
  }
}
