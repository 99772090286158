import { API_V4_SIGNUP } from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import { UserSignUp } from "domain/model/UserSignup"
import UserSignUpDataSource from "data/dataSource/Admin/UserSignUpDataSourse"

export class UserSignUpAPIDataSourceImpl implements UserSignUpDataSource {
  async signup(userSignUp: UserSignUp): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_SIGNUP), {
        course: userSignUp.courseType,
        batch: userSignUp.batch,
        onboardingManagerId: userSignUp.onboardingManagerId,
        milestoneSessionMentor: userSignUp.milestoneSessionMentor,
        role: userSignUp.role,
        email: userSignUp.email,
        name: userSignUp.fullName,
        phone: userSignUp.phoneNumber,
        country_code: userSignUp.countryCode,
        country: userSignUp.country,
        state: userSignUp.state,
        experience: userSignUp.yearsOfExperience,
        fees: userSignUp.fees,
        walk_through_date: userSignUp.walkThroughDate,
        isAlumni: userSignUp.isAlumni,
        isMentor: userSignUp.isMentor,
        bandwidth: userSignUp.bandwidth,
        standard_discount: userSignUp.standardDiscount,
        seat_booking_amount: userSignUp.seat_booking_amount,
        paymentId_of_seat_booking: userSignUp.paymentId_of_seat_booking,
        seat_booking_received_date: userSignUp.seat_booking_received_date,
        payment_submethod: userSignUp.payment_submethod,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
