import { Auth } from "domain/model/Auth"
import { MentorRepository } from "domain/repository/Student/MentorRepository"

export interface CancelMentorSessionUseCase {
  invoke(auth: Auth, session_id: string, type: string): Promise<any>
}

export class CancelMentorSession implements CancelMentorSessionUseCase {
  private repository: MentorRepository

  constructor(repository: MentorRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, session_id: string, type: string): Promise<any> {
    return this.repository.cancelMentorSession(auth, session_id, type)
  }
}
