import MonthlyFeedbackDataSource from "data/dataSource/Student/MonthlyFeedbackDataSource"
import { Auth } from "domain/model/Auth"
import MonthlyFeedbackRepository from "domain/repository/Student/MonthlyFeedbackRepository"

export class MonthlyFeedbackRepositoryImpl implements MonthlyFeedbackRepository {
  private dataSource: MonthlyFeedbackDataSource

  constructor(dataSource: MonthlyFeedbackDataSource) {
    this.dataSource = dataSource
  }

  async submitMonthlyFeedback(auth: Auth, data: any): Promise<any> {
    return this.dataSource.submitMonthlyFeedback(auth, data)
  }

  async checkMonthlyFeedback(auth: Auth): Promise<any> {
    return this.dataSource.checkMonthlyFeedback(auth)
  }

  async getMonthlyFeedback(auth: Auth, data: any): Promise<any> {
    return this.dataSource.getMonthlyFeedback(auth, data)
  }
}
