// import { MoreIcon } from 'core/constants/svgs';
import Button from "core/components/new/Button"
import selectionImg from "assets/images/selection.png"
import Flag from "core/components/Flag"

export default function MentorBeginSelectionCard({ onClick }: any) {
  return (
    <div className="flex gap-8 rounded bg-new-solid-red-light p-8">
      <div className="grid h-[120px] w-[120px] shrink-0 place-items-center rounded-sm bg-new-solid-white shadow-[0px_4px_8px_rgba(232,108,108,0.15)]">
        <img className="h-20 w-full object-contain" src={selectionImg} alt="contest" />
      </div>
      <div className="flex w-full flex-col justify-between">
        <div className="flex w-full justify-between gap-4">
          <div className="flex flex-col gap-1">
            <Flag text="Mandatory" />
            <h3 className="text-new-accent">Complete mentor selection process</h3>
          </div>
          <h3 className="text-new-failure">5 min</h3>
        </div>
        <div className="flex items-end justify-between">
          <p className="max-w-sm text-new-neutral-dark">
            You have not selected your mentor yet, complete the remaining steps before your batch starts.
          </p>
          <Button className="w-fit whitespace-nowrap" onClick={onClick}>
            Continue Process
          </Button>
        </div>
      </div>
    </div>
  )
}
