import TableButton from "core/components/v2/TableButton"
import CopyIcon from "presentation/Student/ReferandEarn/components/CopyIcon"

export default function ReferralListCard(props: any) {
  const { candidate, handleCopy, handleViewReward, empty } = props

  if (empty) {
    return (
      <div className="w-[154px] shrink-0 h-[185px] p-3 bg-[#F9F9F9] flex flex-col shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)]"></div>
    )
  }

  const studentNameParts = candidate?.studentName?.split(" ") || []
  const firstName = studentNameParts[0] || ""
  const lastName = studentNameParts[1] || ""

  return (
    <>
      {/* <div>firstname is :{firstName}</div> */}
      <div className="w-[154px] h-[185px] p-3 border rounded-lg shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)] bg-white flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <div className="w-[102px] h-[34px] text-sm text-[#333333] font-semibold truncate leading-[normal]">
            {firstName} <br /> {lastName}
          </div>
          <div
            className="icon-holder w-6 h-6 rounded flex items-center justify-center hover:bg-[#cdcdcd]"
            onClick={() => handleCopy(candidate?.studentEmail, "Email")}
          >
            <CopyIcon />
          </div>
        </div>
        <div className="mb-2">
          <div className="flex items-center">
            <input
              type="checkbox"
              className="mr-1 checked:bg-[#07B42D]"
              checked={candidate?.studentEnrolled}
              readOnly
            />
            <span className="text-xs">Student Enrolled</span>
          </div>
        </div>
        <div className="mb-2">
          <div className="flex">
            <input
              type="checkbox"
              className="mr-1 checked:bg-[#07B42D]"
              checked={candidate?.studentFinalized}
              readOnly
            />
            <span className="text-xs">Enrolment Done</span>
          </div>
        </div>
        <div className="mt-auto justify-items-center">
          <TableButton
            outlined
            disabled={!candidate?.studentFinalized || !candidate?.studentEnrolled}
            onClick={() => {
              handleViewReward(candidate)
            }}
          >
            View Reward
          </TableButton>
        </div>
      </div>
    </>
  )
}
