import Toast from "core/components/Toast"
import useToast from "core/hooks/useToast"
import referral_image from "assets/images/illustration.svg"
import { Button } from "core/components/v2/Button"
import { AddIcon } from "core/constants/svgs"
import useLocalStorage from "core/hooks/useLocalStorage"
export default function ReferAndEarnBasicInfo({ setIsModalOpen }: { setIsModalOpen: (bool: boolean) => void }) {
  const { toast, changeToastVisibility } = useToast()
  const [student] = useLocalStorage<any>("student", {} as any)
  return (
    <div className="flex justify-between space-y-6">
      <div className="w-[100%]">
        <div className="relative w-[100%]">
          <img src={referral_image} alt="refer and earn" className="w-[100%]" />
        </div>
        <div className="text-[#FBBF24] text-lg pt-6 pb-2 font-semibold">Refer And Earn</div>
        <div className="space-y-4">
          <div className="text-sm ">
            Refer your friends to any course at Bosscoder and get chance to win exciting cash rewards and Goodies
          </div>
          <div className="text-sm">
            For each successful referral you get a cashback of Rs. {student?.batch?.includes("AC2") ? 2000 : 3000} &
            your friend gets a cashback of Rs. {student?.batch?.includes("AC2") ? 2000 : 3000}.
          </div>
          <div className="text-sm">Ohh wait...! there are more, Invite your friends and get amazing...REWARDS!</div>
          <div className="flex flex-row justify-between p-4 bg-[#F9F9F9] gap-10 rounded-[8px]">
            <div className="flex flex-col ">
              <div className="font-inter text-base font-semibold leading-normal text-[#333]">
                Want to refer someone?
              </div>
              <div className="text-[#525252] font-inter text-xs font-medium leading-normal">
                Click on the Refer button to proceed further.
              </div>
            </div>
            <div className="flex justify-center items-center">
              <Button
                onClick={() => setIsModalOpen(true)}
                className="flex-row rounded-[4px] bg-new-accent text-white hover:bg-[#03249A]"
              >
                <div className="flex justify-center items-center">
                  <AddIcon className="h-4 items-center" />
                  Refer
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
