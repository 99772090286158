import NotificationAlert from "assets/svgs/HomePage/NotificationAlert"
import React from "react"

const NotificationToast = ({ type, icon, time, description, color }: any) => {
  return (
    <div className="flex w-full rounded-lg border-[0.5px] border-[#d3d3d3] bg-white shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)]">
      <div className="h-full w-[8px] rounded-tl-lg rounded-bl-lg" style={{ backgroundColor: color }}></div>
      <div className="w-full p-4">
        <div className="flex justify-between">
          <div className="flex items-center space-x-2">
            {/* {icon} */}
            <NotificationAlert color={color} />
            <p className="text-base font-medium" style={{ color: color }}>
              {type}
            </p>
          </div>
          {time && <p className="text-xs font-semibold text-[#dc2626]">{time}</p>}
        </div>
        <p className="text-sm font-normal">
          {description}
          {/* Complete your onboarding meeting for better understanding of your
          portal. */}
        </p>
      </div>
    </div>
  )
}

export default NotificationToast
