import React from "react"
import FeedbackViewModel from "./FeedbackViewModel"
import Table from "core/components/new/Table"
import { isEmpty } from "core/utils/misc"
import DashboardLayout from "core/layouts/DashboardLayout"
import Input from "core/components/new/Input"
import Button from "core/components/new/Button"
import Loader from "core/components/Loader"
import StandaredSelect from "core/components/StandaredSelect"

export default function FeedbackTable() {
  const {
    monthlyFeedback,
    fetchAllFeedback,
    HandleTableData,
    data,
    filterByEmail,
    searchByEmail,
    handleChange,
    downloadMonthlyFeedback,
    studentBatch,
    filterByBatch,
    handleMonthChange,
    month,
    filterByMonth,
    allMonthFeedback,
    batch,
    getBatches,
    handleBatchChange,
    handleNext,
    loadingNext,
    median,
    average,
    setMonthlyFeedback,
  } = FeedbackViewModel()

  React.useEffect(() => {
    fetchAllFeedback()
  }, [])

  React.useEffect(() => {
    HandleTableData()
  }, [monthlyFeedback])

  React.useEffect(() => {
    getBatches()
  }, [])

  React.useEffect(() => {
    let result = allMonthFeedback
    result = filterByEmail(result)
    result = filterByBatch(result)
    result = filterByMonth(result)
    setMonthlyFeedback(result)
  }, [searchByEmail, studentBatch, month])

  const options = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  return (
    <DashboardLayout>
      <div className="flex flex-col gap-6 p-8">
        <div className="flex items-center gap-6">
          <h3>Average: {Math.round(average * 100) / 100}</h3>
          <h3 className="">Median: {median}</h3>
        </div>
        <div className="flex items-end justify-between">
          <Input
            id="name"
            type="text"
            placeholder="Search by Email"
            label="Email"
            value={searchByEmail}
            onChange={handleChange}
          />
          <div className="flex  flex-col gap-2">
            <h3 className="text-new-neutral-dark">Batches</h3>
            <StandaredSelect
              className="w-[200px]"
              required
              options={batch}
              placeholder="Select Batch"
              onChange={handleBatchChange}
              value={studentBatch}
            />
          </div>

          <div className="flex flex-col gap-2 ">
            <label htmlFor="Month">
              <h3 className="text-new-neutral-dark">Month</h3>
            </label>
            <select id="Month" className=" text-sm leading-[17px]" value={month} onChange={handleMonthChange}>
              <option value={""}>please choose month</option>
              {options.map((option, index) => {
                return (
                  <option key={index} value={option}>
                    {option}
                  </option>
                )
              })}
            </select>
          </div>
          <Button small className="h-fit w-1/4" onClick={() => downloadMonthlyFeedback(allMonthFeedback)}>
            Download All
          </Button>
        </div>
        {!isEmpty(allMonthFeedback) ? <Table data={data} /> : <Loader />}
        {!isEmpty(allMonthFeedback) && (
          <Button onClick={handleNext} loading={loadingNext}>
            Next
          </Button>
        )}
      </div>
    </DashboardLayout>
  )
}
