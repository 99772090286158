import React from "react"
import { MentorDashboardAPIDataSourceImpl } from "data/API/Admin/MentorDashboardAPIDataSourceImpl"
import { MentorDashboardRepositoryImpl } from "data/repository/Admin/MentorDashboardRepositoryImpl"
import { GetMentorsDetails } from "domain/useCase/Admin/MentorDashboard/GetAllMentorDetails"
import { EnableInstructors } from "domain/useCase/Admin/MentorDashboard/EnableInstructor"
import { DisableMentors } from "domain/useCase/Admin/MentorDashboard/DisableMentor"
import { useAuth } from "core/context/auth"
import { ToggleGoalSettingMentor } from "domain/useCase/Admin/MentorDashboard/ToggleGoalSettingMentor"
import { Auth } from "domain/model/Auth"

export default function MentorDashboardViewModel() {
  const [mentorData, setMentorData] = React.useState<any>([])
  const [searchByEmail, setSearchByEmail] = React.useState<string>("")

  const { auth } = useAuth()

  const GetAllMentorsDetailsUseCase = new GetMentorsDetails(
    new MentorDashboardRepositoryImpl(new MentorDashboardAPIDataSourceImpl())
  )

  const assignInstructorUseCase = new EnableInstructors(
    new MentorDashboardRepositoryImpl(new MentorDashboardAPIDataSourceImpl())
  )

  const disableMentorUseCase = new DisableMentors(
    new MentorDashboardRepositoryImpl(new MentorDashboardAPIDataSourceImpl())
  )

  const toggleGoalSettingMentorUseCase = new ToggleGoalSettingMentor(
    new MentorDashboardRepositoryImpl(new MentorDashboardAPIDataSourceImpl())
  )

  async function getMentorsData() {
    const response = await GetAllMentorsDetailsUseCase.invoke({
      admin_uid: auth.local_id,
    })

    if (response) {
      const filteredMentorData = response.response.mentors_list.filter((mentor: any) => mentor.bandwidth !== undefined)
      const isMentorTrueArray = filteredMentorData.filter((mentor: any) => mentor.is_mentor === true)
      const isMentorFalseArray = filteredMentorData.filter((mentor: any) => mentor.is_mentor === false)
      const isMentorAbsentArray = filteredMentorData.filter((mentor: any) => mentor.is_mentor === undefined)
      isMentorTrueArray.sort((a: any, b: any) => a.bandwidth - b.bandwidth)
      isMentorFalseArray.sort((a: any, b: any) => a.bandwidth - b.bandwidth)
      isMentorAbsentArray.sort((a: any, b: any) => a.bandwidth - b.bandwidth)
      const sortedMentorData = isMentorTrueArray.concat(isMentorFalseArray, isMentorAbsentArray)
      console.log("ye hai sorted data", sortedMentorData)
      // Set the sorted and filtered data using setMentorData
      setMentorData(sortedMentorData)
    }
  }

  async function isInstructor(id: any, status: any) {
    const response = await assignInstructorUseCase.invoke({
      email: id,
      is_instructor: status,
    })

    return response
  }

  async function toggleGoalSettingMentor(id: any) {
    const response = await toggleGoalSettingMentorUseCase.invoke(auth, id)

    return response
  }

  async function disbaleMentor(id: string, status: any) {
    const response = await disableMentorUseCase.invoke({
      email: id,
      disable_user: status,
    })
    return response
  }

  function handleSearchByEmail(e: any) {
    if (e.target.value) setSearchByEmail(e.target.value)
    else setSearchByEmail("")
  }

  function searchingforEmail(email: string | null | undefined) {
    return function (obj: any) {
      return !email || obj.email.toLowerCase().includes(email.toLowerCase())
    }
  }

  function handleInstructor(id: any) {
    const ind = mentorData.findIndex((mentor: any) => mentor.email === id)
    const togglestate = !mentorData[ind]?.is_instructor
    isInstructor(id, togglestate)
    const updatedMentorsList = [...mentorData]
    updatedMentorsList[ind].is_instructor = togglestate
    setMentorData(updatedMentorsList)
  }

  function handleDisableMentor(id: any) {
    const ind = mentorData.findIndex((mentor: any) => mentor.email === id)
    const togglestate = !mentorData[ind]?.disabled
    disbaleMentor(id, togglestate)
    const updatedMentorsList = [...mentorData]
    updatedMentorsList[ind].disabled = togglestate
    setMentorData(updatedMentorsList)
  }

  return {
    mentorData,
    searchByEmail,
    getMentorsData,
    handleSearchByEmail,
    searchingforEmail,
    handleInstructor,
    handleDisableMentor,
    toggleGoalSettingMentor,
  }
}
