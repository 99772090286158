import { Auth } from "domain/model/Auth"
import MentorDataSource from "data/dataSource/Student/MentorDataSource"
import {
  API_V4_STUDENT,
  API_V4_ADMIN,
  STR_ADD_STUDENT_REVIEW,
  STR_BOOK_MENTOR_SESSION,
  STR_GET_BOOKED_SESSIONS,
  STR_GET_MENTOR_AVAILABILITY,
  STR_GET_MENTOR_DETAILS,
  STR_UPDATE_MENTOR_PRIORITY,
  STR_GET_ALL_MENTOR_SESSIONS,
  STR_CANCEL_MENTOR_SESSION,
  STR_GET_MENTOR_SESSION_LINK_STUDENT,
  STR_MARK_MENTOR_ABSENT,
  STR_GET_MENTOR_SESSIONS_BY_STUDENT,
  STR_GET_MENTOR_DETAILS_BY_STUDENT,
  STR_GET_MENTOR_SESSION_DETAILS,
  STUDENT,
  MENTOR_DETAILS,
} from "core/constants/strings"
import { postAPI, Server } from "core/utils/axios"
import pn from "core/utils/pn"

const server = new Server()
export class MentorAPIDataSourceImpl implements MentorDataSource {
  async getAllMentors(auth: Auth, data: any) {
    try {
      const response = await server.get(pn(STUDENT, MENTOR_DETAILS), {
        Authorization: `Bearer ${auth?.id_token}`,
      })
      return response
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }

  async updateMentor(auth: Auth, emails: string[3]) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_UPDATE_MENTOR_PRIORITY), {
        id_token: auth.id_token,
        mentor_priority: emails,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getMentorAvailability(auth: Auth, isMilestoneSession: boolean, data: any) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_GET_MENTOR_AVAILABILITY), {
        id_token: auth.id_token,
        is_milestone_session: isMilestoneSession,
        data: data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getBookedSessions(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_GET_BOOKED_SESSIONS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getAllMentorSessions(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_GET_ALL_MENTOR_SESSIONS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async bookMentorSession(auth: Auth, date: string, start: string, end: string, session_id: string) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_BOOK_MENTOR_SESSION), {
        id_token: auth.id_token,
        date: date,
        start: start,
        end: end,
        session_id: session_id,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async cancelMentorSession(auth: Auth, session_id: string, type: string) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_CANCEL_MENTOR_SESSION), {
        id_token: auth.id_token,
        session_id: session_id,
        type: type,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async addSessionReview(auth: Auth, rating: number, comment: string, session_id: string) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_ADD_STUDENT_REVIEW), {
        id_token: auth.id_token,
        rating: rating,
        comment: comment,
        session_id: session_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getMentorSessionLinkStudent(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_GET_MENTOR_SESSION_LINK_STUDENT), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async markMentorAbsent(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_MARK_MENTOR_ABSENT), {
        id_token: auth.id_token,
        data,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getMentorSessionsByStudent(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, STR_GET_MENTOR_SESSIONS_BY_STUDENT), {
        id_token: auth.id_token,
        data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getMentorDetailsByStudent(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_GET_MENTOR_DETAILS_BY_STUDENT), {
        id_token: auth.id_token,
        data,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getMentorSessionDetails(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_GET_MENTOR_SESSION_DETAILS), {
        id_token: auth.id_token,
        data,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
