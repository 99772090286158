import { Auth } from "domain/model/Auth"
import MentorDataSource from "data/dataSource/Student/MentorDataSource"
import { MentorRepository } from "domain/repository/Student/MentorRepository"

export class MentorRepositoryImpl implements MentorRepository {
  private dataSource: MentorDataSource

  constructor(dataSource: MentorDataSource) {
    this.dataSource = dataSource
  }

  async getAllMentors(auth: Auth, data: any): Promise<any> {
    return this.dataSource.getAllMentors(auth, data)
  }

  async updateMentor(auth: Auth, emails: string[3]): Promise<any> {
    return this.dataSource.updateMentor(auth, emails)
  }

  async getMentorAvailability(auth: Auth, isMilestoneSession: boolean, data: any): Promise<any> {
    return this.dataSource.getMentorAvailability(auth, isMilestoneSession, data)
  }

  async getBookedSessions(auth: Auth): Promise<any> {
    return this.dataSource.getBookedSessions(auth)
  }

  async getAllMentorSessions(auth: Auth): Promise<any> {
    return this.dataSource.getAllMentorSessions(auth)
  }

  async bookMentorSession(auth: Auth, date: string, start: string, end: string, session_id: string): Promise<any> {
    return this.dataSource.bookMentorSession(auth, date, start, end, session_id)
  }

  async cancelMentorSession(auth: Auth, session_id: string, type: string): Promise<any> {
    return this.dataSource.cancelMentorSession(auth, session_id, type)
  }

  async addSessionReview(auth: Auth, rating: number, comment: string, session_id: string): Promise<any> {
    return this.dataSource.addSessionReview(auth, rating, comment, session_id)
  }
  async getMentorSessionLinkStudent(auth: Auth): Promise<any> {
    return this.dataSource.getMentorSessionLinkStudent(auth)
  }
  async markMentorAbsent(auth: Auth, data: any): Promise<any> {
    return this.dataSource.markMentorAbsent(auth, data)
  }
  async getMentorSessionsByStudent(auth: Auth, data: any): Promise<any> {
    return this.dataSource.getMentorSessionsByStudent(auth, data)
  }
  async getMentorDetailsByStudent(auth: Auth, data: any): Promise<any> {
    return this.dataSource.getMentorDetailsByStudent(auth, data)
  }
  async getMentorSessionDetails(auth: Auth, data: any): Promise<any> {
    return this.dataSource.getMentorSessionDetails(auth, data)
  }
}
