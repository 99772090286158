import React, { useState } from "react"
import Tags from "core/components/v2/Tags"
import ReferralListCard from "./ReferralListCard"

export default function ReferralList(props: any) {
  const { myreferredCandidates, handleCopy, referredByActive, candidate, setReferredByActive, handleViewReward } = props

  const handleTagClick = () => {
    setReferredByActive(!referredByActive)
  }

  return (
    <div className="pt-[24px]">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-lg font-semibold">Your Earnings</h1>
        <div className="flex space-x-2">
          <Tags selected={referredByActive} onClick={handleTagClick} disabled={referredByActive}>
            Referral
          </Tags>
          <Tags selected={!referredByActive} onClick={handleTagClick} disabled={!referredByActive}>
            Referred By
          </Tags>
        </div>
      </div>
      <div className="flex overflow-x-auto hide-scrollbar space-x-4 bg-white border rounded-lg pl-4 pr-0 pt-4 pb-4 shadow-light">
        {referredByActive ? (
          <>
            {/* {myreferredCandidates} */}
            {myreferredCandidates?.map((candidate: any, id: number) => (
              <ReferralListCard
                key={id}
                candidate={candidate}
                handleCopy={handleCopy}
                handleViewReward={handleViewReward}
              />
            ))}
            {myreferredCandidates?.length < 5 &&
              [...Array(5 - myreferredCandidates?.length)].map((_, id) => (
                <ReferralListCard
                  key={id}
                  candidate={{}}
                  handleCopy={handleCopy}
                  handleViewReward={handleViewReward}
                  empty
                />
              ))}
          </>
        ) : (
          <>
            {candidate.studentName !== "" && (
              <ReferralListCard candidate={candidate} handleCopy={handleCopy} handleViewReward={handleViewReward} />
            )}
            {[...Array(4)].map((_, id) => (
              <ReferralListCard
                key={id}
                candidate={{}}
                handleCopy={handleCopy}
                handleViewReward={handleViewReward}
                empty
              />
            ))}
          </>
        )}
      </div>
    </div>
  )
}
