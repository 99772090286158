import React from "react"

export default function Feedback9(props: any) {
  const { selectedColor } = props
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="32" fill={selectedColor} />
      <circle cx="23.1503" cy="25.8652" r="3.40426" fill="white" />
      <circle cx="41.1934" cy="25.8652" r="3.40426" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1881 41.3037C19.5007 40.6197 20.3087 40.3187 20.9926 40.6314L25.1196 42.518C29.6761 44.601 34.9276 44.5257 39.4225 42.3128L42.8038 40.6482C43.4785 40.316 44.2947 40.5937 44.6269 41.2684C44.9591 41.9431 44.6814 42.7594 44.0067 43.0915L40.6254 44.7562C35.3966 47.3303 29.2878 47.418 23.9873 44.9949L19.8603 43.1083C19.1764 42.7956 18.8754 41.9877 19.1881 41.3037Z"
        fill="white"
      />
    </svg>
  )
}
