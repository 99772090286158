import {
  API_ADD_EXTRA_MENTOR_SESSION,
  API_V4_MENTOR,
  STR_ADD_MENTOR_FEEDBACK,
  STR_GET_MENTEE_MENTOR_SESSION,
  STR_GET_MENTOR_MENTEES,
  STR_GET_MENTOR_SESSIONS_BY_STUDENT_MENTOR,
  STR_ADD_MENTOR_FEEDBACK_NEW,
  STR_EDIT_MENTOR_TEMPLATE_FOR_STUDENT,
  STR_MARK_MENTEE_ABSENT,
  STR_CANCEL_MENTOR_SESSION_BY_MENTOR,
  STR_GET_MENTOR_SESSION_DETAILS_MENTOR,
  API_V4_CANCEL_MENTOR_SESSION_BY_MENTOR_OLD,
} from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import { MyMenteeDataSource } from "data/dataSource/Mentor/MyMenteeDataSource"
import { Auth } from "domain/model/Auth"
import { getMenteeDetail, mentorFeedback } from "domain/model/MyMentee"

export default class MyMenteeAPIDataSourceImpl implements MyMenteeDataSource {
  async getMentorMentee(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_MENTOR, STR_GET_MENTOR_MENTEES), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getMenteeMentorSessions(details: getMenteeDetail): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_MENTOR, STR_GET_MENTEE_MENTOR_SESSION), details)
      return response
    } catch (error) {
      return error
    }
  }

  async addMentorFeedback(feedback: mentorFeedback): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_MENTOR, STR_ADD_MENTOR_FEEDBACK), feedback)
      return response
    } catch (error) {
      return error
    }
  }

  async getMentorSessionsByStudentMentor(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_MENTOR, STR_GET_MENTOR_SESSIONS_BY_STUDENT_MENTOR), {
        id_token: auth.id_token,
        data,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async addMentorFeedbackNew(auth: Auth, rating: any, feedback: any, action_items: any, id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_MENTOR, STR_ADD_MENTOR_FEEDBACK_NEW), {
        id_token: auth.id_token,
        rating,
        feedback,
        action_items,
        id,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async markMenteeAbsent(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_MENTOR, STR_MARK_MENTEE_ABSENT), {
        id_token: auth.id_token,
        data,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async cancelMentorSessionByMentor(auth: Auth, session_id: any, type: any, student_id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_MENTOR, STR_CANCEL_MENTOR_SESSION_BY_MENTOR), {
        id_token: auth.id_token,
        session_id,
        type,
        student_id,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getMentorSessionDetails(auth: Auth, data: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_MENTOR, STR_GET_MENTOR_SESSION_DETAILS_MENTOR), {
        id_token: auth.id_token,
        data,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async allowExtraMentorSession(auth: Auth, email: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_MENTOR, API_ADD_EXTRA_MENTOR_SESSION), {
        id_token: auth.id_token,
        email,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async cancelMentorSessionByMentorOld(auth: Auth, date: any, start: any, end: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_MENTOR, API_V4_CANCEL_MENTOR_SESSION_BY_MENTOR_OLD), {
        id_token: auth.id_token,
        date,
        start,
        end,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
