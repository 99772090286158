import React from "react"

const HomePageGradient = ({ className }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="289" height="115" viewBox="0 0 289 115" fill="none">
      <g style={{ mixBlendMode: "multiply" }}>
        <path d="M140.5 0H288.5L148 151H0L140.5 0Z" fill="url(#paint0_linear_999_807)" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_999_807"
          x1="276.5"
          y1="-111.5"
          x2="89"
          y2="151"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={className} />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default HomePageGradient
