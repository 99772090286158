import SearchInput from "core/components/SearchInput"
import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import MentorDashboardTable from "./components/MentorDashboardTable"
import useMentorDashboardViewModel from "./MentorDashboardViewModel"

export default function MentorDashboardView() {
  const {
    mentorData,
    searchByEmail,
    getMentorsData,
    handleSearchByEmail,
    searchingforEmail,
    handleInstructor,
    handleDisableMentor,
    toggleGoalSettingMentor,
  } = useMentorDashboardViewModel()

  const tableHeaders = [
    "",
    "General Info",
    "Bandwidth",
    "Contact",
    "Current Company",
    "Experience",
    "Instructor",
    "Disable",
    "Goal Setting Mentor",
    "Edit",
  ]

  React.useEffect(() => {
    getMentorsData()
  }, [])

  return (
    <DashboardLayout>
      <div className="mt-[40px] mb-8 flex flex-row">
        <SearchInput
          className="ml-[24px] h-[40px] w-[292px] rounded-[8px]"
          placeholder="Search by Email !"
          value={searchByEmail}
          onChange={handleSearchByEmail}
        />
      </div>

      <MentorDashboardTable
        tableHeaders={tableHeaders}
        mentorData={mentorData}
        searchByEmail={searchByEmail}
        searchingforEmail={searchingforEmail}
        handleInstructor={handleInstructor}
        handleDisableMentor={handleDisableMentor}
        handleDisableGoalSettingMentor={toggleGoalSettingMentor}
      />
    </DashboardLayout>
  )
}
