import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import { Auth } from "domain/model/Auth"
import { GetBatchTemplateGeneric } from "domain/useCase/Admin/NewMentorSessions/GetBatchTemplateGeneric"
import { NewMentorSessionsRepositoryImpl } from "data/repository/Admin/NewMentorSessionsRepositoryImpl"
import NewMentorSessionsAPIDataSourceImpl from "data/API/Admin/NewMentorSessionsAPIDataSourceImpl"
import { useAuth } from "core/context/auth"
import Input from "core/components/Input"
import Button from "core/components/new/Button"
import { UpdateBatchTemplateGeneric } from "domain/useCase/Admin/NewMentorSessions/UpdateBatchTemplateGeneric"
import { GetMentorSessionsByStudent } from "domain/useCase/Student/Mentor/GetMentorSessionsByStudent"
import { MentorRepositoryImpl } from "data/repository/Student/MentorRepositoryImpl"
import { MentorAPIDataSourceImpl } from "data/API/Student/MentorAPIDataSourceImpl"
import { GetAllStudentData } from "domain/useCase/Admin/StudentDashboard/GetUserData"
import { StudentDashboardRepositoryImpl } from "data/repository/Admin/StudentDashboardRepositoryImpl"
import { StudentDashboardAPIDataSourceImpl } from "data/API/Admin/StudentDashboardAPIDataSourceImpl"
import StudentCard from "../StudentDashboard/components/StudentCard"
import MentorSessionTable from "../../Student/Mentor/components/MentorSessionTable"
import { format } from "date-fns"
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"
import useMentorViewModel from "../../Student/Mentor/MentorViewModel"
import { isEmpty } from "core/utils/misc"
// import { formatDate } from "core/utils/date";
import Menu from "assets/svgs/Menu"
import EditIcon from "assets/svgs/Edit"
import Model from "core/components/Model"
import { EditMentorTemplateForStudent } from "domain/useCase/Admin/NewMentorSessions/EditMentorTemplateForStudent"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import Toast from "core/components/Toast"
import ConfirmationPopUpMentorRemoval from "./components/ChangesConfirmationPopup"
import { GetMentorNameFromId } from "domain/useCase/Admin/NewMentorSessions/GetMentorNameFromId"
import MentorModal from "presentation/Student/Mentor/components/MentorModal"
import useMentorshipStudentViewModel from "./MentorshipStudentEditViewModel"

export default function MentorshipEditView() {
  const {
    reviewIndex,
    setReviewIndex,
    feedbackIndex,
    setFeedbackIndex,
    setRating,
    comment,
    setComment,
    LightTooltip,
    getMentorSessionsByStudentUseCase,
    getMentorNameFromIdUseCase,
    auth,
    refreshed,
    tableDataNew,
    setTableDataNew,
    mentorName,
    setMentorName,
    milestoneMentorName,
    setMilestoneMentorName,
    student,
    setStudent,
    items,
    setItems,
    editModes,
    setEditModes,
    endDateEditModes,
    toast,
    changeToastVisibility,
    isModalOpen,
    setIsModalOpen,
    agenda,
    setAgenda,
    modalAgenda,
    setModalAgenda,
    isOpen,
    setIsOpen,
    indexToBeDeleted,
    setIndexToBeDeleted,
    getUserDataUseCase,
    handleChange,
    toggleModal,
    formatDate,
    toggleEditMode,
    handleStartDateChange,
    handleEndDateChange,
    getStatusColorClass,
    addNewMentorSessionAt,
    getNextSessionId,
    getMentorNameFromId,
    getMilestoneMentorNameFromId,
    getMentorSessionsByStudent,
    getUserData,
    deleteMentorSession,
    handleEdit,
    handleModalClose,
    handleModalAgendaChange,
    closeSelectedFeedbackDialog,
    toggleEndDateEditMode,
  } = useMentorshipStudentViewModel()

  React.useEffect(() => {
    const mentorSessionsList: any[] = items?.map((item: any, i: number) => {
      const n = items.length

      const currentDate = new Date()
      const isDateRangeDefined = item?.startDate && item?.endDate
      const isPaymentDone = auth?.payment_status !== "NOT_PAID"

      const isBookNowEnabled =
        item?.session_status === "not_booked" &&
        item?.startDate &&
        item?.endDate &&
        new Date(item.startDate) <= currentDate &&
        currentDate <= new Date(item.endDate)

      // const sessionStartTime: number = parseInt(item?.session_start_time, 10);
      const sessionStartTime: string = item?.session_start_time || "" // Ensure it's a string
      const sessionEndTime: string = item?.session_end_time || ""
      const sessionStartTimeFormatted: string = sessionStartTime.length < 2 ? `0${sessionStartTime}` : sessionStartTime
      const sessionEndTimeFormatted: string = sessionEndTime.length < 2 ? `0${sessionEndTime}` : sessionEndTime

      const sessionDateTime: Date = new Date(`${item?.session_date}T${sessionStartTimeFormatted}:00:00`)
      const sessionEndDateTime: Date = new Date(`${item?.session_date}T${sessionEndTimeFormatted}:00:00`)
      const timeDifference: number = sessionDateTime.getTime() - currentDate.getTime()
      const timeDifferenceEndTime: number = currentDate.getTime() - sessionEndDateTime.getTime()
      const isGreaterThan24Hours: boolean = timeDifference > (24 * 60 * 60 * 1000) / 2

      const isRescheduleEnabled: boolean = isGreaterThan24Hours && item?.session_status === "Booked"
      const isMarkMentorAbsentEnabled: boolean = item?.session_status === "Booked"
      const isJoinNowEnabled: boolean =
        (timeDifference >= 0 && timeDifference < 60 * 60 * 500 && item?.session_status !== "Completed") ||
        (timeDifferenceEndTime >= 0 && timeDifferenceEndTime < 60 * 60 * 500) ||
        (timeDifference < 0 && timeDifference >= -60 * 60 * 1000)

      const isMentorReportVisible: boolean =
        item?.session_status === "Completed" && item?.student_review !== undefined && !isEmpty(item?.student_review)

      const hasMentorGivenFeedback: boolean = item?.mentor_feedback !== undefined && item?.mentor_feedback !== ""

      return [
        <LightTooltip title={item.session_id}>
          <span>{i + 1}</span>
        </LightTooltip>,
        <input type="text" value={item.name} onChange={(e) => handleChange(e, i)} />,
        <Button onClick={() => toggleModal(item, i)} outlined>
          Edit
        </Button>,
        item?.session_date ? (
          <p className={item?.status === "Completed" ? "" : "text-green-500"}>{formatDate(item?.session_date)}</p>
        ) : item?.startDate?.charAt(0) !== "2" ? (
          "To be notified"
        ) : isBookNowEnabled || !isBookNowEnabled ? (
          <div className="flex flex-row items-center">
            <div className="flex">
              <p className=" flex text-green-500 gap-4">
                {formatDate(item?.startDate) + " - " + formatDate(item?.endDate)}
                <div className="flex flex-row gap-12">
                  <button
                    onClick={() => {
                      toggleEditMode(i)
                      toggleEndDateEditMode(i)
                    }}
                    className="text-black"
                  >
                    <EditIcon className="h-5 w-5" />
                  </button>
                </div>
              </p>
            </div>
            <div className="ml-4 flex flex-row gap-4">
              {editModes[i] ? (
                <Input onChange={(e) => handleStartDateChange(i, e.target.value)} type="date"></Input>
              ) : (
                <div></div>
              )}
              {endDateEditModes[i] ? (
                <Input onChange={(e) => handleEndDateChange(i, e.target.value)} type="date"></Input>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <p>{formatDate(item?.startDate) + " - " + formatDate(item?.endDate)}</p>
          </div>
        ),

        item?.mentor_name || "Not Booked",
        item?.session_status === "Completed" ? item?.duration + " mins" : "N/A",
        <p className={getStatusColorClass(item?.session_status || "")}>{item?.session_status || "N/A"}</p>,

        <Button
          outlined
          disabled={item?.session_status !== "Completed"}
          className="w-fit border-none bg-transparent text-new-accent underline hover:bg-transparent hover:text-new-accent"
          onClick={() => setReviewIndex(i)}
        >
          Click Here
        </Button>,
        <LightTooltip
          title={
            isMentorReportVisible && hasMentorGivenFeedback
              ? "View Mentor Report"
              : hasMentorGivenFeedback
                ? "Mentor Report will be available only after you submit your feedback"
                : "Mentor has not provided any feedback yet."
          }
        >
          <span>
            <Button
              outlined
              disabled={!isMentorReportVisible || !hasMentorGivenFeedback}
              className="w-fit border-none bg-transparent text-new-accent underline hover:bg-transparent hover:text-new-accent"
              onClick={() => setFeedbackIndex(i)}
            >
              Click to View
            </Button>
          </span>
        </LightTooltip>,
        <Button
          onClick={() => {
            setIsOpen(!isOpen)
            setIndexToBeDeleted(i)
          }}
          outlined
        >
          Delete
        </Button>,
        <Button onClick={() => addNewMentorSessionAt(i, email, item?.session_id)} outlined>
          Add Session
        </Button>,
        <Button onClick={() => addNewMentorSessionAt(i, email, getNextSessionId(item?.session_id))} outlined>
          Add Session with new Id
        </Button>,
      ]
    })
    // Parse the email from the URL
    const url = window.location.href
    const email = url.substring(url.lastIndexOf("/") + 1)
    // Invoke the use case with the email

    setTableDataNew({
      header: [
        "S. No.",
        "Session Name",
        "Agenda",
        "Date",
        // "Book Session",
        // "Join Link",
        "Mentor Name",
        "Duration",
        "Status",
        "Student Feedback",
        "Mentor Feedback",
        "Delete Session",
        "Add Session with same id",
        "Add session with new id",
      ],
      rows: mentorSessionsList,
    })
    getMentorNameFromId(student?.mentor_id)
    getMilestoneMentorNameFromId(student?.milestone_session_mentor)
  }, [items, editModes, endDateEditModes, isModalOpen, student])
  React.useEffect(() => {
    const url = window.location.href

    const email = url.substring(url.lastIndexOf("/") + 1)

    getMentorSessionsByStudent(email)
    getUserData(email)
  }, [])

  return (
    <DashboardLayout>
      {isOpen && (
        <ConfirmationPopUpMentorRemoval
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          // email={student?.email}
          updateStudentsMentor={() => deleteMentorSession(indexToBeDeleted!)}
          heading="Are you sure you want to delete this session?"
        />
      )}
      <div className="space-y-6 p-6">
        <div className="space-y-1">
          <h2 className="text-new-accent">Edit Mentorship Flow Template</h2>
          <p className="">You have access to edit Mentorship Flow Templates here.</p>
        </div>
        <StudentCard
          email={student?.email || "email"}
          name={student?.name || "name"}
          imageURL={student?.imageURL || "imageURL"}
          phone={student?.phone || "phone"}
          batch={student?.batch || "batch"}
          batchPaused={student?.batchPaused}
          batchPausedTimeStamp={student?.batchPausedTimeStamp}
        />
        <h2 className="text-new-accent">Track Mentor Sessions of this student here</h2>
        <div className="my-4">
          <MentorSessionTable data={tableDataNew} gradient={false} />
        </div>
        {/* <div className="flex justify-center items-center"><Button onClick={addNewMentorSession} className="w-[400px]">Add Mentor Session</Button></div> */}

        <div className="flex justify-center items-center">
          <Button onClick={() => handleEdit(items)} className="w-[400px]">
            Save Changes
          </Button>
        </div>
      </div>

      <Model isOpen={isModalOpen} onClose={handleModalClose} width={"max-w-5xl"}>
        <div className="flex flex-col gap-4">
          <div className="text-new-accent">The agenda of the session is:</div>
          <textarea onChange={handleModalAgendaChange} value={modalAgenda} />
        </div>
      </Model>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      {reviewIndex !== -1 && (
        <MentorModal
          index={reviewIndex}
          sessions={items?.[reviewIndex]}
          onClose={() => closeSelectedFeedbackDialog("review")}
          rating={items?.[reviewIndex]?.student_review["rating"]}
          comment={items?.[reviewIndex]?.student_review["comment"]}
          setComment={setComment}
          // onSubmit={handleSubmit}
          openedFrom="admin"
          type="review"
        />
      )}
      {feedbackIndex !== -1 && (
        <MentorModal
          index={feedbackIndex}
          sessions={items?.[feedbackIndex]}
          onClose={() => closeSelectedFeedbackDialog("feedback")}
          rating={items?.[feedbackIndex]?.mentor_feedback["rating"]}
          setRating={setRating}
          comment={comment}
          setComment={setComment}
          openedFrom="admin"
          // actionItems = {actionItems}
          // setActionItems = {setActionItems}
          type="feedback"
          // onSubmit={handleSubmit}
          // type="review"
        />
      )}
    </DashboardLayout>
  )
}
