import { CheckSuccessIcon, CrossIcon, ErrorWarningIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import { STR_FAILURE } from "core/constants/strings"
import { useEffect, useState, useRef } from "react"

export default function Toast({ data, onClick, className }: any) {
  const status = data?.details?.status
  const message = data?.details?.message
  const visible = data?.visible

  const [isHovered, setIsHovered] = useState(false)
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const handleMouseEnter = () => {
    setIsHovered(true)
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
    timerRef.current = setTimeout(() => {
      onClick()
    }, 3000)
  }

  useEffect(() => {
    if (visible && !isHovered) {
      timerRef.current = setTimeout(() => {
        onClick()
      }, 3000)
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [visible, isHovered, onClick])

  return (
    <div
      className={cn(
        "absolute top-0 left-1/2 z-[99999] flex w-toast-status -translate-x-1/2 items-center gap-2 rounded-lg px-4 py-2 text-white transition-all duration-500",
        status === STR_FAILURE ? "bg-red-500" : "bg-green-500",
        visible ? "translate-y-8 opacity-100" : "-translate-y-full opacity-0",
        className
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {status === STR_FAILURE ? <ErrorWarningIcon className="h-4 w-4" /> : <CheckSuccessIcon className="h-4 w-4" />}
      <p className="select-none font-semibold leading-[17px]">{message}</p>
      <button onClick={onClick} className="ml-auto cursor-pointer">
        <CrossIcon className="h-4 w-4 text-new-solid-white" />
      </button>
    </div>
  )
}
